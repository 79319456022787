import React from "react";
import moment from "moment";
import TZMButton from "../../../../components/common/TZMButton";
import TZMForm from "../../../../components/common/TZMForm";
import TZMTextField from "../../../../components/common/TZMTextField";
import {ModalRealm} from "../../../../components/modal/ModalRealm";
import TZMPanel from "../../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../../utilities/TZMUserData";
import {EditarRomaneio} from "./EditarRomaneio";
import {Column} from "primereact/components/column/Column";
import TZMMainContent from "../../../../components/main/TZMMainContent";
import TZMTable from "../../../../components/table/TZMTable";
import TZMCalendar from "../../../../components/common/TZMCalendar";
import {MunicipioService} from "../../servicos/MunicipioService";
import {TransportadoraService} from "../../servicos/TransportadoraService";
import {ClienteService} from "../../../servicos/ClienteService";
import TZMCombobox from "../../../../components/common/TZMCombobox";
import {MunicipioUtils} from "../../municipio/MunicipioUtils";
import TZMAutoComplete from "../../../../components/common/TZMAutoComplete";
import {TransportadoraUtils} from "../../transportadora/TransportadoraUtils";
import {ClienteUtils} from "../../../comum/cliente/ClienteUtils";
import TZMConfirm from "../../../../components/dialog/TZMConfirm";
import { RomaneioColacrilService } from "../../servicos/RomaneioColacrilService";

const title = "Romaneio";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Romaneio extends React.Component {

	state = {
		romaneios: [],
		params: {
			numeroPedido: null,
			numeroNf: null,
			status: null,
			cliente: null,
			municipioOrigem: null,
			municipioDestino: null,
			transportadora: null,
			dataIni: null,
			dataFim: null
			// dataIni: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
			// dataFim: moment(new Date()).endOf('month').format("YYYY-MM-DD")

		}
	};

	romaneioService = new RomaneioColacrilService();
	municipioService = new MunicipioService();
	transportadoraService = new TransportadoraService();
	clienteService = new ClienteService();

	findByNomeOrCodigoOrigem = (event) => {
		this.municipioService.findByNomeOrCodigo({municipio: event.query}).then((municipiosOrigem) => this.setState({municipiosOrigem}));
	};

	findByNomeOrCodigoDestino = (event) => {
		this.municipioService.findByNomeOrCodigo({municipio: event.query}).then((municipiosDestino) => this.setState({municipiosDestino}));
	};

	autoCompleteTransportadora = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((response) => {
			this.setState({transportadoras: response});
		});
	};

	autoCompleteCliente = async (event) => {
		await this.clienteService.autoComplete({
			cliente: event.query,
			desativados: false,
			sort: "razao"
		}).then((clientes) => this.setState({clientes}));
	};

	listar = async () => {
		let params = Object.assign({}, this.state.params);
		if (params.transportadora) {
			params.transportadora = params.transportadora.id;
		}
		if (params.municipioDestino) {
			if (params.municipioDestino.codigo) {
				params.municipioDestino = params.municipioDestino.codigo;
			} else {
				params.municipioDestino = null;
			}
		}
		if (params.municipioOrigem) {
			if (params.municipioOrigem.codigo) {
				params.municipioOrigem = params.municipioOrigem.codigo;
			} else {
				params.municipioOrigem = null;
			}
		}
		await this.romaneioService.listarSimples(params).then((romaneios) => {
			this.setState({romaneios});
		});
	};

	editar = (event) => {
		this.setState({selectedRomaneio: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.romaneioService.buscar(event.data).then((romaneio) => {
					ModalRealm.showDialog(<EditarRomaneio key={Math.random()} romaneio={romaneio}
														  onModalClose={this.listar}/>);
				});
			} else {
				ModalRealm.showDialog(<EditarRomaneio key={Math.random()} romaneio={event.data}
													  onModalClose={this.listar}/>);
			}
		}
	};

	handleUpdate = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});
	};

	handleChange = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	};

	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	};

	columns = [
		<Column sortable header="Número" key="numero" field="numero" style={{width: "8em", textAlign: "center"}}/>,
		<Column sortable header="Status" key="status" field="status" style={{textAlign: "center", width: "9em"}} body={r => romaneioStatusToLabel[r.status]}/>,
		<Column sortable header="Data Emissão" key="dataEmissao" field="dataEmissao" style={{textAlign: "center", width: "12em"}}
				body={(rowData) => moment(rowData.dataEmissao).format("DD/MM/YYYY HH:mm:ss")}/>,
		<Column header="Transporte" key="transporte" field="transportadora" style={{width: "*"}} body={r => (
			<div>
				<div style={{padding: "0 .5em"}}><i className="fa fa-truck lead"/> {r.transportadora}</div>
				<div style={{display: "flex", borderTop: "1px solid rgba(50, 50, 50, .1)"}}>
					<div style={{padding: "0 .5em", flex: 1}}><i style={{transform: "rotate(-45deg)"}} className="fa fa-arrow-right green"/> {r.origem}</div>
					<div style={{padding: "0 .5em", flex: 1, borderLeft: "1px solid rgba(50, 50, 50, .1)"}}><i style={{transform: "rotate(45deg)"}} className="fa fa-arrow-right red"/> {r.destino}</div>
				</div>
			</div>
		)}/>,
		<Column sortable style={{width: "22em"}} header="Motorista" key="motorista" field="motorista"/>,
		<Column header="Ações" key="acoes" style={{width: "8em", textAlign: "center"}} body={(romaneio) => {
			let botoes = [];
			if (TZMUserData.hasRole("FRETE_ROMA_E")) {
				botoes.push(<span key={0} title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.editar({data: romaneio})}/>);
			}
			botoes.push(<span key={1} title="Imprimir" className={romaneio._isLoading ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf ui-tzm-icon`} onClick={() => this.imprimir(romaneio)}/>);
			if (romaneio.id != null && romaneio.status !== "CANCELADO") {
				botoes.push(<span key={2} title="Cancelar" className="fa fa-ban ui-tzm-icon" onClick={(event) => this.cancelar(romaneio)}/>);
			}
			return <div style={{textAlign: "center"}}>{botoes}</div>;
		}}/>
	];

	criar = () => {
		this.setState({selectedRomaneio: null});
		this.editar({data: {id: null, status: "EXPEDICAO"}});
	};

	imprimir = async (romaneio) => {
		const romaneios = this.state.romaneios;
		romaneios.filter(r => r.id === romaneio.id).forEach(p => {
			p._isLoading = true;
		});
		this.setState({romaneios});
		await this.romaneioService.imprimir(romaneio, "Romaneio_" + romaneio.numero).then(response => {
			romaneios.filter(r => r.id === romaneio.id).forEach(r => r._isLoading = false);
			this.setState({romaneios});
		});
	};

	optionsStatus = [
		<option key={0} value={null}/>,
		<option key={1} value="EXPEDICAO">Expedição</option>,
		<option key={2} value="FATURAMENTO">Faturamento</option>,
		<option key={3} value="FINALIZADO">Finalizado</option>,
		<option key={4} value="CANCELADO">Cancelado</option>
	];

	cancelar = (rowData) => {
		ModalRealm.showDialog(<TZMConfirm
			question="Tem certeza de que deseja cancelar este romaneio? Após cancela-lo não será mais possível desfazer o cancelamento."
			onYes={() => {
				this.romaneioService.buscar(rowData.id).then((romaneio) => {
					romaneio.status = "CANCELADO";
					this.romaneioService.salvar(romaneio);
					setTimeout(() => {
						this.listar();
					}, 50);
				});
			}}
		/>);
	};

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-2">
										<TZMCalendar label="Data de Emissão" placeholder="Inicial" name="dataIni" onChange={this.handleUpdate} value={this.state.params.dataIni}/>
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={this.handleUpdate} value={this.state.params.dataFim}/>
									</div>
									<div className="ui-g-3">
										<TZMTextField type="number" name="numeroPedido" placeholder="Número Pedido" label="Pedido" onChange={this.handleUpdate} value={this.state.params.numeroPedido}/>
									</div>
									<div className="ui-g-3">
										<TZMTextField type="number" name="numeroNf" placeholder="Número NF" label="Nota Fiscal" onChange={this.handleUpdate} value={this.state.params.numeroNf}/>
									</div>
									<div className="ui-g-2">
										<TZMCombobox value={this.state.params.status} name="status" label="Status" onChange={this.handleChange}>
											{this.optionsStatus}
										</TZMCombobox>
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete onClear={this.handleClear}
														 itemTemplate={MunicipioUtils.municipioTemplate}
														 onSelect={this.handleChange}
														 suggestions={this.state.municipiosOrigem}
														 completeMethod={this.findByNomeOrCodigoOrigem}
														 name="municipioOrigem" field="nome"
														 placeholder="Nome, Código IBGE" onChange={this.handleChange}
														 value={this.state.params.municipioOrigem}
														 label="Munícipio Origem"/>
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete onClear={this.handleClear}
														 itemTemplate={MunicipioUtils.municipioTemplate}
														 onSelect={this.handleChange}
														 suggestions={this.state.municipiosDestino}
														 completeMethod={this.findByNomeOrCodigoDestino}
														 name="municipioDestino" field="nome"
														 placeholder="Nome, Código IBGE" onChange={this.handleChange}
														 value={this.state.params.municipioDestino}
														 label="Munícipio Destino"/>
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete itemTemplate={TransportadoraUtils.transportadoraTemplate}
														 onClear={this.handleClear} onSelect={this.handleChange}
														 onChange={this.handleChange}
														 placeholder="Razão social, fantasia ou CNPJ"
														 suggestions={this.state.transportadoras}
														 completeMethod={this.autoCompleteTransportadora}
														 name="transportadora"
														 label="Transportadora" value={this.state.params.transportadora}
														 field="razaoSocial"/>
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete onClear={this.handleClear}
														 itemTemplate={ClienteUtils.templateTipo}
														 onSelect={this.handleChange} onChange={this.handleChange}
														 suggestions={this.state.clientes}
														 completeMethod={this.autoCompleteCliente} name="cliente"
														 field="descricaoAmigavel"
														 label="Cliente" placeholder="Razão social, fantasia ou CNPJ"
														 value={this.state.params.cliente}/>
									</div>


								</div>
							</TZMForm>
							<TZMPanelFooter>
								{TZMUserData.hasRole("FRETE_ROMA_C") ?
									<TZMButton success icon="fas fa-plus" label="Novo Romaneio" onClick={this.criar}/> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listar}/>
							</TZMPanelFooter>

						</TZMPanel>
						<div style={{height: "8px"}}/>
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.romaneios} paginator rows={50} children={this.columns}/>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm/>
			</TZMMainContent>
		);
	}

}

export const romaneioStatusToLabel = {
	EXPEDICAO: "Expedição",
	FATURAMENTO: "Faturamento",
	FINALIZADO: "Finalizado",
	CANCELADO: "Cancelado"
};

import React from "react";

export const TabelaFreteElementoCustoModalidadeMap = {
	"FIXO": "Valor Fixo",
	"CALCULADO_KG": "Fracionado Por Kg",
	"CALCULADO_KM": "Fracionado Por Km",
	"CALCULADO_VALOR": "Fracionado Por Valor",
	"CALCULADO_INDICE": "Fracionado Por Índice",
	"CALCULADO_KG_INTEGRAL": "Total Por Kg",
	"CALCULADO_KM_INTEGRAL": "Total Por Km",
	"CALCULADO_VALOR_INTEGRAL": "Total Por Valor",
	"CALCULADO_INDICE_INTEGRAL": "Total Por Índice",
	"FIXO_FAIXA_KG": "Fixo por Faixa de Kg",
	"FIXO_FAIXA_KM": "Fixo por Faixa de Km",
	"FIXO_FAIXA_VALOR": "Fixo por Faixa de Valor",
	"FIXO_FAIXA_INDICE": "Fixo por Faixa de Índice"
};

export const AuditoriaCteStatusMap = {
	"SUCESSO": "o frete passou na auditoria",
	"OBSERVACOES": "o frete passou na auditoria com ressalvas",
	"FALHA": "o frete não passou na auditoria",
	"ERRO": "o sistema não pôde auditar o frete"
};

export const AuditoriaCteStatusOptions = [
	<option key={-1} value={null}/>,
	<option key={0} value="SUCESSO">Sucesso</option>,
	<option key={1} value="OBSERVACOES">Observação</option>,
	<option key={2} value="FALHA">Divergência</option>,
	<option key={3} value="ERRO">Erro</option>,
	<option key={4} value="REVISADO">Revisado</option>
];

export const TabelaFreteFlagOptions = [
	<option key={0} value="VALIDAR_ICMS">Validar ICMS</option>
];

export const TabelaFreteElementoCustoModalidadeOptions = [
	<option key={0} value="FIXO">Valor Fixo</option>,
	<option key={1} value="CALCULADO_KM">Fracionado Por Km</option>,
	<option key={2} value="CALCULADO_KG">Fracionado Por Kg</option>,
	<option key={3} value="CALCULADO_VALOR">Fracionado Por Valor</option>,
	<option key={3} value="CALCULADO_INDICE">Fracionado Por Índice</option>,
	<option key={1} value="CALCULADO_KM_INTEGRAL">Total Por Km</option>,
	<option key={2} value="CALCULADO_KG_INTEGRAL">Total Por Kg</option>,
	<option key={3} value="CALCULADO_VALOR_INTEGRAL">Total Por Valor</option>,
	<option key={3} value="CALCULADO_INDICE_INTEGRAL">Total Por Índice</option>,
	<option key={4} value="FIXO_FAIXA_KG">Fixo por Faixa de Kg</option>,
	<option key={5} value="FIXO_FAIXA_KM">Fixo por Faixa de Km</option>,
	<option key={6} value="FIXO_FAIXA_VALOR">Fixo por Faixa de Valor</option>,
	<option key={6} value="FIXO_FAIXA_INDICE">Fixo por Faixa de Índice</option>
];

export const TabelaFreteElementoCustoFaixaComponenteOptions = [
	<option key={0} value="NORMAL">Normal</option>,
	<option key={1} value="VALOR">Por Valor</option>,
	<option key={2} value="PESO">Por Kg</option>,
	<option key={3} value="DISTANCIA">Por Km</option>
];

export const TabelaFreteElementoCustoArredondamentoOptions = [
	<option key={0} value="NENHUM">Nenhum</option>,
	<option key={1} value="PARA_CIMA">Para cima</option>,
	<option key={2} value="PARA_BAIXO">Para baixo</option>
];

export const TabelaFreteElementoCustoRoundingModeOptions = [
	<option key={1} value="HALF_UP">Para cima</option>,
	<option key={2} value="HALF_DOWN">Para baixo</option>
];

export const TabelaFreteElementoCustoAcaoLimiteOptions = [
	{label: "Limitar valor", value: "LIMITAR"},
	{label: "Cobrar exclusivo", value: "EXCLUSIVO"}
];

export const TabelaFreteElementoCustoArredondamentoMap = {
	"NENHUM": "Nenhum",
	"PARA_CIMA": "Para cima",
	"PARA_BAIXO": "Para baixo"
};

export const TabelaFreteElementoCustoAcaoLimiteMap = {
	"LIMITAR": "Limitar valor",
	"EXCLUSIVO": "Cobrar exclusivo"
};

export const TabelaFreteElementoCustoFlagMap = {
	"INCIDE_IMPOSTO": "Incide imposto",
	"EXCLUSIVO": "Frete fechado",
	"RATEIO": "Rateio (Min/Máx)"
};

export function newTabelaFrete() {
	return {
		transportadora: null,
		inicio: null,
		fim: null,
		descricaoIcms: "",
		descricaoPesoTaxado: "",
		percursos: [],
		elementosCusto: [],
		_key: Math.random()
	};
}

export function newTabelaFretePercurso() {
	return {
		origens: [],
		destinos: [],
		elementosCusto: [],
		_key: Math.random()
	};
}

export function clonarPercurso(p, incluirDestinos = false) {
	const percurso = newTabelaFretePercurso();
	percurso.descricao = p.descricao;
	percurso.valorMinimo = p.valorMinimo;
	for (const o of p.origens) {
		const origem = Object.assign({}, o);
		origem.id = null;
		percurso.origens.push(origem);
	}
	if (incluirDestinos) {
		for (const d of p.destinos) {
			const destino = Object.assign({}, d);
			destino.id = null;
			destino.incidencias = [];
			for (const i of d.incidencias) {
				const incidencia = Object.assign({}, i);
				incidencia.id = null;
				incidencia.elementosCusto = [];
				for (const ec of i.elementosCusto) {
					incidencia.elementosCusto.push(clonarElementoCusto(ec));
				}
				destino.incidencias.push(incidencia);
			}
			percurso.destinos.push(destino);
		}
	}
	for (const ec of p.elementosCusto) {
		percurso.elementosCusto.push(clonarElementoCusto(ec));
	}
	return percurso;
}

export function clonarTabelaFrete(tf) {
	const tabelaFrete = newTabelaFrete();
	tabelaFrete.margemSuperior = tf.margemSuperior;
	tabelaFrete.margemInferior = tf.margemInferior;
	tabelaFrete.transportadora = tf.transportadora;
	for (const p of tf.percursos) {
		tabelaFrete.percursos.push(clonarPercurso(p, true));
	}
	for (const ec of tf.elementosCusto) {
		tabelaFrete.elementosCusto.push(clonarElementoCusto(ec));
	}
	return tabelaFrete;
}

export function clonarElementoCusto(oec) {
	const ec = Object.assign({}, oec);
	ec.id = null;
	ec.faixas = [];
	for (const oecf of oec.faixas) {
		const ecf = Object.assign({}, oecf);
		ecf.id = null;
		ec.faixas.push(ecf);
	}
	return ec;
}

export function newTabelaFretePercursoOrigem() {
	return {
		municipio: null,
		uf: null,
		_key: Math.random()
	};
}

export function newTabelaFretePercursoDestino() {
	return {
		municipio: null,
		uf: null,
		incidencias: [],
		_key: Math.random()
	};
}

export function newTabelaFretePercursoDestinoIncidencia() {
	return {
		clientes: [],
		elementosCusto: [],
		_key: Math.random()
	};
}

export function newTabelaFreteElementoCusto() {
	return {
		descricao: "",
		modalidade: "FIXO",
		arredondamento: "NENHUM",
		valorArredondamento: 0,
		valorMinimo: 0,
		valorLimite: 0,
		roundingMode: "HALF_UP",
		acoesLimite: [],
		tiposVeiculo: [],
		faixas: [],
		flags: ["INCIDE_IMPOSTO"],
		elementosIndexados: [],
		_key: Math.random()
	};
}

export function newTabelaFreteElementoCustoFaixa() {
	return {
		valor: 0,
		faixaLimite: 0,
		_key: Math.random()
	};
}

export function newTipoVeiculo() {
	return {
		descricao: ""
	};
}

export function printOrigem(entidade) {
	return entidade.municipio ? `${entidade.municipio.nome}/${entidade.municipio.siglaUf}` : `[UF] ${entidade.uf?.nome}`;
}

export function printDestino(entidade) {
	return entidade.municipio ? `${entidade.municipio.nome}/${entidade.municipio.siglaUf}` : `[UF] ${entidade.uf?.nome}`;
}

import classNames from "classnames";
import React from "react";
import "./Common.css";

export default class TZMTextField extends React.Component {

	onChange = (event) => {
		if (this.props.onChange) {
			let value = event.target.value;
			switch (this.props.type) {
			case "number":
				value = Number(value);
				if (isNaN(value)) {
					value = 0;
				}
				if (this.props.max !== undefined && value > this.props.max) {
					value = this.props.max;
				}
				if (this.props.min !== undefined && value < this.props.min) {
					value = this.props.min;
				}
				break;
			case "plate":
				value = value.toUpperCase();
				break;
			default:
				break;
			}
			this.props.onChange({...event, name: this.props.name, value, index: this.props.index});
		}
	}

	handleBlur = () => {
		if (this.props.value && this.props.value.length) {
			this.onChange({target: {value: this.props.value.trim()}});
		}
	}

	render() {
		let classes = classNames({
			"ui-textfield": true,
			"ui-input-disabled-autocomplete": this.props.forac,
			"ui-input-warning": this.props.warning,
			"ui-input-required": this.props.required && (this.props.value == null || JSON.stringify(this.props.value).trim().length === 0)
		});
		let icon = null;
		if (this.props.exclamation) icon = "ui-input-exclamation";
		if (this.props.pending) icon = "ui-input-pending";
		if (this.props.confirmed) icon = "ui-input-confirmed";
		if (this.props.integrated) icon = "ui-input-integrated";
		return (
			<div className={`ui-textfield-group${this.props.col ? ` ui-g-${this.props.col}` : ""}`}>
				{this.props.label?.length ? <><label className="ui-input-label">{this.props.label}</label><br/></> : null}
				<div className={icon} title={this.props.exclamation}>
					<input onBlur={this.handleBlur} type="text" {...this.props} className={classes} onChange={this.onChange} value={this.props.value ? this.props.value : ""} />
				</div>
			</div>
		);
	}

}

import { httpGet } from "../../../utilities/TZMRequest";

export class MunicipioService {

	findByNomeOrCodigo(value) {
		return httpGet("/municipios/findByNomeOrCodigo", value,false);
	}

	listar(query) {
		return httpGet(`/municipios?${query}`);
	}

	autoCompletar(municipio) {
		return httpGet(`/municipios/findByNomeOrCodigo?municipio=${municipio.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ]/g, "")}`, false);
	}

}

import React, {Component} from "react";
import {MotoristaService} from "../../servicos/MotoristaService";
import TZMForm from "../../../components/common/TZMForm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMUserData from "../../../utilities/TZMUserData";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {ufs} from "../../../utilities/Constantes";
import TZMInputMask from "../../../components/common/TZMInputMask";
import {isCpfValido} from "../../../utilities/NumberUtils";

export class EditarMotorista extends Component {

	state = {
		visible: true,
		motorista: this.props.motorista
	};

	onClose = () => this.setState({visible: false});

	motoristaService = new MotoristaService();

	handleChange = event => {
		const {motorista} = this.state;
		motorista[event.name] = event.value;
		this.setState({motorista});
	}

	salvarMotorista = () => {
		const messages = [];
		const {motorista} = this.state;
		if (!motorista.nome?.length) messages.push("O nome do motorista é obrigatório");
		if (!motorista.rg?.length) messages.push("O RG do motorista é obrigatório");
		if (!motorista.cpf?.length) {
			messages.push("O CPF do motorista é obrigatório");
		} else if (!isCpfValido(motorista.cpf)) {
			messages.push("O CPF preenchido é inválido");
		}
		if (!motorista.rntnc?.length) messages.push("O RNTNC do motorista é obrigatório");
		if (!motorista.logradouro?.length) messages.push("O logradouro do endereço do motorista é obrigatório");
		if (!motorista.cep?.length) messages.push("O CEP do endereço do motorista é obrigatório");
		if (!motorista.cidade?.length) messages.push("A cidade do endereço do motorista é obrigatório");
		if (!motorista.uf?.length) messages.push("A UF do endereço do motorista é obrigatório");
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>);
		} else {
			this.motoristaService.salvar(motorista).then(motorista => {
				if (this.props.onModalClose) {
					this.props.onModalClose(motorista);
				}
				this.onClose();
			});
		}
	}

	render() {
		return (
			<TZMDialog style={{width: "750px"}} visible={this.state.visible} modal header="Motorista" onHide={this.onClose} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<div className="ui-g">
							<TZMTextField label="Nome" col={12} name="nome" onChange={this.handleChange} value={this.state.motorista.nome}/>
							<TZMInputMask mask="999.999.999-99" unmask label="CPF" col={3} name="cpf" onChange={this.handleChange} value={this.state.motorista.cpf}/>
							<TZMTextField label="RG" col={3} name="rg" onChange={this.handleChange} value={this.state.motorista.rg}/>
							<TZMTextField label="CNH" col={3} name="cnh" onChange={this.handleChange} value={this.state.motorista.cnh}/>
							<TZMTextField label="RNTNC" col={3} name="rntnc" onChange={this.handleChange} value={this.state.motorista.rntnc}/>
							<TZMTextField label="Logradouro" col={6} name="logradouro" onChange={this.handleChange} value={this.state.motorista.logradouro}/>
							<TZMTextField label="Complemento" col={3} name="complemento" onChange={this.handleChange} value={this.state.motorista.complemento}/>
							<TZMTextField label="CEP" col={3} name="cep" onChange={this.handleChange} value={this.state.motorista.cep}/>
							<TZMTextField label="Bairro" col={5} name="bairro" onChange={this.handleChange} value={this.state.motorista.bairro}/>
							<TZMTextField label="Cidade" col={5} name="cidade" onChange={this.handleChange} value={this.state.motorista.cidade}/>
							<TZMCombobox options={ufs} label="UF" col={2} name="uf" onChange={this.handleChange} value={this.state.motorista.uf}/>
						</div>
					</TZMForm>
				</TZMPanel>
				<TZMPanelFooter>
					{TZMUserData.hasRole("FRETE_MOTO_E") ? <TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarMotorista}/> : null}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

import React, {Component} from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import {TabelaFreteService} from "../servicos/TabelaFreteService";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {EditarTabelaFrete} from "./EditarTabelaFrete";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {byDescricao, byFaixaLimite} from "../../../utilities/SortUtils";
import {
	clonarTabelaFrete,
	newTabelaFrete,
	TabelaFreteElementoCustoAcaoLimiteMap,
	TabelaFreteElementoCustoArredondamentoMap,
	TabelaFreteElementoCustoFlagMap,
	TabelaFreteElementoCustoModalidadeMap
} from "./MapTabelaFrete";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {SimularFrete} from "./SimularFrete";
import moment from "moment";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import {Column} from "primereact/components/column/Column";
import {toCurrency, toDecimalFixed, toNumber, toNumberRightAligned} from "../../../components/common/TZMFormatter";

export class TabelaFrete extends Component {

	state = {
		transportadora: "",
		tabelasFrete: []
	};

	tabelaFreteService = new TabelaFreteService();

	listarTabelasFrete = () => {
		this.tabelaFreteService.dtos(this.state.transportadora).then(tabelasFrete => this.setState({tabelasFrete}));
	}

	editarTabelaFrete = async (tabelaFrete = newTabelaFrete()) => {
		if (tabelaFrete.id) {
			tabelaFrete = await this.tabelaFreteService.buscar(tabelaFrete.id);
		}
		tabelaFrete.elementosCusto = tabelaFrete.elementosCusto.sort(byDescricao);
		ModalRealm.showDialog(<EditarTabelaFrete key={Math.random()} tabelaFrete={tabelaFrete} onModalClose={this.listarTabelasFrete}/>);
	}

	removerTabelaFrete = (tabelaFrete) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja excluir esta tabela de frete?" onYes={() => {
			this.tabelaFreteService.excluir(tabelaFrete.id).then(this.listarTabelasFrete);
		}}/>);
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	simularFrete = () => {
		ModalRealm.showDialog(<SimularFrete/>);
	}

	clonarTabelaFrete = async (tf) => {
		ModalRealm.showDialog(<EditarTabelaFrete tabelaFrete={clonarTabelaFrete(await this.tabelaFreteService.buscar(tf.id))} onModalClose={this.listarTabelasFrete}/>);
	}

	render() {
		return (
			<TZMMainContent>
				<PanelContent>
					<div className="ui-g-12">
						<TZMPanel header="Tabelas de Frete">
							<TZMForm>
								<PanelContent>
									<TZMTextField col={12} label="Transportadora" name="transportadora" value={this.state.transportadora} onChange={this.handleChange}/>
								</PanelContent>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton success icon="fas fa-plus" label="Nova Tabela de Frete" onClick={() => this.editarTabelaFrete()}/>
								<TZMButton success icon="fas fa-calculator" label="Simular Frete" onClick={this.simularFrete}/>
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarTabelasFrete}/>
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel>
							<TZMTable paginator rows={50} value={this.state.tabelasFrete}>
								<TZMColumn style={{width: "*"}} header="Transportadora" body={tf => tf.transportadora_razaoSocial}/>
								<TZMColumn style={{width: "10em"}} header="Início" body={tf => tf.inicio && moment(tf.inicio).format("DD/MM/YYYY")}/>
								<TZMColumn style={{width: "10em"}} header="Fim" body={tf => tf.fim && moment(tf.fim).format("DD/MM/YYYY")}/>
								<TZMColumn style={{width: "7em"}} header="Ações" body={tf => (
									<div style={{textAlign: "center"}}>
										<span title="Editar Tabela de Frete" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarTabelaFrete(tf)}/>
										<span title="Clonar Tabela de Frete" className="fa fa-copy ui-tzm-icon" onClick={() => this.clonarTabelaFrete(tf)}/>
										<span title="Remover Tabela de Frete" className="fa fa-trash ui-tzm-icon" onClick={() => this.removerTabelaFrete(tf)}/>
									</div>
								)}/>
							</TZMTable>
						</TZMPanel>
					</div>
				</PanelContent>
				<ModalRealm/>
			</TZMMainContent>
		);
	}

}

export const headerElementoCusto = (
	<ColumnGroup>
		<Row>
			<Column rowSpan={2} header="Descrição"/>
			<Column rowSpan={2} header="Tipos de Veículo"/>
			<Column style={{width: "10em"}} rowSpan={2} header="Modalidade"/>
			<Column rowSpan={2} header="Valores"/>
			<Column style={{width: "12em"}} rowSpan={2} header="Limites"/>
			<Column style={{width: "14em"}} colSpan={2} header="Arredondamento"/>
			<Column style={{width: "5em"}} rowSpan={2} header="Ações"/>
		</Row>
		<Row>
			<Column header="Forma"/>
			<Column header="Fator"/>
		</Row>
	</ColumnGroup>
);

function printUnit(valor, modalidade) {
	switch (modalidade) {
	case "CALCULADO_KM":
	case "CALCULADO_KM_INTEGRAL":
	case "FIXO_FAIXA_KM":
		return toCurrency(valor, 5) + "/Km";
	case "CALCULADO_KG":
	case "CALCULADO_KG_INTEGRAL":
	case "FIXO_FAIXA_KG":
		return toCurrency(valor, 5) + "/Kg";
	case "CALCULADO_VALOR":
	case "CALCULADO_VALOR_INTEGRAL":
	case "FIXO_FAIXA_VALOR":
		return toDecimalFixed(valor * 100, 5) + "%";
	default:
		return toCurrency(valor, 2);
	}
}

function printComponentUnit(ec, f) {
	switch (ec.faixaComponente) {
	case "PESO":
		return toNumber(f.faixaLimite) + "Kg";
	case "DISTANCIA":
		return toNumber(f.faixaLimite) + "Km";
	case "VALOR":
		return toCurrency(f.faixaLimite);
	default:
		switch (ec.modalidade) {
		case "CALCULADO_KM":
		case "CALCULADO_KM_INTEGRAL":
		case "FIXO_FAIXA_KM":
			return toNumber(f.faixaLimite) + "Km";
		case "CALCULADO_KG":
		case "CALCULADO_KG_INTEGRAL":
		case "FIXO_FAIXA_KG":
			return toNumber(f.faixaLimite) + "Kg";
		case "CALCULADO_VALOR":
		case "CALCULADO_VALOR_INTEGRAL":
		case "FIXO_FAIXA_VALOR":
		case "CALCULADO_INDICE":
		case "FIXO_FAIXA_INDICE":
		case "CALCULADO_INDICE_INTEGRAL":
			return toCurrency(f.faixaLimite);
		default:
			return "?";
		}
	}
}

const IconArredondamento = {
	"NENHUM": <i style={{marginRight: ".5em"}} className="fa fa-square"/>,
	"PARA_CIMA": <i style={{marginRight: ".5em"}} className="fa fa-arrow-up"/>,
	"PARA_BAIXO": <i style={{marginRight: ".5em"}} className="fa fa-arrow-down"/>
};

export const commonElementoCustoColumns = [
	<TZMColumn key="descricao" field="descricao"/>,
	<TZMColumn key="tiposVeiculo" body={ec => ec.tiposVeiculo.map(tv => tv.descricao).join(", ")}/>,
	<TZMColumn key="modalidade" body={ec => TabelaFreteElementoCustoModalidadeMap[ec.modalidade]}/>,
	<TZMColumn key="valores" body={ec => (
		<div>
			<div>{ec.faixas?.length ? ec.faixas.sort(byFaixaLimite).map(f => <div>{`Até ${printComponentUnit(ec, f)}: ${printUnit(f.valor, ec.modalidade)}`}</div>) : toCurrency(ec.valorMinimo)}</div>
			<div>{ec.flags.map(f => TabelaFreteElementoCustoFlagMap[f]).join(", ")}</div>
			<div>{ec.acoesLimite.map(f => TabelaFreteElementoCustoAcaoLimiteMap[f]).join(", ")}</div>
		</div>
	)}/>,
	<TZMColumn key="limites" body={ec => {
		if (ec.faixas?.length && (ec.valorLimite || ec.valorMinimo)) {
			return (
				<div className="tf-ec-limites">
					<div className="label">Máx</div><div>{ec.valorLimite ? toCurrency(ec.valorLimite) : ""}</div>
					<div className="label">Min</div><div>{ec.valorMinimo ? toCurrency(ec.valorMinimo) : ""}</div>
				</div>
			);
		}
		return <div/>;
	}}/>,
	<TZMColumn key="arredondamento" body={ec => <div style={{textAlign: "center"}}>{IconArredondamento[ec.arredondamento]}{TabelaFreteElementoCustoArredondamentoMap[ec.arredondamento]}</div>}/>,
	<TZMColumn key="valorArredondamento" body={ec => ec.valorArredondamento ? toNumberRightAligned(ec.valorArredondamento) : ""}/>
];

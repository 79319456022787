import React from "react";
import moment from "moment";
import TZMButton from "../../../components/common/TZMButton";
import {Card} from "primereact/components/card/Card";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import {Column} from "primereact/components/column/Column";
import TZMTable from "../../../components/table/TZMTable";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {VwPedidoService} from "../../servicos/VwPedidoService";
import {TransportadoraService} from "../servicos/TransportadoraService";
import {CteService} from "../servicos/CteService";
import {EditarTransportadora} from "../transportadora/EditarTransportadora";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import {toCurrency, toDecimal, toPercent} from "../../../components/common/TZMFormatter";
import {ClienteService} from "../../servicos/ClienteService";
import {RelatorioService} from "../../servicos/RelatorioService";
import {SimularFrete} from "../tabela-frete/SimularFrete";
import {byDescricao, byNome} from "../../../utilities/SortUtils";
import TZMTextArea from "../../../components/common/TZMTextArea";
import {StatusCte} from "./StatusCte";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { Button } from "primereact/components/button/Button";

export class VisualizarCte extends React.Component {

	constructor(props) {
		super();		
		moment.locale('pt-BR');
		this.state = {
			visible: true,			
			cte: props.cte
		};
	}	
	vwPedidoService = new VwPedidoService();
	transportadoraService = new TransportadoraService();
	clienteService = new ClienteService();
	relatorioService = new RelatorioService();
	cteService = new CteService();

	onHide = () => this.setState({visible: false});

	paineis = (rowData, header) => {
		return (
			<div key={Math.random()} className="ui-g-6 ui-g-nopad">
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-7">
							<TZMTextField label={header} disabled name="remetenteNome" value={rowData.nome}  />
						</div>
						<div className="ui-g-3">
							<TZMTextField label="CNPJ" disabled name="remetenteCNPJ" value={rowData.cnpjFormatado}  />
						</div>
						<div className="ui-g-2">
							<TZMTextField label="IE" disabled name="remetenteIE" value={rowData.inscricaoEstadual}  />
						</div>
						<div className="ui-g-10">
							<TZMTextField label="Endereço" disabled name="remetenteEndereco" value={rowData.endereco}  />											
						</div>
						<div className="ui-g-2">
							<TZMTextField label="CEP" disabled name="remetenteCEP" value={rowData.cep}  />
						</div>
						<div className="ui-g-3">
							<TZMTextField label="Telefone" disabled name="remetenteTelefone" value={rowData.telefoneFormatado}  />
						</div>
						<div className="ui-g-4">
							<TZMTextField label="Municipio" disabled name="remetenteMunicipio" value={rowData.municipio}  />
						</div>
						<div className="ui-g-2">
							<TZMTextField label="UF" disabled name="remetenteUF" value={rowData.uf}  />
						</div>
						<div className="ui-g-3">
							<TZMTextField label="País" disabled name="remetentePais" value={rowData.pais}  />												
						</div>
					</div>	
				</TZMPanel>
			</div>			
		);
	}
	
	desconsiderar = () => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let salvo = this.state.cte;
		salvo.status  = 'DESCONSIDERADO';				
		this.cteService.salvar(salvo).then((cte) => {
			if (this.props.onModalClose) this.props.onModalClose(cte);
			this.onHide();
		});
		}} question="Tem certeza de que deseja desconsiderar este CT-e, ao continuar, esse registro não aparecerá em relatórios futuros." />);
	}

	processar = async () => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {		
		this.cteService.processar(this.state.cte).then((cte) => {
			this.setState({cte})});
		}} question="Tem certeza de que deseja reprocessar os dados fiscais?" />);
	}

	columnsCarga = [
		<Column header="Medida" key="tipoMedida" field="tipoMedida"  style={{textAlign: "left",width: "*" }}/>,
		<Column header="Quantidade" key="quantidade" field="quantidade" style={{textAlign: "right",width: "10em" }} body={ (rowData) => rowData.quantidade.toDecimal()}/>,
		<Column header="Unidade" key="unidade" field="unidade"  style={{textAlign: "left",width: "8em" }}/>		
	];

	columnsValores = [
		<Column header="Nome" key="nome" field="nome"   style={{width: "*" }}/>,
		<Column header="Valor" key="valor" field="valor"  style={{textAlign: "right",width: "8em" }} body={ (rowData) => rowData.valor.toCurrency()}/>
	];

	handleChangeItem = (event) => {
		let value = Number(event.value);
		if (!isNaN(value)) {
			let  cte  = this.state.cte;
			cte.notas[event.index][event.name] = event.value;
			this.setState({cte});
		}
	}

	editorPesoBruto = (rowData, props) => {
		return <TZMCurrency precision={3}
							prefix=""
							suffix=" Kg"
							index={props.rowIndex}
							style={{textAlign: "right"}}
							disabled={this.state.cte.status === "LIDO"}
							name="pesoBruto" value={this.state.cte.notas[props.rowIndex].pesoBruto}
							onChange={this.handleChangeItem}/>;
	}

	editorPesoLiquido = (rowData, props) => {
		return <TZMCurrency precision={3}
							prefix=""
							suffix=" Kg"
							index={props.rowIndex}
							style={{textAlign: "right"}}
							name="pesoLiquido"
							value={this.state.cte.notas[props.rowIndex].pesoLiquido}
							onChange={this.handleChangeItem}/>;
	}

	infoNotaFiscal = (rowData) => {
		if (!rowData.notaFiscalId) {
			return (
				<div className='ui-g'>
					<span key={1} className="fa fa-triangle-exclamation" title="Não foi possível obter os dados da Nota Fiscal." style={{color: "#ff0000", fontSize: "15px" }} />
				</div>
			)
		}
		return null;
	}

	columnsNota = [
		<Column key="status" style={{ width: "2em" }} body={this.infoNotaFiscal}/>,
		<Column header="Chave" key="chave" field="chave" style={{textAlign: "left",width: "*" }} />,
		<Column header="Peso Líquido" key="pesoLiquido" field="pesoLiquido"  style={{textAlign: "right", width: "10em" }} body={this.editorPesoLiquido}/>,
		<Column header="Peso Bruto" key="pesoBruto" field="pesoBruto"  style={{textAlign: "right", width: "10em" }} body={this.editorPesoBruto}/>,
		<Column header="Valor dos Produtos" key="valorTotProd" field="valorTotalProdutos" style={{textAlign: "right", width: "10em" }} body={(rowData) => rowData.valorTotalProdutos?.toCurrency()}/>,
		<Column header="Valor Total da NF" key="valorNf" field="valor" style={{textAlign: "right", width: "10em" }} body={(rowData) => rowData.valor.toCurrency()}/>,
		<Column header="Pedido" key="pedido" style={{textAlign: "right", width: "10em" }} field="pedidoId" />,
		<Column header="Nota Fiscal" key="notaFiscal" style={{textAlign: "right", width: "10em" }} field="notaFiscalId" />
	];

	simularFrete = () => {
		ModalRealm.showDialog(<SimularFrete cte={this.state.cte}/>);
	}

	columnsValores1 = [
		<Column header="Nome" key="nome" style={{width: "*"}} body={ec => ec.nome.toUpperCase()}/>,
		<Column header="Valor" key="valor" field="valor" style={{textAlign: "right", width: "8em"}} body={(rowData) => rowData.valor.toCurrency()}/>
	];

	columnsValores2 = [
		<Column header="Nome" key="descricao" style={{width: "*"}} body={ec => ec.descricao.toUpperCase()}/>,
		<Column header="Valor" key="valor" field="valor" style={{textAlign: "right", width: "8em"}} body={(rowData) => rowData.valor.toCurrency()}/>
	];

	columnsMensagens = [
		<Column header="Mensagens da Auditoria" key={0} body={m => m}/>
	];

	salvar = () => {
		this.cteService.salvar(this.state.cte).then((cte) => {
			if (this.props.onModalClose) this.props.onModalClose(cte);
			this.onHide();
		});
	}

	tipoIcon = (cte) => {
		let value, color, titulo;
		switch (cte.tipo) {
			case "ENTRADA": value = "fa fa-warehouse"; color= "#ff0000"; titulo="CT-e de Entrada"; break;
			case "SAIDA": value = "fa fa-building"; color= "#0400ff"; titulo="CT-e de Saída"; break;
			case "DESCONHECIDO": value = "fa fa-question"; color= "#607D8B"; titulo="Desconhecido"; break; 
			default: break;
		}

		return (
			<div className='ui-g' style={{ textAlign: "center" }}>
				<span key={1} className={value} title={titulo} style={{ color, fontSize: "20px" }} />&nbsp;
			</div>
		);
	}
		
	statusIcon = (cte) => {
		let value, titulo, color;
		switch (cte.status) {
			case "LIDO": value = "fa fa-book-open"; color= "#607D8B"; titulo="Lido"; break;			
			case "PROCESSADO": value = "fa fa-road"; color= "#03A9F4"; titulo="Processado"; break;
			case "AUDITADO_SUCESSO": value = "fa fa-check"; color= "#4CAF50"; titulo="Auditado com Sucesso"; break;
			case "AUDITADO_OBSERVACOES": value = "fa fa-info-circle"; color= "#009688"; titulo="Auditado com Observações"; break; 
			case "AUDITADO_FALHA": value = "fa fa-exclamation-triangle"; color= "#FF9800"; titulo="Auditado com Falha"; break;
			case "AUDITADO_ERRO": value = "fa fa-square-xmark"; color= "#F44336"; titulo="Auditado com Erro"; break;
			case "AUDITADO_REVISADO": value = "fa fa-check-circle"; color= "#009688"; titulo="Auditado Revisado"; break;			
			case "CANCELADO": value = "fa fa-ban"; color= "#ff0000"; titulo=`Cancelado em ${moment(cte.dataCancelamento).format("DD/MM/YYYY HH:mm:ss")} Motivo: ${cte.motivoCancelamento}` ; break;
			case "DESCONSIDERADO":  value = "fa fa-trash"; color= "#640404"; titulo="Desconsiderado"; break;
			default: break;
		}
		return (
			<div className='ui-g' style={{ textAlign: "center" }}>
				<span key={1} className={value} title={titulo} style={{ color, fontSize: "20px" }} />
			</div>
		);
	}


	tipoCteIcon = (cte) => {
		let value, titulo, color;
		switch (cte.identificacaoCte.tipoCte) {
			case "NORMAL": value = "fa fa-truck-moving"; titulo="CT-e Normal"; break;
			case "COMPLEMENTO": value = "fa fa-truck-field ";  titulo="CT-e de Complemento de valores"; color="#607D8B";  break;
			case "ANULACAO": value = "fa fa-road-circle-xmark"; titulo="CT-e de Anulação"; break;
			case "SUBSTITUICAO": value = "fa fa-truck-fast"; titulo="CT-e de Substituição"; break; 
			case "MINUTA": value = "fa fa-truck-pickup";  titulo="Minuta"; break;
			case "NAO_IDENTIFICADO": value = "fa fa-circle-question ";  titulo="Não Identificado"; break;
			default: break;
		}
		return (
			<div className='ui-g' style={{ textAlign: "center" }}>
				<span key={1} className={value} title={titulo} style={{ color, fontSize: "20px" }} />&nbsp;
			</div>
		);
	}

    render() {
		const cte = this.state.cte;
		const identificacao = cte.identificacaoCte;
		const inicioPrestacao = identificacao.codigoMunicipioOrigem + ' / ' + identificacao.nomeMunicipioOrigem + '-' + identificacao.ufOrigem;
		const terminoPrestacao = identificacao.codigoMunicipioDestino + ' / ' + identificacao.nomeMunicipioDestino + '-' + identificacao.ufDestino;
		const transportadora = cte.transportadora;
		const carga = cte.informacaoCarga;
		const salvar = (cte.status !== 'LIDO' &&  cte.status !== 'DESCONSIDERADO' !== cte.status !== 'AUDITADO_SUCESSO' && cte.status !== "CANCELADO");
		const desconsiderar = cte.status !== "DESCONSIDERADO" && cte.status !== "CANCELADO";
		return (
			<TZMDialog style={{width: "1400px"}} visible={this.state.visible} modal header={
				<span className="fake-link">CT-e {cte.identificacaoCte.numeroCte}
					<span style={{ position: "absolute", right: "40px", top: "8px" }}>
						<i style={{ marginRight: "8px" }} />{this.state.cte.statusExtenso}
					</span>
				</span>
			} onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
								<div className="ui-g">
									<div className="ui-g-2 ui-g-nopad">
										<div className="ui-g-2 cliente-tipo">
											{this.tipoIcon(cte)}
										</div>
										<div className="ui-g-10">
											<TZMTextField label="CT-e" disabled value={cte.tipo}  />											
										</div>
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Data Emissão CT-e" disabled   value={moment(identificacao.dataEmissao).format("DD/MM/YYYY HH:mm:ss")}  />
									</div>
									<div className="ui-g-4"/>	
									<div className="ui-g-2">
										<TZMTextField label="Lido em" disabled name="registro" value={moment(cte.registro).format("DD/MM/YYYY HH:mm:ss")}  />
									</div>
									<div className="ui-g-2 ui-g-nopad">
										<div className="ui-g-2 cliente-tipo">
											{this.statusIcon(cte)}
										</div>
										<div className="ui-g-10">
											<TZMTextField label="Status" disabled name="status" value={cte.status}  />
										</div>
									</div>
									<div className="ui-g-2 ui-g-nopad">
										<div className="ui-g-2 cliente-tipo">
											{this.tipoCteIcon(cte)}
										</div>
										<div className="ui-g-10">
											<TZMTextField label="Tipo do CT-e" disabled value={identificacao.tipoCte}  />											
										</div>
									</div>
									
									<div className="ui-g-1">
										<TZMTextField label="Tipo do Serviço" disabled value={identificacao.tipoServico}  />
									</div>
									<div className="ui-g-2">
										<TZMTextField label="Tomador do Serviço" disabled value={identificacao.tomador}  />
									</div>
									<div className="ui-g-3">
										<TZMTextField label="Chave de Acesso" disabled name="chave" value={cte.chave}  />
									</div>
									<div className="ui-g-4 ui-g-nopad">
										<div className="ui-g-3">
											<TZMTextField label="Modelo" disabled name="modelo" value={identificacao.modelo}  />
										</div>
										<div className="ui-g-3">
											<TZMTextField label="Série" disabled name="serie" value={identificacao.serie}  />
										</div>
										<div className="ui-g-6">
											<TZMTextField label="Número" disabled name="numeroCte" value={identificacao.numeroCte}  />
										</div>
									</div>
									<div className="ui-g-6">
										<TZMTextField label="Início da Prestação" disabled name="inicioPrestacao" value={inicioPrestacao}  />
									</div>
									<div className="ui-g-6">
										<TZMTextField label="Término da Prestação" disabled name="inicioPrestacao" value={terminoPrestacao}  />
									</div>
									{this.paineis(cte.remetente,"Remetente")}
									{this.paineis(cte.destinatario,"Destinatário")}
									<div className="ui-g-12"><p></p></div>
									
									{cte.expedidor ? this.paineis(cte.expedidor,"Expedidor") : null}
									{cte.recebedor ? this.paineis(cte.recebedor,"Recebedor") : null}
									
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Tomador / Transportadora</span>}>
								<Card title="Tomador">
									<div className="ui-g">
										<div className="ui-g-5">
											<TZMTextField label="Tomador" disabled name="tomadorNome" value={cte.tomador.nome}  />
										</div>
										<div className="ui-g-3">
											<TZMTextField label="CNPJ/CPF" disabled name="numeroCte" value={cte.tomador.cnpjFormatado}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="Inscrição Estadual" disabled name="numeroCte" value={cte.tomador.inscricaoEstadual}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="Fone" disabled name="numeroCte" value={cte.tomador.telefoneFormatado}  />
										</div>
										<div className="ui-g-5">
											<TZMTextField label="Endereço" disabled name="numeroCte" value={cte.tomador.endereco}  />											
										</div>
										<div className="ui-g-2">
											<TZMTextField label="CEP" disabled name="numeroCte" value={cte.tomador.cep}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="Cidade" disabled name="numeroCte" value={cte.tomador.municipio}  />
										</div>
										<div className="ui-g-1">
											<TZMTextField label="UF" disabled name="numeroCte" value={cte.tomador.uf}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="País" disabled name="numeroCte" value={cte.tomador.pais}  />
										</div>
									</div>
								</Card>
								<Card title="Transportadora">
									<div className="ui-g">
										<div className="ui-g-4 ui-g-nopad">										
											<div className="ui-g-1 cliente-tipo">																
												<span className="fas fas fa-truck ui-action-icon" title="Visualizar Dados da Transportadora" 
														style={{width:"30px", textAlign: "center", fontSize: "20px", color: "#14b64a"}}  onClick={() => {
														this.transportadoraService.completar(transportadora.transportadoraId).then((result) => 
														ModalRealm.showDialog(<EditarTransportadora key={Math.random()} transportadora={result} />));}}>
												</span>	
											</div>											
											<div className="ui-g-11">
												<TZMTextField label="Razão Social" disabled  value={transportadora.razaoSocial}  />											
											</div>
										</div>
										<div className="ui-g-4">
											<TZMTextField label="Nome Fantasia" disabled  value={transportadora.nomeFantasia}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="CNPJ/CPF" disabled value={transportadora.cnpjFormatado}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="Inscrição Estadual" disabled  value={transportadora.inscricaoEstadual}  />
										</div>
										<div className="ui-g-5">
											<TZMTextField label="Endereço" disabled   value={transportadora.endereco}  />											
										</div>
										<div className="ui-g-2">
											<TZMTextField label="CEP" disabled   value={transportadora.cep}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="Cidade" disabled  value={transportadora.municipio}  />
										</div>
										<div className="ui-g-1">
											<TZMTextField label="UF" disabled   value={transportadora.uf}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="País" disabled  value={transportadora.pais}  />
										</div>
										<div className="ui-g-2">
											<TZMTextField label="Fone" disabled   value={transportadora.telefoneFormatado}  />
										</div>									
									</div>
								</Card>	
							</TZMTabPanel>													
							<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Informação da Carga / Prestação Serviço</span>}>
								<div className="ui-g">
									<div className="ui-g-4">
										<Card subtitle="Informação da Carga" >
											<div className="ui-g">
												<div className="ui-g-9">
													<TZMTextField label="Produto Predominante" disabled  value={carga?.produtoPredominante}  />
												</div>
												<div className="ui-g-3">
													<TZMCurrency label="Valor da Carga" disabled  value={carga?.valorTotalCarga}  />
												</div>
												<TZMTable value={carga?.itens} children={this.columnsCarga} />
											</div>
										</Card>
									</div>
									<div className="ui-g-4">
										<Card subtitle="Componentes do Valor da Prestação do Serviço">
											<div className="ui-g">
												<div className="ui-g-6">
													<TZMCurrency label="Valor Total do Serviço" disabled  value={cte.valorPrestacaoServico.valorTotal}  />
												</div>
												<div className="ui-g-6">
													<TZMCurrency label="Valor a Receber" disabled  value={cte.valorPrestacaoServico.valorReceber}  />
												</div>
												<TZMTable value={cte.valorPrestacaoServico.itens} children={this.columnsValores} />
											</div>		
										</Card>
									</div>
									<div className="ui-g-4">
										<Card subtitle="ICMS">
											<div className="ui-g">
												<div className="ui-g-12">
													<TZMTextField label="CST" disabled  value={cte.icms.cst}  />
												</div>
												<div className="ui-g-4">
													<TZMCurrency label="Valor Base de Cálculo" disabled  value={cte.icms.valorBaseCalculo}  />
												</div>
												<div className="ui-g-4">
													<TZMTextField label="Alíquota" disabled  value={toPercent(cte.icms.aliquota / 100)}  />
												</div>
												<div className="ui-g-4">
													<TZMCurrency label="Valor" disabled  value={cte.icms.valor}  />
												</div>
											</div>
										</Card>
									</div>
								</div>
								<Card title="Notas Fiscais">
									<TZMTable children={this.columnsNota} value={this.state.cte.notas} paginator rows={15} 
									paginatorLeft={
										<div style={{ textAlign: "left" }}>
											<Button icon="fa fa-gears"  title="Reprocessar dados fiscais" className="tzm-paginator-button" onClick={this.processar} />
										</div> 									
									}
									footerColumnGroup={
										<ColumnGroup>
											<Row>
												<Column/>
												<Column style={{ fontSize: "12px", textAlign: "right" }} footer="Total NF" />
												<Column style={{ textAlign: "right" }} footer={toDecimal(this.state.cte.notas.map((nota) => nota.pesoLiquido).reduce((a, b) => Number(a) + Number(b), 0))} />
												<Column style={{ textAlign: "right" }} footer={toDecimal(this.state.cte.notas.map((nota) => nota.pesoBruto).reduce((a, b) => a + b, 0))} />
												<Column style={{ textAlign: "right" }} footer={toCurrency(this.state.cte.notas.map((nota) => nota.valorTotalProdutos).reduce((a, b) => a + b, 0))} />
												<Column style={{ textAlign: "right" }} footer={toCurrency(this.state.cte.notas.map((nota) => nota.valor).reduce((a, b) => a + b, 0))} />
												<Column colSpan={2}/>
											</Row>
										</ColumnGroup>}>
									</TZMTable>
								</Card>
							</TZMTabPanel>
							<TZMTabPanel disabled={!this.state.cte?.auditoria} header={<span><span className="ui-tab-number" children="4"/> Resultado da Auditoria</span>}>
								<div className="ui-g">
									<StatusCte col={2} cte={this.state.cte}/>
									<TZMTextField col={3} label="Número" value={this.state.cte.identificacaoCte?.numeroCte}/>
									<TZMCurrency style={{fontWeight: "bold"}} disabled col={2} label="Valor Correto" value={this.state.cte.auditoria?.valorCorreto}/>
									<TZMTextField disabled col={5} label="Responsável pela Revisão" value={this.state.cte.auditoria?.responsavel ? `${this.state.cte?.auditoria?.responsavel?.nome} (${this.state.cte?.auditoria?.responsavel?.email})` : ""}/>
									<div className="ui-g-6">
										<Card subtitle="Componentes do Valor da Prestação do Serviço">
											<TZMTable footer={
												<div style={{textAlign: "right"}}>Total: {toCurrency(this.state.cte.valorPrestacaoServico?.itens.map(i => i.valor).reduce((a, b) => a + b, 0))}</div>
											} value={this.state.cte?.valorPrestacaoServico?.itens.sort(byNome)} children={this.columnsValores1}/>
										</Card>
									</div>
									<div className="ui-g-6">
										<Card subtitle="Componentes do Cálculo da Auditoria do Frete">
											<TZMTable footer={
												<div style={{textAlign: "right"}}>Total: {toCurrency(this.state.cte.auditoria?.elementosCusto.map(i => i.valor).reduce((a, b) => a + b, 0))}</div>
											} value={this.state.cte?.auditoria?.elementosCusto.sort(byDescricao)} children={this.columnsValores2}/>
										</Card>
									</div>
									<div className="ui-g-12">
										<TZMTable value={this.state.cte?.auditoria?.mensagens} children={this.columnsMensagens}/>
									</div>
									<div className="ui-g-12">
										<TZMTextArea disabled style={{height: "9em"}} label="Observações" value={this.state.cte?.auditoria?.observacoes}/>
									</div>
								</div>
							</TZMTabPanel>
						</TZMTabView>
					</TZMForm>	
				</TZMPanel>
				<TZMPanelFooter>
					{TZMUserData.hasRole("FRETE_CTE_E") && desconsiderar ?  <TZMButton danger  style={{float: "left"}} title="O CT-e será desconsiderado e não aparecerá futuramente nos relatórios." label="Desconsiderar" icon="fas fa-trash	" onClick={this.desconsiderar}/> : null}
					<TZMButton secondary style={{float: "left"}} label="Visualizar Cálculo" icon="fas fa-search" onClick={this.simularFrete}/>
					{TZMUserData.hasRole("FRETE_CTE_E") && salvar ?  <TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvar} /> : null}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}

}

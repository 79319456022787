import React, {Component} from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import {MinutaService} from "../servicos/MinutaService";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMTextField from "../../../components/common/TZMTextField";
import {TransportadoraUtils} from "../transportadora/TransportadoraUtils";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMTable from "../../../components/table/TZMTable";
import {Column} from "primereact/components/column/Column";
import {TransportadoraService} from "../servicos/TransportadoraService";
import {ClienteService} from "../../servicos/ClienteService";
import {toCurrency} from "../../../components/common/TZMFormatter";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import {EditarNotaFiscal} from "./EditarNotaFiscal";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {EditarElementoCusto} from "./EditarElementoCusto";
import {MunicipioService} from "../servicos/MunicipioService";

export class EditarMinuta extends Component {

	state = {
		visible: true,
		_key: Math.random(),
		minuta: this.props.minuta,
		origens: [],
		destinos: []
	};

	minutaService = new MinutaService();
	municipioService = new MunicipioService();
	transportadoraService = new TransportadoraService();
	clienteService = new ClienteService();

	onClose = () => this.setState({visible: false});

	onSalvar = () => {
		this.minutaService.salvar(this.state.minuta).then(minuta => {
			if (this.props.onModalClose) {
				this.props.onModalClose(minuta);
			}
			this.onClose();
		});
	}

	listarTransportadoras = event => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then(transportadoras => this.setState({transportadoras}));
	}

	listarClientes = event => {
		this.clienteService.listarSimples({cliente: event.query, sort: "razaoSocial"}).then(clientes => this.setState({clientes}));
	}

	removerNotaFiscal = notaFiscal => {
		const {minuta} = this.state;
		minuta.notasFiscais = minuta.notasFiscais.filter(nf => (!nf._key || nf._key !== notaFiscal._key) && (!nf.id || nf.id !== notaFiscal.id));
		this.setState({minuta});
	}

	removerElementoCusto = elementoCusto => {
		const {minuta} = this.state;
		minuta.elementosCusto = minuta.elementosCusto.filter(ec => (!ec._key || ec._key !== elementoCusto._key) && (!ec.id || ec.id !== elementoCusto.id));
		this.setState({minuta});
	}

	handleUpdate = event => {
		const {minuta} = this.state;
		minuta[event.name] = event.value;
		this.setState({minuta});
	}

	adicionarNotaFiscal = () => {
		this.editarNotaFiscal({_key: Math.random(), serie: 0, numero: 0});
	}

	editarNotaFiscal = notaFiscal => {
		ModalRealm.showDialog(<EditarNotaFiscal notaFiscal={notaFiscal} onModalClose={notaFiscal => {
			const {minuta} = this.state;
			const index = minuta.notasFiscais.findIndex(nf => (!nf._key || nf._key === notaFiscal._key) && (!nf.id || nf.id === notaFiscal.id));
			if (index === -1) {
				minuta.notasFiscais.push(notaFiscal);
			} else {
				minuta.notasFiscais[index] = notaFiscal;
			}
			this.setState({minuta});
		}}/>);
	}

	adicionarElementoCusto = () => {
		this.editarElementoCusto({_key: Math.random(), nome: "", valor: 0});
	}

	editarElementoCusto = elementoCusto => {
		ModalRealm.showDialog(<EditarElementoCusto elementoCusto={elementoCusto} onModalClose={elementoCusto => {
			const {minuta} = this.state;
			const index = minuta.elementosCusto.findIndex(ec => (!ec._key || ec._key === elementoCusto._key) && (!ec.id || ec.id === elementoCusto.id));
			if (index === -1) {
				minuta.elementosCusto.push(elementoCusto);
			} else {
				minuta.elementosCusto[index] = elementoCusto;
			}
			minuta.valorTotalFrete = minuta.elementosCusto.map(ec => ec.valor).reduce((a, b) => a + b, 0);
			this.setState({minuta});
		}}/>);
	}

	listarOrigens = event => {
		this.municipioService.autoCompletar(event.query).then(origens => this.setState({origens}));
	}

	listarDestinos = event => {
		this.municipioService.autoCompletar(event.query).then(destinos => this.setState({destinos}));
	}

	render() {
		return (
			<TZMDialog key={this.state._key} style={{width: "1250px"}} visible={this.state.visible} modal header={
				<span>Minuta (Status: {this.state.minuta.status})</span>
			} onHide={this.onClose} {...this.props}>
				<TZMTabView>
					<TZMTabPanel header="Dados Principais">
						<div className="ui-g">
							<TZMTextField col={2} type="number" name="fatura" autoFocus onChange={this.handleUpdate} label="Nº Fatura" value={this.state.minuta.fatura}/>
							<TZMTextField col={2} type="number" name="numero" onChange={this.handleUpdate} label="Nº Minuta" value={this.state.minuta.numero}/>
							<TZMCalendar label="Data da Emissão" col={2} name="dataEmissao" onChange={this.handleUpdate} value={this.state.minuta.dataEmissao}/>
							<TZMAutoComplete itemTemplate={TransportadoraUtils.transportadoraTemplate}
											 onClear={this.handleClear}
											 onSelect={this.handleUpdate}
											 onChange={this.handleUpdate}
											 placeholder="Razão social, fantasia ou CNPJ"
											 suggestions={this.state.transportadoras}
											 completeMethod={this.listarTransportadoras}
											 name="transportadora"
											 label="Transportadora"
											 col={6}
											 value={this.state.minuta.transportadora}
											 field="razaoSocial"/>
							<TZMAutoComplete completeMethod={this.listarOrigens}
											 suggestions={this.state.origens}
											 col={6}
											 label="Origem"
											 name="origem"
											 itemTemplate={m => `${m.nome}/${m.siglaUf}`}
											 value={this.state.minuta.origem}
											 field="nome"
											 onChange={this.handleUpdate}/>
							<TZMAutoComplete completeMethod={this.listarDestinos}
											 suggestions={this.state.destinos}
											 col={6} label="Destino"
											 name="destino"
											 field="nome"
											 itemTemplate={m => `${m.nome}/${m.siglaUf}`}
											 value={this.state.minuta.destino}
											 onChange={this.handleUpdate}/>
							<TZMAutoComplete onClear={this.handleClear}
											 onSelect={this.handleUpdate}
											 onChange={this.handleUpdate}
											 placeholder="Razão social, fantasia ou CNPJ"
											 suggestions={this.state.clientes}
											 completeMethod={this.listarClientes}
											 name="cliente"
											 label="Cliente"
											 col={6}
											 value={this.state.minuta.cliente}
											 field="razaoSocial"/>
							<TZMTextField col={2} type="number" name="peso" onChange={this.handleUpdate} label="Peso Bruto" value={this.state.minuta.peso}/>
							<TZMCurrency suffix="%" prefix="" precision={0} col={2} name="percentualIcms" onChange={this.handleUpdate} label="ICMS" value={this.state.minuta.percentualIcms}/>
							<TZMCurrency col={2} name="valorTotalFrete" onChange={this.handleUpdate} label="Valor do Frete" value={this.state.minuta.valorTotalFrete}/>
							<TZMTextField col={6} label="CNPJ Tomador" name="cnpjTomador" value={this.state.minuta.cnpjTomador} onChange={this.handleUpdate}/>
							<TZMTextField col={6} label="CNPJ Remetente" name="cnpjRemetente" value={this.state.minuta.cnpjRemetente} onChange={this.handleUpdate}/>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header="Valores">
						<div className="ui-g">
							<div className="ui-g-6">
								<div className="ui-g">
									<TZMTable paginatorLeft={
										<TZMButton icon="fas fa-plus" onClick={this.adicionarElementoCusto}/>
									} paginator header="Elementos de Custo" rows={10} value={this.state.minuta.elementosCusto} footer={
										<div style={{textAlign: "right", marginRight: "4.5em"}}>
											<b>Valor Total:</b> {toCurrency(this.state.minuta.elementosCusto.map(ec => ec.valor).reduce((a, b) => a + b, 0))}
										</div>
									}>
										<Column sortable field="nome" header="Nome"/>
										<Column style={{textAlign: "right"}} field="valor" header="Valor" body={ec => toCurrency(ec.valor)}/>
										<Column style={{width: "5em", textAlign: "center"}} header="Ações" body={ec => (
											<div>
												<span title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarElementoCusto(ec)}/>
												<span title="Remover" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.removerElementoCusto(ec)}/>
											</div>
										)}/>
									</TZMTable>
								</div>
							</div>
							<div className="ui-g-6">
								<div className="ui-g">
									<TZMTable paginatorLeft={
										<TZMButton icon="fas fa-plus" onClick={this.adicionarNotaFiscal}/>
									} header="Notas Fiscais" rows={10} paginator value={this.state.minuta.notasFiscais}>
										<Column sortable field="serie" header="Série"/>
										<Column sortable field="numero" header="Número"/>
										<Column style={{width: "5em", textAlign: "center"}} header="Ações" body={nf => (
											<div>
												<span title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarNotaFiscal(nf)}/>
												<span title="Remover" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.removerNotaFiscal(nf)}/>
											</div>
										)}/>
									</TZMTable>
								</div>
							</div>
						</div>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					<TZMButton disabled={this.state.minuta.status !== "PENDENTE"} label="Salvar" icon="fas fa-save" onClick={this.onSalvar}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

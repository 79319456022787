import {httpGet, httpPost} from "../../../utilities/TZMRequest";

export class AuditoriaCteService {

	buscarPorCte(cte) {
		return httpGet(`/auditorias-cte?search=cte.id==${cte.id}`, {});
	}

	buscarStatusPorCtes(ctes) {
		return httpGet(`/auditorias-cte/ctes/${ctes.map(c => c.id).join(",")}`);
	}

	async auditar(cte) {
		return await httpPost("/auditorias-cte/auditar", cte);
	}

}


export class TransportadoraUtils { 

	static transportadoraTemplate(transportadora) {
		return `${transportadora.razaoSocial} (${transportadora.municipio} - ${transportadora.uf})`;
	}

}



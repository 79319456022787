import React, {Component} from "react";
import TZMPanel from "../../../../components/panel/TZMPanel";
import TZMForm from "../../../../components/common/TZMForm";
import TZMDialog from "../../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../../components/common/TZMButton";
import {TZMSimpleCheckbox} from "../../../../components/common/TZMSimpleCheckbox";
import moment from "moment";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {toDecimal} from "../../../../components/common/TZMFormatter";
import {RomaneioColacrilService} from "../../servicos/RomaneioColacrilService";
import {PanelContent} from "../../../../components/common/PanelContent";
import TZMTextField from "../../../../components/common/TZMTextField";
import {NeoSuggestion} from "../../../../components/common/NeoSuggestion";
import {options} from "../../../../utilities/Options";
import TZMCombobox from "../../../../components/common/TZMCombobox";
import {httpGet} from "../../../../utilities/TZMRequest";
import TZMCalendar from "../../../../components/common/TZMCalendar";

export class SelecionarOrdemServico extends Component {

	state = {
		visible: true,
		notasFiscais: [],
		ordensServico: [],
		inicio: moment().add(-2, "days").format("YYYY-MM-DD"),
		fim: moment().add(7, "days").format("YYYY-MM-DD"),
		formato: "OS"
	};

	formatos = [
		{label: "Ordem de Serviço", value: "OS"},
		{label: "Nota Fiscal", value: "NF"}
	];

	romaneioService = new RomaneioColacrilService();

	onClose = () => this.setState({visible: false});

	handleSelecionar = async () => {
		let ordensServico = this.state.ordensServico.filter(os => os._selecionado);
		for (const ordemServico of ordensServico) {
			let ordemPreenchida = await this.romaneioService.preencherDadosPesoVolume(ordemServico.ordemServico);
			ordemServico.pesoLiquido = ordemPreenchida.pesoLiquido;
			ordemServico.pesoEmbalagem = ordemPreenchida.pesoEmbalagem;
			ordemServico.pesoBruto = ordemPreenchida.pesoBruto;
			ordemServico.volumes = ordemPreenchida.volumes;
			ordemServico.unidade = ordemPreenchida.unidade;
			
		}
		let notasFiscais = this.state.notasFiscais.filter(nf => nf._selecionado);
		for (const ordemServico of notasFiscais) {
			let ordensServico = await this.romaneioService.preencherDadosFiscaisNf(ordemServico.notaFiscalId);
			ordemServico.ordensServico = ordensServico;
		}
		
		if (this.props.onModalClose) {
			this.props.onModalClose([...ordensServico, ...notasFiscais]);
		}
		this.onClose();
	}

	toggleAllOrdensServico = () => {
		const {ordensServico} = this.state;
		const value = ordensServico.some(os => os._selecionado);
		ordensServico.forEach(os => os._selecionado = !value);
		this.setState({ordensServico});
	}

	toggleAllNotasFiscais = () => {
		const {notasFiscais} = this.state;
		const value = notasFiscais.some(nf => nf._selecionado);
		notasFiscais.forEach(nf => nf._selecionado = !value);
		this.setState({notasFiscais});
	}

	handleChangeOrdemServico = event => {
		const {ordensServico} = this.state;
		ordensServico[event.index][event.name] = event.value;
		this.setState({ordensServico});
	}

	handleChangeNotaFiscal = event => {
		const {notasFiscais} = this.state;
		notasFiscais[event.index][event.name] = event.value;
		this.setState({notasFiscais});
	}

	handleChange = event => this.setState({[event.name]: event.value});

	listarOrdensServico = () => {
		const params = [
			`transportadora=${this.props.transportadora.vwTransportadora.pk.fornecedorId}`
		];
		if (this.state.cliente?.id) params.push(`cliente=${this.state.cliente.id}`);
		if (this.state.municipio?.id) {
			params.push(`municipio=${this.state.municipio.codigo}`);
		} else {
			if (this.state.uf?.length) params.push(`uf=${this.state.uf}`);
		}
		if (this.state.os) params.push(`os=${this.state.os}`);
		if (this.state.pedido) params.push(`pedido=${this.state.pedido}`);
		if (this.state.inicio?.length) params.push(`inicio=${this.state.inicio}`);
		if (this.state.fim?.length) params.push(`fim=${this.state.fim}`);
		if (this.state.produto?.length) params.push(`produto=${this.state.produto}`);
		switch (this.state.formato) {
		case "OS":
			httpGet(`/ordens-servico/ordens-servico-transportadora?${params.join("&")}`).then(ordensServico => this.setState({ordensServico}));
			break;
		case "NF":
			httpGet(`/ordens-servico/notas-fiscais-transportadora?${params.join("&")}`).then(notasFiscais => this.setState({notasFiscais}));
			break;
		default:
			break;
		}
	}

	render() {
		return (
			<TZMDialog style={{width: "1510px"}}  visible={this.state.visible}  modal header="Selecionar Ordens de Serviço" onClose={this.onClose}>
				<TZMPanel>
					<TZMForm>
						<PanelContent>
							<TZMCombobox options={this.formatos} col={2} name="formato" label="Filtro" value={this.state.formato} onChange={this.handleChange}/>
							<TZMCalendar col={1} name="inicio" label="Período de Entrega" value={this.state.inicio} onChange={this.handleChange}/>
							<TZMCalendar col={1} name="fim" label="&nbsp;" value={this.state.fim} onChange={this.handleChange}/>
							<NeoSuggestion.Cliente col={4} name="cliente" value={this.state.cliente} onChange={this.handleChange}/>
							<NeoSuggestion.Municipio col={3} name="municipio" value={this.state.municipio} onChange={this.handleChange}/>
							<TZMCombobox label="UF" disabled={this.state.municipio?.id} col={1} options={options.ufs} name="uf" value={this.state.municipio?.siglaUf || this.state.uf} onChange={this.handleChange}/>
							<TZMTextField placeholder="Pesquise por um ou mais números separados por vírgula" col={4} label="Número do Pedido" name="pedido" value={this.state.pedido} onChange={this.handleChange}/>
							<TZMTextField disabled={this.state.formato === "NF"} placeholder="Pesquise por um ou mais números separados por vírgula" col={5} label="Número da OS" name="os" value={this.state.os} onChange={this.handleChange}/>
							<TZMTextField disabled={this.state.formato === "NF"} placeholder="Pesquise por SKU ou descrição" col={3} label="Produto" name="produto" value={this.state.produto} onChange={this.handleChange}/>
							<div style={{width: "100%", textAlign: "right", padding: ".5em 0"}}>
								<TZMButton onClick={this.listarOrdensServico} label="Procurar" icon="fa fa-search"/>
							</div>
						</PanelContent>
						<PanelContent>
							{
								this.state.formato === "OS" ? (
									<div className="ui-g-12">
										<DataTable value={this.state.ordensServico} scrollable scrollHeight="500px" emptyMessage="Nenhum registro encontrado">
											<Column style={{width: "3.5em", textAlign: "center"}} header={(
												<TZMSimpleCheckbox checked={this.state.ordensServico.some(os => os._selecionado)} onChange={this.toggleAllOrdensServico}/>
											)} body={(os, i) => (
												<TZMSimpleCheckbox index={i.rowIndex} key={Math.random()} checked={os._selecionado} name="_selecionado" onChange={this.handleChangeOrdemServico}/>
											)}/>
											<Column name="ordemServico" style={{width: "8em", textAlign: "right"}} header="OS" field="ordemServico" key="ordemServico"/>
											<Column style={{width: "8em"}} header="Pedido Item" field="pedidoId" key="pedidoId" name="pedidoId" body={(rowData) => `${rowData.pedidoId} - ${rowData.itemId}`}/>
											<Column name="cliente" style={{width: "*"}} header="Cliente" field="clienteRazaoSocial" key="cliente"/>
											<Column style={{width: "*"}} header="Produto" field="produtoDescricao" key="produtoDescricao" name="produtoDescricao"/>
											<Column style={{width: "8em", textAlign: "right"}} header="Quantidade" field="quantidade" key="quantidade" name="quantidade" body={(rowData) => toDecimal(Math.round(rowData.quantidade))}/>
											<Column style={{width: "8em", textAlign: "center"}} name="dataEntrega" key="dataEntrega" header="Data Entrega" field="dataEntrega" body={os => moment(os.dataEntrega).format("DD/MM/YYYY")}/>
											<Column style={{width: "8em", textAlign: "center"}} header="Frete" field="frete" name="frete" key="frete"/>
											<Column style={{width: "8em", textAlign: "right"}} header="m²"  name="m2" key="m2" body={(rowData) => toDecimal(Math.round(rowData.m2))}/>
										</DataTable>
									</div>
								) : (
									<div className="ui-g-12">
										<DataTable value={this.state.notasFiscais} scrollable scrollHeight="500px" emptyMessage="Nenhum registro encontrado">
											<Column style={{width: "3.5em", textAlign: "center"}} header={(
												<TZMSimpleCheckbox checked={this.state.notasFiscais.some(nf => nf._selecionado)} onChange={this.toggleAllNotasFiscais}/>
											)} body={(nf, i) => (
												<TZMSimpleCheckbox index={i.rowIndex} key={Math.random()} checked={nf._selecionado} name="_selecionado" onChange={this.handleChangeNotaFiscal}/>
											)}/>
											<Column name="notaFiscalId" style={{width: "8em", textAlign: "right"}} header="Nota Fiscal" field="notaFiscalId" key="notaFiscalId" />
											<Column style={{width: "8em"}} header="Pedido" field="pedidoId" key="pedidoId" name="pedidoId" />
											<Column name="cliente" style={{width: "*"}} header="Cliente" field="clienteRazaoSocial" key="cliente" />
											<Column style={{width: "8em", textAlign: "center"}} name="dataEmissao" key="dataEmissao" header="Data Emissão" field="dataEmissao" body={os => moment(os.dataEmissao).format("DD/MM/YYYY")}/>
											<Column style={{width: "8em", textAlign: "center"}} header="Frete" field="frete" name="frete" key="frete"/>
											<Column style={{width: "8em", textAlign: "center"}} header="Quantidade" field="volumes" name="volumes" key="volumes"/>
											<Column style={{width: "8em", textAlign: "center"}} header="Unidade" field="unidade" name="unidade" key="unidade"/>
											<Column style={{width: "8em", textAlign: "right"}} header="Peso Bruto"  name="pesoBruto" key="pesoBruto" body={(rowData) => toDecimal(rowData.pesoBruto)} />
											<Column style={{width: "8em", textAlign: "right"}} header="Peso Líquido"  name="pesoLiquido" key="pesoLiquido" body={(rowData) => toDecimal(rowData.pesoLiquido)} />
											<Column style={{width: "8em", textAlign: "right"}} header="m²"  name="m2" key="m2" body={(rowData) => toDecimal(Math.round(rowData.quantidadeProduzida
												))} />
										</DataTable>
									</div>
								)
							}
						</PanelContent>
					</TZMForm>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton success label="Adicionar" title="Adicionar selecionados" icon="fas fa-check" onClick={this.handleSelecionar}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

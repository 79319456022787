import React from "react";
import TZMButton from "../../../../components/common/TZMButton";
import TZMDialog from "../../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../../components/panel/TZMPanelFooter";
import TZMTabView from "../../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../../components/panel/TZMTabPanel";
import TZMUserData from "../../../../utilities/TZMUserData";
import TZMTable from "../../../../components/table/TZMTable";
import {Column} from "primereact/components/column/Column";
import TZMAutoComplete from "../../../../components/common/TZMAutoComplete";
import {toCurrency, toDecimal} from "../../../../components/common/TZMFormatter";
import TZMCalendar from "../../../../components/common/TZMCalendar";
import {ModalRealm} from "../../../../components/modal/ModalRealm";
import {TransportadoraUtils} from "../../transportadora/TransportadoraUtils";
import {TransportadoraService} from "../../servicos/TransportadoraService";
import {TipoVeiculoService} from "../../servicos/TipoVeiculoService";
import TZMTextField from "../../../../components/common/TZMTextField";
import TZMNumberField from "../../../../components/common/TZMNumberField";
import TZMCombobox from "../../../../components/common/TZMCombobox";
import {MunicipioUtils} from "../../municipio/MunicipioUtils";
import {MunicipioService} from "../../servicos/MunicipioService";
import {PlacaMercosul} from "../../veiculos/PlacaMercosul";
import "./Romaneio";
import TZMConfirm from "../../../../components/dialog/TZMConfirm";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import {Button} from "primereact/components/button/Button";
import {Validator} from "../../../../utilities/TZMValidator";
import TZMPopup from "../../../../components/dialog/TZMPopup";
import TZMTextArea from "../../../../components/common/TZMTextArea";
import {SelecionarOrdemServico} from "./SelecionarOrdemServico";
import {EditarMotorista} from "../../motorista/EditarMotorista";
import {normalizeQuery} from "../../../../utilities/StringUtils";
import {MotoristaService} from "../../../servicos/MotoristaService";
import { RomaneioColacrilService } from "../../servicos/RomaneioColacrilService";
import "./Romaneio.css";
import moment from "moment";
import { DataTable } from "primereact/components/datatable/DataTable";
import { RomaneioUtils } from "./RomaneioUtils";
import { Observacao } from "./Observacao";

const BARRICA = "BARRICA";
const CAIXA = "CAIXA";
const PALLET = "PALLET";
const DESCONHECIDO = "DESCONHECIDO";

export class EditarRomaneio extends React.Component {

	state = {
		visible: true,
		romaneio: Object.assign({itens: []}, this.props.romaneio),
		romaneioItem: {params: ""},
		ordensServico: []
	};

	romaneioService = new RomaneioColacrilService();		
	transportadoraService = new TransportadoraService();
	tipoVeiculoService = new TipoVeiculoService();
	municipioService = new MunicipioService();
	motoristaService = new MotoristaService();

	componentDidMount() {		
		this.tipoVeiculoService.listar().then(tiposVeiculo => {
			this.setState({
				tipoVeiculo: tiposVeiculo[0],
				tiposVeiculo: [
					<option key={0} value={null}>Qualquer</option>,
					...tiposVeiculo.map(tv => <option key={tv.id} value={JSON.stringify(tv)}>{tv.descricao}</option>)
				]
			});
		});
		if (!this.state.romaneio.id) {
			this.preencherOrigemDestino();		
		}
	}

	preencherOrigemDestino = () => {
		let romaneio = this.state.romaneio;
		let origem,destino; 
		this.municipioService.findByNomeOrCodigo({municipio: 4104303}).then((responseOrigem) => {
			origem = responseOrigem[0];
			this.municipioService.findByNomeOrCodigo({municipio: 3550308}).then((responseDestino) => {
				destino = responseDestino[0];
				romaneio.origem = origem;
				romaneio.destino = destino;				
				this.setState({romaneio});
			});
		});
	}

	onClose = () => this.setState({visible: false});

	autoCompleteTransportadora = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((response) => {
			this.setState({transportadoras: response});
		});
	};

	autoCompleteTransportadoraRedespacho = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((response) => {
			this.setState({transportadorasRedespacho: response});
		});
	};

	findByNomeOrCodigoOrigem = (event) => {
		this.municipioService.findByNomeOrCodigo({municipio: event.query}).then((municipiosOrigem) => this.setState({municipiosOrigem}));
	};

	findByNomeOrCodigoDestino = (event) => {
		this.municipioService.findByNomeOrCodigo({municipio: event.query}).then((municipiosDestino) => this.setState({municipiosDestino}));
	};


	editorRedespacho = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMAutoComplete itemTemplate={TransportadoraUtils.transportadoraTemplate} index={props.rowIndex}
								 onClear={this.handleClearItem}
								 onChange={this.handleUpdateItem}
								 suggestions={this.state.transportadorasRedespacho}
								 completeMethod={this.autoCompleteTransportadoraRedespacho} name="redespacho"
								 field="razaoSocial"
								 placeholder="Razão social, fantasia ou CNPJ"
								 value={this.state.romaneio.itens[props.rowIndex].redespacho}
								 onSelect={(event) => {
									 this.transportadoraService.completar(event.value.id).then(transportadora => {
										 let romaneio = this.state.romaneio;
										 romaneio.itens[props.rowIndex].redespacho = transportadora;
										 this.setState({romaneio});
									 });
								 }}/>
			</div>
		);
	};

	handleUpdateItem = (event) => {
		let romaneio = this.state.romaneio;
		romaneio.itens[event.index][event.name] = event.value;
		this.setState({romaneio});
	};

	handleChange = (event, callback) => {
		const {romaneio} = this.state;
		switch (event.name) {
			case "placaCarreta":
			case "placaCavalo":
				romaneio[event.name] = event.value.toUpperCase();
				break;
			default:
				romaneio[event.name] = event.value;
				break;
		}
		this.setState({romaneio}, callback);
	};

	handleClear = (event) => {
		let romaneio = this.state.romaneio;
		romaneio[event.name] = null;
		this.setState({romaneio});
	};

	handleClearItem = (event) => {
		let romaneio = this.state.romaneio;
		romaneio.itens[event.index][event.name] = null;
		this.setState({romaneio});
	};

	editorPeso = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMNumberField min={0} onChange={this.handleUpdateItem} index={props.rowIndex} name="pesoBruto" value={this.state.romaneio.itens[props.rowIndex]?.pesoBruto?.toString()}/>
			</div>
		);
	};

	validarFaturamento = () => {
		if (this.validar()) {
			let messages = [];
			let romaneio = this.state.romaneio;

			if (!Validator.isEntidade(romaneio.destino)) {
				messages.push("Município de destino não informado.");
			}

			romaneio.itens.forEach((item, index) => {
				// if (!item.quantidade || item.quantidade === 0) {
				// 	messages.push(`Quantidade não informada ou menor que 0(zero) para a O.S ${item.ordemServico}`);
				// }
				// if (!item.unidade) {
				// 	messages.push(`Unidade não informada para a O.S ${item.ordemServico}.`);
				// }				
			});
			if (messages.length > 0) {
				ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages}/>);
				return false;
			}
			return true;
		}
		return false;
	};

	validarFinalizar = () => {
		if (this.validarFaturamento()) {
			let messages = [];
			let romaneio = this.state.romaneio;
			romaneio.itens.forEach((item, index) => {
				if (!item.notaFiscalId) {
					messages.push(`Nota fiscal não informada a O.S ${item.ordemServico}.`);					
				}
			});
			if (messages.length > 0) {
				ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages}/>);
				return false;
			}
			return true;
		}
	};

	validar = () => {
		let messages = [];
		let romaneio = this.state.romaneio;
		if (!romaneio.transportadora) {
			messages.push("Transportadora não informada.");
		}
		if (!romaneio.motorista?.id) {
			messages.push("Motorista não informado.");
		}
		if (!romaneio.lacre || !romaneio.lacre.length) {
			messages.push("Número do lacre não informado.");
		}
		 if (!romaneio.tipoVeiculo) {
		 	messages.push("Tipo de veículo não informado.");
		 }
		if (!Validator.isEntidade(romaneio.origem)) {
			messages.push("Município de origem não informado.");
		}
		if (!Validator.isEntidade(romaneio.destino)) {
			messages.push("Município de destino não informado.");
		}
		if (romaneio.itens == null || romaneio.itens.length === 0) {
			messages.push("Nenhum item adicionado.");
		} 

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages}/>);
			return false;
		}
		return true;
	};

	editorM2 = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMNumberField min={0} onChange={this.handleUpdateItem} index={props.rowIndex} name="quantidadeM2" value={this.state.romaneio.itens[props.rowIndex].quantidadeM2}/>
			</div>
		);
	};

	editorQuantidade = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMNumberField type="number" min={0} onChange={this.handleUpdateItem} index={props.rowIndex} name="volumes" value={this.state.romaneio.itens[props.rowIndex].volumes.toString()}/>
			</div>
		);
	};

	editorUnidade = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMCombobox field="unidade" name="unidade" index={props.rowIndex} onChange={this.handleUpdateItem} value={this.state.romaneio.itens[props.rowIndex].unidade}>
					{this.optionsUnidade}
				</TZMCombobox>
			</div>
		);
	};

	salvar = () => {
		if (this.validar()) {
			this.romaneioService.salvar(this.state.romaneio).then((romaneio) => {
				if (this.props.onModalClose) this.props.onModalClose(romaneio);
				this.onClose();
			});
		}
	};

	preencherDadosFiscais = () => {
		this.romaneioService.preencherDadosFiscaisOs(this.state.romaneio).then((completo) => {
			this.setState({romaneio: completo})
		});
	}

	// faturamento = () => {
	// 	if (this.validarFaturamento()) {
	// 		let romaneio = this.state.romaneio;
	// 		romaneio.status = "FATURAMENTO";
	// 		ModalRealm.showDialog(<TZMConfirm key={Math.random()} onYes={() => {
	// 		 	this.romaneioService.salvar(romaneio).then((result) => {
	// 				this.romaneioService.preencherDadosFiscaisNf(result).then((completo) => {
	// 					this.setState({romaneio: completo})
					
	// 				});
	// 			});
	// 		}} question="Tem certeza de que deseja encaminhar o romaneio para o faturamento?"/>);
	// 	}
	// };

	finalizar = () => {
		if (this.validarFinalizar()) {
			let romaneio = this.state.romaneio;
			romaneio.status = "FINALIZADO";
			ModalRealm.showDialog(<TZMConfirm key={Math.random()} onYes={() => {
				this.romaneioService.salvar(romaneio).then((result) => {
					if (this.props.onModalClose) this.props.onModalClose(result);
					this.onClose();
				});
			}} question="Tem certeza de que deseja finalizar o romaneio?"/>);
		}
	};

	excluirItem = (item) => {
		if (item.id) {
			ModalRealm.showDialog(<TZMConfirm key={Math.random()} onYes={() => {
				let romaneio = this.state.romaneio;
				romaneio.itens = romaneio.itens.filter(f => f !== item).map((a, i) => {a.item = i + 1; return a;});
				this.setState({romaneio});
			}} question="Tem certeza de que deseja remover este item?"/>);
		} else {
			let romaneio = this.state.romaneio;
			romaneio.itens = romaneio.itens.filter(f => f !== item);
			this.setState({romaneio});
		}
	};

	selectTransportadora = async (event) => {
		if (event.value != null) {
			const {romaneio} = this.state;
			await this.transportadoraService.completar(event.value.id).then(transportadora => {
				romaneio.transportadora = transportadora;
				this.setState({romaneio});
			});
		}
	};
	
	ordensServico = (rowData) => {
		return (
			<div>

				{rowData.ordensServico ? rowData.ordensServico.map((ordemServico) => {
					return (
						<div key={TZMUserData.getIdGenerator()} style={{
							backgroundColor: "#607D8B", padding: "2px 10px 2px 10px", borderRadius: "16px", margin: "2px", fontSize: "12px", color: "#FFF", display: "inline-block"
						}} >{`${ordemServico.ordemServico} `}
						</div>
					);
				}) : null}
			</div>
		);
	}

	optionsUnidade = [
		<option key={0} value={DESCONHECIDO}>Desconhecido</option>,
		<option key={1} value={BARRICA}>Barrica</option>,
		<option key={2} value={CAIXA}>Caixa</option>,
		<option key={3} value={PALLET}>Pallet</option>
	];
	
	clienteRetira = (rowData) => {
		if (rowData.clienteRetira) {
			return  <span className="fas fa-user-check" title="Cliente vai retirar o pedido direto na transportadora" 
			style={{textAlign: "center",  color: "#14b64a"}} />
		} 
		return null;
	}

	columnStatus = (rowData) => {		
		return (
			<div className='ui-g' >
				<div className='ui-g-12' style={{ textAlign: "center" }}>
					{RomaneioUtils.columnStatus(rowData)}&nbsp;					
					<span key={5} className={`fas fa-comments ui-tzm-icon`} title="Observação" onClick={() => this.showObservacao(rowData)} />&nbsp;
					{this.clienteRetira(rowData)}
				</div>
			</div>
		);
	}

	notaFiscal = (item) => {
		return (
			<div className="campo-impostos">
				<div>Número:</div><div style={{textAlign: "right"}}>{item.notaFiscalId}</div>
				<div>Valor:</div><div style={{textAlign: "right"}}>{item.valorNotaFiscal.toCurrency()}</div>
			</div>
		);
	}	

	columns = [
		<Column expander key="expander" style={{textAlign: "center", width: "2em"}}/>,
		<Column key="item" field="item" style={{textAlign: "center", width: "2em"}}/>,		
		<Column header="Pedido" key="pedido" field="pedidoId" style={{textAlign: "center", width: "6em"}}/>,
		<Column key="ordemServico" style={{ width: "15em", textAlign: "center" }} body={this.ordensServico} header="O.S" />,
		<Column name="cliente" style={{width: "*"}} header="Cliente" field="cliente.vwCliente.razaoSocial" key="cliente" />,
		<Column header="Quantidade" key="volumes" style={{width: "6em",  textAlign: "right"}} body={this.editorQuantidade}/>,
		<Column header="Unidade" key="unidade" style={{width: "10em"}} body={this.editorUnidade}/>,
		<Column header="Peso/Kg" key="pesoBruto" field="pesoBruto" style={{width: "8em", textAlign: "right"}} body={this.editorPeso}/>,
		<Column header="M2" key="m2" field="m2" style={{width: "8em", textAlign: "right"}} body={this.editorM2}/>,
		<Column key="notaFiscal" style={{width: "14em"}} header="Nota Fiscal" body={ (item) => this.notaFiscal(item)} />,
		<Column header="Redespacho" key="redespacho" style={{width: "15em", textAlign: "center"}} body={this.editorRedespacho}/>,
		<Column header="Status" key="status" style={{width: "6em", textAlign: "center"}} field="status" body={this.columnStatus}/>,
		<Column style={{width: "4em"}} key="acoes" header="Ações" body={(rowData) => {
			let botoes = [];
			botoes.push(<span key={3} title="Remover" className="fa fa-trash-alt ui-tzm-icon"
							  onClick={() => this.excluirItem(rowData)}/>);
			return <div style={{textAlign: "center"}}>{botoes}</div>;
		}}/>
	];



	showObservacao = (rowData) => {
		ModalRealm.showDialog(<Observacao key={TZMUserData.getIdGenerator()} observacao={rowData.observacao} />);
	}

	optionsStatus = [
		<option key={0} value={null}/>,
		<option key={1} value="EXPEDICAO">Expedição</option>,
		<option key={2} value="FATURAMENTO">Faturamento</option>,
		<option key={3} value="FINALIZADO">Finalizado</option>,
		<option key={4} value="CANCELADO">Cancelado</option>
	];

	procurarOrdensServico = () => {
		ModalRealm.showDialog(<SelecionarOrdemServico key={Math.random()} transportadora={this.state.romaneio.transportadora} onModalClose={oss => {
			const {romaneio} = this.state;
			if (!romaneio.itens) {
				romaneio.itens = [];
			}
			let item = romaneio.itens.map(ri => ri.item).sort((a, b) => b - a)[0] || 0;
			for (const os of oss) {
				const index = romaneio.itens.findIndex(ri => ri.pedidoId === os.pedidoId && ri.notaFiscalId === os.notaFiscalId);				
				let statusItem,ordens,valorNotaFiscal,notaFiscalId;
				if (index === -1) {
					if (os.origem === 'NOTA_FISCAL') {
						ordens = os.ordensServico;
						valorNotaFiscal = os.valorNotaFiscal;
						notaFiscalId = os.notaFiscalId;
						statusItem = 'FATURADO';
					} else {
						statusItem =  'EXPEDIDO';
						valorNotaFiscal = 0;
						notaFiscalId = 0;
						ordens = [
							{
								ordemServico: os.ordemServico, 
								produtoId: os.produtoId,
								produtoDescricao: os.produtoDescricao,
								itemPedidoId: os.itemId,
								dataEntrega: os.dataEntrega,
								m2:  os.quantidadeProduzida  
							}
						];
					}
					romaneio.itens.push( {
						item: ++item,
						itemId: os.itemId,
						observacao: os.texto,
						cliente: {id: os.clienteId, vwCliente: {razaoSocial: os.clienteRazaoSocial}},					
						pedidoId: os.pedidoId,
						clienteRetira: os.clienteRetira,
						redespacho: os.transportadoraRedespachoId ? {id: os.transportadoraRedespachoId, razaoSocial: os.transportadoraRedespachoRazaoSocial} : null,
						quantidadeM2: os.quantidadeProduzida,
						pesoLiquido: os.pesoLiquido,						
						pesoBruto: os.pesoBruto,
						volumes: os.volumes,
						frete: os.frete,
						unidade: os.unidade,
						valorNotaFiscal: valorNotaFiscal,
						notaFiscalId: notaFiscalId,
						status: statusItem,
						origem: os.origem,						
						ordensServico: ordens,
						dataEntrega: os.dataEntrega
					});
				} else {
					romaneio.itens[index].ordensServico.push(
						{
							ordemServico: os.ordemServico, 
							produtoId: os.produtoId,
							produtoDescricao: os.produtoDescricao,
							itemPedidoId: os.itemId,
							dataEntrega: os.dataEntrega,
							quantidadeM2: os.quantidadeM2
						}
					);
				}				
			}
			this.setState({romaneio});
		}}/>);
	}

	listarMotoristas = event => {
		event.query = normalizeQuery(event.query);
		this.motoristaService.autoComplete(`search=status==ATIVO;nome=ik='${event.query}',cpf=ik='${event.query}',rg=ik='${event.query}',cnh=ik='${event.query}'`).then(motoristas => {
			if (!motoristas.length) {
				motoristas.push({id: -1, nome: `${this.CRIAR_CADASTRO_MOTORISTA}${event.query}`});
			}
			this.setState({motoristas});
		});
	}

	CRIAR_CADASTRO_MOTORISTA = "Criar cadastro de motorista para ";

	onSelectMotorista = event => {
		if (event.value.id) {
			if (event.value.id === -1) {
				const {nome} = event.value;
				event.value = null;
				this.handleChange(event, () => {
					ModalRealm.showDialog(<EditarMotorista motorista={{
						id: null,
						nome: nome.replace(this.CRIAR_CADASTRO_MOTORISTA, "")
					}} onModalClose={motorista => {
						event.value = motorista;
						this.handleChange(event);
					}}/>);
				});
			} else {
				this.handleChange(event);
			}
		}
	}

	toggleOrdemServico = event => this.setState({ordensServico: event.data});

	
	ordemServicoTemplate = romaneioItem => {
		return (
			<div>
				<DataTable value={romaneioItem.ordensServico} selection={this.state.selectedItemPedido}>
					<Column style={{width: "8em", textAlign: "center"}} name="dataEntrega" key="dataEntrega" header="Data Entrega" field="dataEntrega" body={os => moment(os.dataEntrega).format("DD/MM/YYYY")}/>
					<Column style={{width: "8em", textAlign: "right"}} header="Ordem Serviço" field="ordemServico"/>
					<Column key="itemPedidoId" field="itemPedidoId"  header="Item Pedido"  style={{textAlign: "center", width: "8em"}}/>
					<Column style={{ width: "15em"}} header="Produto" field="produtoId"/>
					<Column style={{width: "*"}} header="Descrição" field="produtoDescricao"/>
					<Column header="Quantidade M2" key="quantidadeM2" style={{ width: "15em", textAlign: "right"}} field="quantidadeM2" />
					<Column header="Valor Item NF" key="valorNf" field="valorNotaFiscalItem" style={{width: "15em", textAlign: "right"}} body={(rowData) => toCurrency(rowData.valorNotaFiscalItem)}/>
				</DataTable>
			</div>
		);
	}

	render() {
		let mostrarItens = Validator.isEntidade(this.state.romaneio.transportadora) 
							&& Validator.isEntidade(this.state.romaneio.origem) 
							&& Validator.isEntidade(this.state.romaneio.destino)
							&& Validator.isEntidade(this.state.romaneio.tipoVeiculo);
		let desativado = this.state.romaneio.itens.length > 0;
		let pesoBruto = this.state.romaneio.itens.map((item) => item.pesoBruto).reduce((a, b) => a + b, 0);	
		return (
			<TZMDialog style={{width: "1510px"}} visible={this.state.visible} modal header="Romaneio"
					   onClose={this.onClose} {...this.props}>
				
					<TZMTabView onTabChange={(event) => this.setState({selectedTab: event.index})}
								activeIndex={this.state.selectedTab}>
						<TZMTabPanel
							header={<span><span className="ui-tab-number" children="1"/>Dados Principais</span>}>
							<div className="ui-g">
								<div className="ui-g-1">
									<TZMCombobox value={this.state.romaneio.status} name="status" label="Status" disabled>
										{this.optionsStatus}
									</TZMCombobox>
								</div>
								<div className="ui-g-2">
									<TZMCalendar label="Data de Emissão" name="dataEmissao" onChange={this.handleUpdate} value={this.state.romaneio.dataEmissao}/>
								</div>
								<div className="ui-g-4">
									<TZMAutoComplete itemTemplate={TransportadoraUtils.transportadoraTemplate}
														onClear={this.handleClear} onSelect={this.selectTransportadora}
														onChange={this.handleChange}
														placeholder="Razão social, fantasia ou CNPJ"
														suggestions={this.state.transportadoras}
														completeMethod={this.autoCompleteTransportadora}
														name="transportadora"
														disabled={desativado}
														label="Transportadora"
														value={this.state.romaneio.transportadora}
														field="razaoSocial"/>
								</div>
								<div className="ui-g-2">
									<TZMTextField name="lacre" value={this.state.romaneio.lacre} label="Nº Lacre" onChange={this.handleChange}/>
								</div>
								<TZMAutoComplete col={3} onSelect={this.onSelectMotorista} onClear={this.handleClear} onChange={this.handleChange} placeholder="Nome, CPF, RG ou CNH" suggestions={this.state.motoristas} completeMethod={this.listarMotoristas} name="motorista" label="Motorista" value={this.state.romaneio.motorista} field="nome"/>
								<div className="ui-g-3">
									<TZMAutoComplete onClear={this.handleClear}
														itemTemplate={MunicipioUtils.municipioTemplate}
														onSelect={this.handleChange}
														suggestions={this.state.municipiosOrigem}
														completeMethod={this.findByNomeOrCodigoOrigem} name="origem"
														field="nome"
														disabled={desativado}
														placeholder="Nome, Código IBGE" onChange={this.handleChange}
														value={this.state.romaneio.origem} label="Munícipio Origem"/>
								</div>
								<div className="ui-g-3">
									<TZMAutoComplete onClear={this.handleClear}
														itemTemplate={MunicipioUtils.municipioTemplate}
														onSelect={this.handleChange}
														suggestions={this.state.municipiosDestino}
														completeMethod={this.findByNomeOrCodigoDestino} name="destino"
														field="nome"
														disabled={desativado}
														placeholder="Nome, Código IBGE" onChange={this.handleChange}
														value={this.state.romaneio.destino} label="Munícipio Destino"/>
								</div>
								<div className="ui-g-2">
									<TZMCombobox children={this.state.tiposVeiculo} label="Tipo de Veículo" value={JSON.stringify(this.state.romaneio.tipoVeiculo)} 
													disabled={desativado} name="tipoVeiculo" onChange={this.handleChange}/>
								</div>
								<div className="ui-g-2 ui-g-nopad">
									<div className="ui-g-5">
										<TZMTextField name="placaCarreta" maxLength={7} value={this.state.romaneio.placaCarreta} label="Placa Carreta" onChange={this.handleChange}/>
									</div>
									<div className="ui-g-7 cliente-tipo">
										<PlacaMercosul placa={this.state.romaneio.placaCarreta}/>
									</div>
								</div>
								<div className="ui-g-2 ui-g-nopad">
									<div className="ui-g-5">
										<TZMTextField name="placaCavalo" maxLength={7} value={this.state.romaneio.placaCavalo} label="Placa Cavalo" onChange={this.handleChange}/>
									</div>
									<div className="ui-g-7 cliente-tipo">
										<PlacaMercosul placa={this.state.romaneio.placaCavalo}/>
									</div>
								</div>

								<div className="ui-g-6"/>

								<div className="ui-g-6">
									<TZMTextArea name="observacao" field="observacao" onChange={this.handleChange} maxLength="4000" style={{height: "110px"}} label="Observações de Embarque" value={this.state.romaneio.observacao}/>
								</div>
							</div>
						</TZMTabPanel>
						<TZMTabPanel header={<span><span className="ui-tab-number" children="2"/>Itens</span>}>
							{ mostrarItens ?	
								<div className="ui-g">
									<TZMTable value={this.state.romaneio.itens} paginator rows={10}
												rowExpansionTemplate={this.ordemServicoTemplate}
												onRowToggle={this.toggleOrdemServico}
												expandedRows={this.state.ordensServico}
												rowsPerPageOptions={[10, 20, 30]} pageLinkSize={10}
												emptyMessage="Nenhum item adicionado"
												children={this.columns} paginatorLeft={
										<div style={{textAlign: "left"}}>
											<Button icon="fas fa-clipboard-list" title="Procurar Ordens de Serviço" className="tzm-paginator-button" onClick={this.procurarOrdensServico}/>
											{/* <Button icon="fas fa-file-invoice-dollar" title="Procurar Notas Fiscais" className="tzm-paginator-button" onClick={this.procurarNotasFiscais}/> */}
											</div>}
										paginatorRight={
											<div style={{textAlign: "right"}}>
											<Button icon="fas fa-file-invoice-dollar" title="Atualizar Dados Fiscais" className="tzm-paginator-button ui-button-warning" onClick={this.preencherDadosFiscais}/>
											</div>}
												footerColumnGroup={
													<ColumnGroup>
														<Row>
															<Column colSpan={5} style={{fontSize: "12px", textAlign: "left"}} footer={"Total de Registros: " + this.state.romaneio.itens.length}/>
															<Column style={{textAlign: "right"}} footer={toDecimal(this.state.romaneio.itens.map((item) => item.volumes).reduce((a, b) => a + b, 0))}/>
															<Column/>
															<Column style={{textAlign: "right"}} footer={toDecimal(pesoBruto)}/>
															<Column style={{textAlign: "right"}} footer={toDecimal(this.state.romaneio.itens.map((item) => item.quantidadeM2).reduce((a, b) => a + b, 0))}/>
															<Column style={{textAlign: "right"}} footer={toCurrency(this.state.romaneio.itens.map((item) => item.valorNotaFiscal).reduce((a, b) => a + b, 0))}/>
															<Column colSpan={3}/>
														</Row>
														<Row>
															<Column colSpan={5} style={{
																fontSize: "12px",
																fontWeight: "bold",
																textAlign: "right"
															}} footer="Totais de Barricas/Caixas/Pallets"/>
															<Column style={{
																fontSize: "12px",
																fontWeight: "bold",
																textAlign: "right"
															}} footer={
																toDecimal(this.state.romaneio.itens.filter(v => v.unidade === BARRICA).map((item) => item.volumes).reduce((a, b) => a + b, 0)) + "/" +
																toDecimal(this.state.romaneio.itens.filter(v => v.unidade === CAIXA).map((item) => item.volumes).reduce((a, b) => a + b, 0)) + "/" +
																toDecimal(this.state.romaneio.itens.filter(v => v.unidade === PALLET).map((item) => item.volumes).reduce((a, b) => a + b, 0))
															}/>										
															<Column style={{
																fontSize: "12px",
																fontWeight: "bold",
																textAlign: "right"
															}} footer="Limite Veículo KG"/>
															<Column style={{
																fontSize: "12px",
																fontWeight: "bold",
																textAlign: "right"
															}} footer={toDecimal(this.state.romaneio.tipoVeiculo.capacidade)}/>		
															<Column />
															<Column colSpan={4} style={{
																fontSize: "12px",
																fontWeight: "bold",
																textAlign: "right",
																color: "#e70000"
															}} footer={pesoBruto > this.state.romaneio.tipoVeiculo.capacidade ? 'Ultrapassou o limite de peso do veículo' : ''}/>		

														</Row>
													</ColumnGroup>}/>
										
									
								</div>
								:
									<div className="ui-g">
										<div className="ui-selecione-cliente">
											<i className="fas fa-exclamation ui-selecione-cliente" style={{color: "#F44336"}}/>
											Para incluir itens ao romaneio é necessário infomar os seguintes campos:
											<div className="ui-g">
												<span className="ui-selecione-cliente-itens">{!Validator.isEntidade(this.state.romaneio.transportadora) ? ' - Transportadora ' : null }</span>
												<span className="ui-selecione-cliente-itens">{!Validator.isEntidade(this.state.romaneio.origem) ? ' - Origem ' : null }</span>
												<span className="ui-selecione-cliente-itens">{!Validator.isEntidade(this.state.romaneio.destino) ? ' - Destino ' : null }</span>
												<span className="ui-selecione-cliente-itens">{!Validator.isEntidade(this.state.romaneio.tipoVeiculo) ? ' - Tipo de Veículo ' : null }</span>
											</div>
										</div>
									</div>
							}
								
							
						</TZMTabPanel>
					</TZMTabView>
					
				
				<TZMPanelFooter>
					{/* {TZMUserData.hasRole("FRETE_ROMA_E") && this.state.romaneio.status === "EXPEDICAO" ?
						<TZMButton className="ui-button-info" style={{float: "left"}} label="Encaminhar" title="Encaminhar para o faturamento" icon="fas fa-file-invoice-dollar" onClick={this.faturamento}/>
						: null} */}
					{TZMUserData.hasRole("FRETE_ROMA_E") && this.state.romaneio.id ?
						<TZMButton className="ui-button-success"  style={{float: "left"}} label="Finalizar" title="Finalizar o romaneio" icon="fas fa-truck" onClick={this.finalizar}/>
						: null}
					{TZMUserData.hasRole("FRETE_ROMA_E") && (this.state.romaneio.status !== "FINALIZADO" && this.state.romaneio.status !== "CANCELADO") ?
						<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvar}/>
						: null}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

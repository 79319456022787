import React, {Component} from "react";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import TZMTextField from "../../../components/common/TZMTextField";
import {TipoVeiculoService} from "../servicos/TipoVeiculoService";

export class EditarTipoVeiculo extends Component {

	state = {
		visible: true,
		tipoVeiculo: this.props.tipoVeiculo
	};

	tipoVeiculoService = new TipoVeiculoService();

	onClose = () => this.setState({visible: false});

	salvarTipoVeiculo = () => {
		this.tipoVeiculoService.salvar(this.state.tipoVeiculo).then(tipoVeiculo => {
			if (this.props.onModalClose) {
				this.props.onModalClose(tipoVeiculo);
			}
			this.onClose();
		});
	}

	handleChange = (event) => {
		const {tipoVeiculo} = this.state;
		tipoVeiculo[event.name] = event.value;
		this.setState({tipoVeiculo});
	}

	render() {
		return (
			<Dialog style={{width: "300px"}} visible={this.state.visible} modal header="Tipo de Veículo" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<TZMTextField col={9} label="Descrição" name="descricao" value={this.state.tipoVeiculo.descricao} onChange={this.handleChange}/>
					<TZMTextField col={3} label="Código" name="codigo" value={this.state.tipoVeiculo.codigo} onChange={this.handleChange}/>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarTipoVeiculo}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarTipoVeiculo} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

import { AutoComplete } from "primereact/components/autocomplete/AutoComplete";
import React from "react";
import "./Common.css";

export default class TZMAutoComplete extends React.Component {
	
	state = {
		modified: false,
		empty: false
	};

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({...event, name: this.props.name, field: this.props.field, index: this.props.index});
		}
	}

	onClear = (event) => {
		if (this.props.onClear) {
			this.props.onClear({...event, name: this.props.name, field: this.props.field, index: this.props.index});
		}
	}

	onSelect = (event) => {
		if (this.props.onSelect) {
			this.props.onSelect({...event, name: this.props.name, field: this.props.field, index: this.props.index});
		}
	}

	onUnselect = (event) => {
		if (this.props.onUnselect) {
			this.props.onUnselect({...event, name: this.props.name, field: this.props.field, index: this.props.index});
		}
	}
	

	render() {
		return (
			<div className={`ui-g-${this.props.col}`}>
				<div className="ui-textfield-group" style={{position: "relative"}}>
					<label className="ui-input-label">{this.props.label}</label><br />
					<AutoComplete appendTo={document.getElementById("root")} {...this.props} minLength={this.props.minLength || 3} onClear={this.onClear} onChange={this.onChange} onUnselect={this.onUnselect}
								  onSelect={this.onSelect} className="ui-autocomplete" />					
				</div>
			</div>
		);
	}

}

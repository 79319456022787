import React, {Component} from "react";

export class StatusCte extends Component {

	printStatusCte = () => {
		switch (this.props.cte?.status) {
		case "LIDO":
			return <span><i style={{color: "#607D8B"}} className="fa fa-book-open"/> Lido</span>;
		case "PROCESSADO":
			return <span><i style={{color: "#03A9F4"}} className="fa fa-road"/> Processado</span>;
		case "AUDITADO_SUCESSO":
			return <span><i style={{color: "#4CAF50"}} className="fa fa-check"/> Sucesso</span>;
		case "AUDITADO_FALHA":
			return <span><i style={{color: "#FF9800"}} className="fa fa-exclamation-triangle"/> Divergência</span>;
		case "AUDITADO_OBSERVACOES":
			return <span><i style={{color: "#009688"}} className="fa fa-info-circle"/> Observação</span>;
		case "AUDITADO_ERRO":
			return <span><i style={{color: "#F44336"}} className="fa fa-times-circle"/> Erro</span>;
		case "AUDITADO_REVISADO":
			return <span><i style={{color: "#009688"}} className="fa fa-check-circle"/> Revisado</span>;
		default:
			return null;
		}
	}

	render() {
		return (
			<div className={`ui-g-${this.props.col}`}>
				<label className="ui-input-label">{this.props.label || "Status"}</label><br />
				<div className="statusCteInput">{this.printStatusCte()}</div>
			</div>
		);
	}

}

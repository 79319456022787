import React from "react";
import {RadioButton} from "primereact/components/radiobutton/RadioButton";

import "./Common.css";

export default class TZMRadioButton extends React.Component {

	render() {
		return (
			 <div style={{float: "left"}}>
				<RadioButton {...this.props} inputId={this.props.id} />
			 	<label className="ui-radio-label" htmlFor={this.props.id}>{this.props.label}</label>
			 </div>
		);
	}

}

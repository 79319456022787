import {httpGet, httpPost} from "../../utilities/TZMRequest";

export class FaturaService {

	buscar(id) {
		return httpGet(`/faturas/${id}`);
	}

	listar(query) {
		return httpGet("/faturas/listar", query);
	}

	cancelar(fatura) {
		return httpPost("/faturas/cancelar", fatura);
	}
}

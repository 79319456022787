import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export class TZMDecimal extends React.Component {

    handleChange = (event) => {
		event.target.value = event.target.value.replace(/,/g, ".").replace(/[^0-9.]/g, "");
		if (this.props.onChange) {
			this.props.onChange({...event, index: this.props.index, name: this.props.name, value: event.target.value});
		}
    }

    render() {
		let classes = classNames({
			"ui-textfield": true,
			"ui-input-disabled-autocomplete": this.props.forac,
			"ui-input-warning": this.props.warning,
			"ui-input-required": this.props.required && (this.props.value == null || JSON.stringify(this.props.value).trim().length === 0)
		});
		let icon = null;
		if (this.props.exclamation) icon = "ui-input-exclamation";
		if (this.props.pending) icon = "ui-input-pending";
		if (this.props.confirmed) icon = "ui-input-confirmed";
		if (this.props.integrated) icon = "ui-input-integrated";
		
		return (
			<div className={`ui-textfield-group${this.props.col ? ` ui-g-${this.props.col}` : ""}`}>
				<label className="ui-input-label">{this.props.label}</label><br />
				<div className={icon} title={this.props.exclamation}>
                	<input {...this.props} className={`${this.props.className} ${classes}`} onChange={this.handleChange} onBlur={this.handleBlur} />
					{this.props.step ? <i className="ui-help-icon fa fa-info" title={`Múltiplos de ${this.props.step}`} /> : null}
				</div>
			</div>
		);
    }

	handleBlur = (event) => {
		if (this.props.onBlur) {
			this.props.onBlur(event);
		}
		if (this.props.step) {
			let step = this.props.step;
			let value = Number(this.props.value);
			let factor = 1;
			while (step % 1) {
				step *= 10;
				factor *= 10;
			}
			let diff = ((value * factor) % step) / factor;
			if (diff) {
				value += this.props.step - diff;
			}
			this.props.onChange({name: this.props.name, index: this.props.index, value: Number(value.toFixed(3))});
		}
	}

}

TZMDecimal.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func
};

import React, {Component} from "react";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {UfService} from "../servicos/UfService";
import {byNome} from "../../../utilities/SortUtils";

export class EditarTabelaFreteOrigem extends Component {

	state = {
		visible: true,
		origem: this.props.origem,
		ufs: []
	};

	ufService = new UfService();

	componentDidMount() {
		this.ufService.listar().then(ufs => this.setState({ufs: [<option value={null}>Nenhum</option>, ...ufs.sort(byNome).map(uf => <option key={uf.id} value={JSON.stringify(uf)}>{uf.nome}</option>)]}));
	}

	onClose = () => this.setState({visible: false});

	salvarOrigem = () => {
		const {origem} = this.state;
		const messages = [];
		if (!origem.municipio && !origem.uf) messages.push("A origem é obrigatória");
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>);
		} else {
			if (this.props.onModalClose) {
				this.props.onModalClose(origem);
			}
			this.onClose();
		}
	}

	handleChange = (event) => this.setState({origem: {...this.state.origem, [event.name]: event.value}});

	render() {
		return (
			<Dialog style={{width: "1200px"}} visible={this.state.visible} modal header="Tabela de Frete - Percurso - Origem" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<NeoSuggestion.Municipio appendTo={document.body} col={8} label="Município" name="municipio" value={this.state.origem.municipio} onChange={this.handleChange}/>
					<TZMCombobox appendTo={document.body} children={this.state.ufs} col={4} label="UF" name="uf" value={JSON.stringify(this.state.origem.uf)} onChange={this.handleChange}/>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarOrigem}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarOrigem} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMLogo from "../../../components/common/TZMLogo";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMMenubar from "../../../components/menubar/TZMMenubar";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import {API, Fetch} from "../../../utilities/Fetch";
import TZMRecover from "../../../utilities/TZMRecover";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import "./Login.css";

const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Login extends React.Component {

	state = {
		capsLock: false,
		empresa: process.env.REACT_APP_EMPRESA
	};

	componentDidMount() {
		document.addEventListener("keydown", this.handleAutoLogin);
		const empresa = window.localStorage.getItem("X-User-Company") || process.env.REACT_APP_EMPRESA;
		this.setState({empresa});
	}

	handleAutoLogin = (event) => {
		this.setState({capsLock: event.getModifierState && event.getModifierState("CapsLock")});
	};

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleAutoLogin);
	}

	empresas = [
		{label: "Colacril", value: "COLACRIL"},
		{label: "RRE", value: "RRE"}
	];

	handleChangeEmpresa = (event) => {
		this.setState({empresa: event.value}, () => {
			window.localStorage.setItem("X-User-Company", event.value);
		});
	}

	doLogin = () => {
		let formData = {};
		formData.login = this.state.usuario;
		formData.senha = this.state.senha;
		API.setEmpresa(this.state.empresa);
		Fetch.Post("/login", formData).then((response) => {
			if (response.status === 200) {
				window.localStorage.setItem("X-Authorization-Key", response.headers.get("Authorization"));				
				Fetch.GetJSON("/eu").then((usuario) => {
					TZMUserData.setUsuario(usuario);
					window.location.replace("/ctes");
				});
			} else {
				switch (response.status) {
					case 401:
					case 403:
						ModalRealm.showDialog(<TZMPopup header="Advertência" messages={["Usuário e/ou senha inválidos. Por favor, tente novamente."]} />);
						break;
					default:
						ModalRealm.showDialog(<TZMPopup header="Advertência" messages={["Falha ao autenticar com o serviço. Por favor, tente novamente."]} />);
						break;
				}
			}
		}).catch((error) => {
			ModalRealm.showDialog(<TZMRecover />);
		});
	}

	handleChange = (event) => {
		this.setState({[event.name]: event.target.value});
	}

	render() {
		return (
			<div>
				<TZMMenubar>
					<div className="ui-tzm-logo" />
					<TZMLogo/>
				</TZMMenubar>
				<div className="ui-login-content"><div className="ui-login-leftpanel" /></div>
				<TZMPanel className="ui-login-panel" header={<div style={{textAlign: "center"}}>Autenticação</div>}>
					<TZMForm>
						<div className="ui-g">
							{/*<TZMCombobox col={12} name="empresa" options={this.empresas} autoFocus value={this.state.empresa} onChange={this.handleChangeEmpresa} label="Empresa"/>*/}
							<TZMTextField col={12} name="usuario" value={this.state.usuario} onChange={this.handleChange} label="Usuário"/>
							<TZMTextField col={12} name="senha" value={this.state.senha} onChange={this.handleChange} label="Senha" type="password"/>
							{this.state.capsLock ? <div className="ui-g-12" style={{color: "#FF0000"}}>A tecla Caps Lock está ligada</div> : null}
						</div>
						<TZMPanelFooter>
							<TZMButton onClick={this.doLogin} icon="fas fa-sign-in-alt" label="Entrar" success/>
						</TZMPanelFooter>
					</TZMForm>
				</TZMPanel>
				<span className="ui-tzm-dashboard-title">{empresaNome}</span>
				<ModalRealm/>
				<TZMShortcut active onEnter={this.doLogin} />
				<API/>
			</div>
		);
	}

}

export const numberFormat = Intl.NumberFormat("pt-BR", {maximumFractionDigits: 2});
var integerFormat = Intl.NumberFormat("pt-BR", {maximumFractionDigits: 0});
var currencyFormat = Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"});

// eslint-disable-next-line
Number.prototype.toCurrency = function() {
	return currencyFormat.format(this);
};

// eslint-disable-next-line
Number.prototype.toDecimal = function() {
	return numberFormat.format(this);
};

// eslint-disable-next-line
Number.prototype.toInteger = function() {
	return integerFormat.format(this);
};

// eslint-disable-next-line
String.prototype.toDateTime = function () {
	var num = this.replace(new RegExp('[^0-9]', 'g'), '');
	var res = num.toString();
	switch (num.length) {
		case 0:
		case 1:
		case 2:
			return res;
		case 3:
			return res.substring(0, 2) + "/" + res.substring(2, 3);
		case 4:
			return res.substring(0, 2) + "/" + res.substring(2, 4);
		case 5:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 5);
		case 6:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 6);
		case 7:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 7);
		case 8:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8);
		case 9:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 9);
		case 10:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10);
		case 11:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 11);
		case 12:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 12);
		case 13:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 12) + ":" + res.substring(12, 13);
		default:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8) + " " + res.substring(8, 10) + ":" + res.substring(10, 12) + ":" + res.substring(12, 14);
	}
}

// eslint-disable-next-line
String.prototype.toDate = function () {
	var num = this.replace(new RegExp('[^0-9]', 'g'), '');
	var res = num.toString();
	switch (num.length) {
		case 0:
		case 1:
		case 2:
			return res;
		case 3:
			return res.substring(0, 2) + "/" + res.substring(2, 3);
		case 4:
			return res.substring(0, 2) + "/" + res.substring(2, 4);
		case 5:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 5);
		case 6:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 6);
		case 7:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 7);
		default:
			return res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4, 8);
	}
}

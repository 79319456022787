import React, {Component} from "react";
import classNames from "classnames";

export class TZMSimpleCheckbox extends Component {

	onChange = (event) => {
		if (!this.props.disabled && this.props.onChange) {
			this.props.onChange({name: this.props.name, value: !this.props.checked, index: this.props.index});
		}
	}

	render() {
		let check = classNames({
			"fa": true,
			"ui-checkbox-check": true,
			"fa-square": !this.props.checked,
			"fa-check-square": this.props.checked,
			"disabled": this.props.disabled
		});
		return (
			<div {...this.props} onClick={this.onChange}>
				<span className={check}/>
				{this.props.label ? <label className={`ui-simplecheckbox-label ${this.props.disabled ? "disabled" : ""}`}>{this.props.label}</label> : null}
			</div>
		);
	}

}

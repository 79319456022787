import React, {Component} from "react";
import {MotoristaService} from "../../servicos/MotoristaService";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {EditarMotorista} from "./EditarMotorista";

const title = "Motoristas";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Motorista extends Component {

	state = {
		motoristas: [],
		nome: "",
		cpf: "",
		rg: "",
		cnh: "",
		rntnc: "",
		endereco: ""
	};

	motoristaService = new MotoristaService();

	handleChange = event => {
		this.setState({[event.name]: event.value});
	}

	listarMotoristas = () => {
		let query = [];
		if (this.state.nome?.length) {
			query.push(`nome=ik='${this.state.nome}'`);
		}
		if (this.state.cpf?.length) {
			query.push(`cpf=ik='${this.state.cpf}'`);
		}
		if (this.state.rg?.length) {
			query.push(`rg=ik='${this.state.rg}'`);
		}
		if (this.state.cnh?.length) {
			query.push(`cnh=ik='${this.state.cnh}'`);
		}
		query.push("status==ATIVO");
		this.motoristaService.listar(`search=${query.join(";")}&page=0&size=100`).then(motoristas => this.setState({motoristas}));
	}

	criarMotorista = () => {
		this.editarMotorista({
			nome: "",
			rg: "",
			cpf: "",
			cnh: "",
			rntnc: "",
			logradouro: "",
			complemento: "",
			bairro: "",
			cep: "",
			cidade: "",
			uf: ""
		});
	}

	editarMotorista = motorista => {
		ModalRealm.showDialog(<EditarMotorista motorista={motorista} onModalClose={this.listarMotoristas}/>);
	}

	excluirMotorista = motorista => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja excluir este motorista?" onYes={() => {
			this.motoristaService.excluir(motorista.id).then(this.listarMotoristas);
		}}/>);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<TZMTextField label="Nome" col={6} name="nome" value={this.state.nome} onChange={this.handleChange}/>
									<TZMTextField label="CPF" col={2} name="cpf" value={this.state.cpf} onChange={this.handleChange}/>
									<TZMTextField label="RG" col={2} name="rg" value={this.state.rg} onChange={this.handleChange}/>
									<TZMTextField label="CNH" col={2} name="cnh" value={this.state.cnh} onChange={this.handleChange}/>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								{TZMUserData.hasRole("FRETE_MOTO_C") ? <TZMButton success icon="fas fa-plus" label="Novo Motorista" onClick={this.criarMotorista}/> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarMotoristas}/>
							</TZMPanelFooter>
						</TZMPanel>
					</div>
				</div>
				<div style={{height: "8px"}} />
				<TZMPanel header="Resultado da Pesquisa">
					<TZMTable value={this.state.motoristas} paginator rows={20}>
						<TZMColumn header="Nome" field="nome"/>
						<TZMColumn style={{width: "12em"}} header="CPF" field="cpfFormatado"/>
						<TZMColumn style={{width: "12em"}} header="RG" field="rg"/>
						<TZMColumn style={{width: "12em"}} header="CNH" field="cnh"/>
						<TZMColumn header="Endereço" field="endereco"/>
						<TZMColumn header="Ações" style={{width: "6em", textAlign: "center"}} body={v => (
							<div>
								{TZMUserData.hasRole("FRETE_MOTO_E") ? <span title="Editar Motorista" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarMotorista(v)} /> : null}
								{TZMUserData.hasRole("FRETE_MOTO_D") ? <span title="Excluir Motorista" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirMotorista(v)} /> : null}
							</div>
						)}/>
					</TZMTable>
				</TZMPanel>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}

import React from "react";
import "./PlacaMercosul.css";

export class PlacaMercosul extends React.Component {

	render() {
		return (
			<div className="ui-g ui-g-nopad" style={{borderRadius: "4px", border: "1px solid rgba(0, 0, 0, .2"}}>
				<div className="ui-g-2">
					<Placa placa={this.props.placa} />
				</div>			
			</div>
		);
	}
}

export class Placa extends React.Component {

	resolveColor(categoria) {
		switch (categoria) {
		case "PARTICULAR": return "#000000";
		case "COMERCIAL": return "#c8102e";
		case "OFICIAL": return "#0033a0";
		case "DIPLOMATICO": return "#f2a900";
		case "ESPECIAL": return "#007a53";
		case "COLECAO": return "#968f8b";
		default: return "#000000";
		}
	}

	resolvePais(placa) {
		if (/[A-Z]{3}[0-9][A-Z][0-9]{2}/g.test(placa)) return "BRASIL";
		if (/[A-Z]{2}[0-9]{3}[A-Z]{2}/g.test(placa)) return "ARGENTINA";
		if (/[A-Z]{3}[0-9]{4}/g.test(placa)) return "URUGUAY"; 
		if (/[0-9]{3}[A-Z]{4}/g.test(placa)) return "PARAGUAY";
		return "MERCOSUL";
	}

	render() {
		return (
			<div className="placa-mercosul" {...this.props} style={{borderColor: this.resolveColor("COMERCIAL")}}>
				<div className="placa-label-pais">{this.resolvePais(this.props.placa)}</div>
				<div className="placa-screw" />
				<div className="placa-screw right" />
				<div className="placa-mercosul-content" style={{color: this.resolveColor("COMERCIAL")}}>{this.props.placa && this.props.placa.length ? this.props.placa.toUpperCase() : "ABC0000"}</div>
			</div>
		);
	}

}

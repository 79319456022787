import React, {Component} from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import TZMTextField from "../../../components/common/TZMTextField";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import TZMCalendar from "../../../components/common/TZMCalendar";
import {httpPost} from "../../../utilities/TZMRequest";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {toCurrency} from "../../../components/common/TZMFormatter";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {saveAs} from "file-saver";
import {byTransportadora} from "../../../utilities/SortUtils";
import moment from "moment";
import {TipoVeiculoService} from "../servicos/TipoVeiculoService";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {Fetch} from "../../../utilities/Fetch";

export class SimularFrete extends Component {

	state = {
		visible: true,
		dataDespacho: null,
		valor: 0,
		peso: 0,
		transportadora: null,
		cliente: null,
		origem: null,
		destino: null,
		tipoVeiculo: null,
		tabIndex: 0,
		icms: 0,
		calculosCte: [],
		cte: null,
		tiposVeiculo: []
	};

	tipoVeiculoService = new TipoVeiculoService();

	componentDidMount() {
		this.tipoVeiculoService.listar().then(tiposVeiculo => {
			this.setState({
				tipoVeiculo: tiposVeiculo[0],
				tiposVeiculo: [
					<option key={0} value={0}>Qualquer</option>,
					...tiposVeiculo.map(tv => <option key={tv.id} value={JSON.stringify(tv)}>{tv.descricao}</option>)
				]
			}, () => {
				if (this.props.cte) {
					this.setState({
						dataDespacho: moment().format("YYYY-MM-DD"),
						transportadora: {razaoSocial: "", id: this.props.cte.transportadora.transportadoraId},
						cliente: {razaoSocial: "", cnpj: this.props.cte.destinatario?.cnpj},
						origem: {nome: "", codigo: this.props.cte.identificacaoCte?.codigoMunicipioOrigem},
						destino: {nome: "", codigo: this.props.cte.identificacaoCte?.codigoMunicipioDestino},
						valor: this.props.cte.notas?.map(nf => nf.valor).reduce((a, b) => a + b, 0),
						peso: this.props.cte.notas?.map(nf => nf.pesoBruto).reduce((a, b) => a + b, 0),
						icms: this.props.cte.icms.aliquota
					}, this.simularFrete);
				}
			});
		});
	}

	onClose = () => this.setState({visible: false});

	handleChange = (event) => this.setState({[event.name]: event.value});

	simularFrete = () => {
		const messages = [];
		if (!this.state.dataDespacho) messages.push("A data de despacho é obrigatória");
		if (!this.state.cliente) messages.push("O cliente é obrigatório");
		if (!this.state.origem) messages.push("A origem é obrigatória");
		if (!this.state.destino) messages.push("O destino é obrigatório");
		if (!this.state.valor) messages.push("O valor da carga é obrigatório");
		if (!this.state.peso) messages.push("O peso é obrigatório");
		if (!this.state.tipoVeiculo) messages.push("O tipo de veículo é obrigatório");
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>)
		} else {
			httpPost(`/tabelas-frete/calcular`, {
				dataDespacho: this.state.dataDespacho,
				transportadora: this.state.transportadora?.id,
				cliente: this.state.cliente?.cnpj,
				valor: this.state.valor,
				peso: this.state.peso,
				codigoOrigem: this.state.origem?.codigo,
				codigoDestino: this.state.destino?.codigo,
				tipoVeiculo: this.state.tipoVeiculo?.id,
				icms: this.state.icms / 100
			}).then(calculosCte => this.setState({calculosCte: calculosCte.sort(byTransportadora), tabIndex: 1}));
		}
	}

	handleTabChange = (event) => this.setState({tabIndex: event.index});

	printMensagensSistema = (calculoCte) => {
		if (calculoCte.mensagens.length) {
			return (
				<div>
					<div style={{padding: ".75em 0"}}>Mensagens do sistema</div>
					<ol style={{margin: 0, padding: "0 2em"}}>{calculoCte.mensagens.map(m => <li key={Math.random()}>{m}</li>)}</ol>
				</div>
			);
		}
		return null;
	}

	printCalculoCte = (calculoCte) => {
		return (
			<TZMTabPanel key={Math.random()} header={calculoCte.transportadora?.razaoSocial}>
				<PanelContent>
					<TZMTable style={{opacity: calculoCte.status === "FALHA" ? .3 : 1}}
							  value={calculoCte.elementosCusto}
							  header={(
								  <div style={{textAlign: "center"}}>Status do cálculo: {calculoCte.status}</div>
							  )} footer={(
						<div style={{textAlign: "right", fontWeight: "bold"}}>
							Valor Total: {toCurrency(calculoCte.valorTotal)}
						</div>
					)}>
						<TZMColumn sortable header="Descrição" field="descricao"/>
						<TZMColumn sortable header="Valor" field="valor" style={{width: "10em"}} body={ec => (
							<div style={{textAlign: "right"}}>{toCurrency(ec.valor)}</div>
						)}/>
					</TZMTable>
					{this.printMensagensSistema(calculoCte)}
				</PanelContent>
			</TZMTabPanel>
		);
	}

	handleCBBChange = (event) => {
		this.setState({[event.name]: event.value ? JSON.parse(event.value) : null});
	}

	imprimirSimulacao = () => {
		Fetch.Post("/tabelas-frete/imprimir", this.state.calculosCte).then(response => {
			if (response.status === 200) {
				response.blob().then((blob) => {
					const file = new Blob([blob], {type: "application/pdf"});
					const data = window.URL.createObjectURL(file);
					saveAs(data, "CalculoFrete.pdf");
				});
			}
		});
	}

	render() {
		return (
			<Dialog style={{width: "700px"}} visible={this.state.visible} modal header="Cálculo de Frete" onHide={this.onClose} {...this.props}>
				<TZMTabView onTabChange={this.handleTabChange} activeIndex={this.state.tabIndex}>
					<TZMTabPanel header="Detalhes do Cálculo">
						<PanelContent>
							<TZMCalendar col={4} name="dataDespacho" value={this.state.dataDespacho} onChange={this.handleChange} label="Data de Despacho"/>
							<NeoSuggestion.Cliente col={8} name="cliente" value={this.state.cliente} onChange={this.handleChange}/>
							<NeoSuggestion.Transportadora col={12} name="transportadora" value={this.state.transportadora} onChange={this.handleChange}/>
							<NeoSuggestion.Municipio label="Origem" value={this.state.origem} name="origem" onChange={this.handleChange} col={6}/>
							<TZMCombobox children={this.state.tiposVeiculo}
										 label="Tipo de Veículo"
										 value={JSON.stringify(this.state.tipoVeiculo)}
										 name="tipoVeiculo"
										 onChange={this.handleCBBChange}
										 col={4}/>
							<TZMTextField col={2} type="number" name="icms" value={this.state.icms} onChange={this.handleChange} label="ICMS (%)"/>
							<NeoSuggestion.Municipio label="Destino" value={this.state.destino} name="destino" onChange={this.handleChange} col={6}/>
							<TZMTextField col={3} type="number" name="peso" value={this.state.peso} onChange={this.handleChange} label="Peso (Kg)"/>
							<TZMCurrency col={3} label="Valor da Carga" name="valor" value={this.state.valor} onChange={this.handleChange}/>
						</PanelContent>
					</TZMTabPanel>
					<TZMTabPanel header="Cotação" disabled={!this.state.calculosCte.length}>
						<PanelContent>
							<TZMTable value={this.state.calculosCte}>
								<TZMColumn header="Transportadora" field="transportadora.razaoSocial"/>
								<TZMColumn style={{width: "10em"}} header="Valor Total" body={cf => (
									<div style={{textAlign: "right"}}>{toCurrency(cf.valorTotal)}</div>
								)}/>
							</TZMTable>
							<div className="ui-g-12" style={{textAlign: "right"}}>
								<TZMButton label="Imprimir Cálculo" onClick={this.imprimirSimulacao} icon="fa fa-print"/>
							</div>
						</PanelContent>
					</TZMTabPanel>
					<TZMTabPanel header="Detalhes dos Cálculos" disabled={!this.state.calculosCte.length}>
						<PanelContent>
							<TZMTabView children={this.state.calculosCte.map(this.printCalculoCte)}/>
						</PanelContent>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					<TZMButton disabled={this.state.tabIndex} success label="Calcular Frete" icon="fas fa-save" onClick={this.simularFrete}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

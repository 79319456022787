import {EntidadeService} from "../../servicos/EntidadeService";
import {httpDelete, httpGet, httpPost} from "../../../utilities/TZMRequest";

export class MinutaService extends EntidadeService {

	buscar(id) {
		return httpGet(`/minutas/${id}`);
	}

	salvar(minuta) {
		return httpPost("/minutas", minuta);
	}

	excluir(id) {
		return httpDelete("/minutas", id);
	}

	listar(query) {
		return httpGet(`/minutas?${query}`);
	}

	confirmar(minutas) {
		return httpPost("/minutas/confirmar", minutas);
	}

}

import React, {Component} from "react";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMTextField from "../../../components/common/TZMTextField";

const formasReajuste = [
	{label: "Percentual", value: "PERCENTUAL"},
	{label: "Valor Fixo", value: "VALOR_FIXO"}
];

export class ReajustarElementoCusto extends Component {

	state = {
		visible: true,
		_key: Math.random(),
		elementoCusto: this.props.elementosCusto[0],
		forma: "PERCENTUAL",
		valor: 0
	};

	componentDidMount() {
		let elementosCusto = this.props.elementosCusto?.map(ec => ({label: ec, value: ec}));
		this.setState({elementosCusto});
	}

	handleChange = event => {
		this.setState({[event.name]: event.value});
	}

	confirmarReajuste = () => {
		if (this.props.onModalClose) {
			const {elementoCusto, forma, valor} = this.state;
			this.props.onModalClose({elementoCusto, forma, valor});
		}
		this.onClose();
	}

	onClose = () => this.setState({visible: false});

	render() {
		return (
			<Dialog key={this.state._key} style={{width: "400px"}} visible={this.state.visible} modal header="Reajuste de Valor de Elemento de Custo" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<TZMCombobox col={12} options={this.state.elementosCusto} label="Elemento de Custo" name="elementoCusto" value={this.state.elementoCusto} onChange={this.handleChange}/>
					<TZMCombobox col={6} options={formasReajuste} label="Forma de Reajuste" name="forma" value={this.state.forma} onChange={this.handleChange}/>
					<TZMTextField type="number" col={6} label="Valor" value={this.state.valor} onChange={this.handleChange} name="valor"/>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton success label="Confirmar" icon="fas fa-save" onClick={this.confirmarReajuste}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.confirmarReajuste} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

import React, {Component} from "react";
import {VeiculoService} from "../../servicos/VeiculoService";
import TZMForm from "../../../components/common/TZMForm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {TipoVeiculoService} from "../servicos/TipoVeiculoService";

export class EditarVeiculo extends Component {

	state = {
		visible: true,
		veiculo: this.props.veiculo,
		tiposVeiculo: []
	};

	onClose = () => this.setState({visible: false});

	veiculoService = new VeiculoService();

	tipoVeiculoService = new TipoVeiculoService();

	componentDidMount() {
		this.tipoVeiculoService.listar(``).then(tiposVeiculo => {
			this.setState({tiposVeiculo: tiposVeiculo.map(tv => ({label: tv.descricao, value: JSON.stringify(tv)}))});
		});
	}

	handleChange = event => {
		const {veiculo} = this.state;
		veiculo[event.name] = event.value;
		this.setState({veiculo});
	}

	salvarVeiculo = () => {
		this.veiculoService.salvar(this.state.veiculo).then(veiculo => {
			if (this.props.onModalClose) {
				this.props.onModalClose(veiculo);
			}
			this.onClose();
		});
	}

	render() {
		return (
			<TZMDialog style={{width: "600px"}} visible={this.state.visible} modal header="Veículo" onHide={this.onClose} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<div className="ui-g">
							<TZMTextField type="plate" maxLength={7} label="Placa" col={4} name="placa" onChange={this.handleChange} value={this.state.veiculo.placa}/>
							<TZMTextField label="Modelo" col={8} name="modelo" onChange={this.handleChange} value={this.state.veiculo.modelo}/>
							<TZMTextField label="Fabricante" col={6} name="fabricante" onChange={this.handleChange} value={this.state.veiculo.fabricante}/>
							<TZMCombobox col={6} label="Tipo de Veículo" options={this.state.tiposVeiculo} name="tipoVeiculo" value={JSON.stringify(this.state.veiculo.tipoVeiculo)} onChange={this.handleChange}/>
							<TZMTextField type="number" label="Capacidade (m³)" col={6} name="capacidade" onChange={this.handleChange} value={this.state.veiculo.capacidade}/>
							<TZMTextField type="number" label="Carga Máxima (Kg)" col={6} name="carga" onChange={this.handleChange} value={this.state.veiculo.carga}/>
						</div>
					</TZMForm>
				</TZMPanel>
				<TZMPanelFooter>
					{TZMUserData.hasRole("FRETE_VEIC_E") ? <TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarVeiculo}/> : null}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

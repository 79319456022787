import React from "react";
import TZMButton from "../../../../components/common/TZMButton";
import TZMTextArea from "../../../../components/common/TZMTextArea";
import TZMDialog from "../../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../../components/panel/TZMPanelFooter";

export class Observacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            observacao: props.observacao
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    render() {
        return (
            <TZMDialog style={{ width: "600px" }} visible={this.state.visible} modal header="Observação" {...this.props}>
                <div className="ui-g">
                    <div className="ui-g-12">
                        <TZMTextArea name="observacao" field="texto" maxLength="4000" style={{ height: "150px" }} label="Observação" value={this.state.observacao} />
                    </div>    
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
            </TZMDialog>
        );
    }

}


import React, {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {toCurrency, toCurrencyRightAligned} from "../../../components/common/TZMFormatter";
import {AuditoriaCteStatusMap} from "./MapTabelaFrete";
import {Fetch} from "../../../utilities/Fetch";
import {saveAs} from "file-saver";
import TZMProgressbar from "../../../components/menubar/TZMProgressbar";

export class VisualizarAuditoriaCTe extends Component {

	state = {
		visible: true
	};

	onClose = () => this.setState({visible: false});

	colorStatus = () => {
		switch (this.props.auditoria.status) {
			case "SUCESSO":
				return {color: "#388E3C"};
			case "OBSERVACOES":
				return {color: "#F57C00"};
			default:
				return {color: "#C62828"};
		}
	}

	imprimirSimulacao = () => {
		TZMProgressbar.setActive(true);
		Fetch.Get(`/tabelas-frete/imprimir/cte/${this.props.cte.id}`).then(response => {
			if (response.status === 200) {
				response.blob().then((blob) => {
					const file = new Blob([blob], {type: "application/pdf"});
					const data = window.URL.createObjectURL(file);
					TZMProgressbar.setActive(false);
					saveAs(data, "CalculoFrete.pdf");
				});
			}
		});
	}

	render() {
		return (
			<Dialog style={{width: "700px"}} visible={this.state.visible} modal header="Auditoria de Frete" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<div className="ui-g-12">
						Status da auditoria: <b style={this.colorStatus()}>{AuditoriaCteStatusMap[this.props.auditoria.status]}</b>
					</div>
					<div className="ui-g-12">
						<TZMTable header="Elementos de Custo" value={this.props.auditoria?.elementosCusto} footer={(
							<div style={{textAlign: "right", fontWeight: "bold"}}>Valor Total: {toCurrency(this.props.auditoria?.elementosCusto?.map(ec => ec.valor).reduce((a, b) => a + b, 0))}</div>
						)}>
							<TZMColumn header="Descrição" field="descricao"/>
							<TZMColumn style={{width: "10em"}} header="Valor" body={ec => toCurrencyRightAligned(ec.valor)}/>
						</TZMTable>
					</div>
					{this.props.auditoria?.mensagens?.length ? (
						<div className="ui-g-12">
							<TZMTable value={this.props.auditoria?.mensagens}>
								<TZMColumn header="Mensagens da Auditoria" body={m => m}/>
							</TZMTable>
						</div>
					) : null}
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton label="Imprimir Cálculo" onClick={this.imprimirSimulacao} icon="fa fa-print"/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

import React from "react";
import "./Common.css";

export default class TZMRadioGroup extends React.Component {

	render() {
		return (
			<div className={`ui-g-${this.props.col}`} {...this.props}>
				<label className="ui-input-label">{this.props.label}</label><br />
				{this.props.children}
			</div>
		);
	}

}

import {httpGet, httpGetAsync, httpPost} from "../../../utilities/TZMRequest";
import { RelatorioService } from "../../servicos/RelatorioService";

export class RomaneioColacrilService {
	relatorioService = new RelatorioService();

	salvar(romaneio) {
		return httpPost("/romaneios-colacril", romaneio);
	}

	buscar(romaneio) {
		return httpGet(`/romaneios-colacril/${romaneio.id}`);
	}

    listarSimples = async (params) => {
		return httpGetAsync('/romaneios-colacril/listar-simples', params);
	}

	buscarOs = async (ordemServico) => {
		return httpGetAsync(`/romaneios-colacril/buscar-os/${ordemServico}`);
	}

	preencherDadosFiscaisOs = async (romaneio) => {
		return httpGetAsync(`/ordens-servico/preencher-dados-fiscais-os/${romaneio.id}`);
	}

	preencherDadosFiscaisNf = async (notaFiscalId) => {
		return  httpGetAsync(`/ordens-servico/preencher-dados-fiscais-nf/${notaFiscalId}`);
	}

	preencherDadosPesoVolume = async (ordemServico) => {
		return httpGetAsync(`/ordens-servico/preencher-dados-peso-volume/${ordemServico}`);			
	}

	imprimir = async (romaneio, nome) => {     		 
        return this.relatorioService.postReport(`/romaneios-colacril/imprimir/${romaneio.id}`, nome, '.pdf');
    }


}

import React, {Component} from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTextField from "../../../components/common/TZMTextField";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPopup from "../../../components/dialog/TZMPopup";

export class EditarNotaFiscal extends Component {

	state = {
		notaFiscal: this.props.notaFiscal,
		visible: true,
		_key: Math.random()
	}

	onClose = () => this.setState({visible: false});

	handleUpdate = event => {
		const {notaFiscal} = this.state;
		notaFiscal[event.name] = event.value;
		this.setState({notaFiscal});
	}

	onSalvar = () => {
		if (!this.state.notaFiscal.serie || !this.state.notaFiscal.numero) {
			ModalRealm.showDialog(<TZMPopup messages={["O número e série da NF são obrigatórios"]}/>);
			return;
		}
		if (this.props.onModalClose) {
			this.props.onModalClose(this.state.notaFiscal);
		}
		this.onClose();
	}

	render() {
		return (
			<TZMDialog key={this.state._key} style={{width: "450px"}} visible={this.state.visible} modal header="Nota Fiscal" onHide={this.onClose} {...this.props}>
				<div className="ui-g">
					<TZMTextField col={4} type="number" name="serie" autoFocus onChange={this.handleUpdate} label="Série" value={this.state.notaFiscal.serie}/>
					<TZMTextField col={8} type="number" name="numero" autoFocus onChange={this.handleUpdate} label="Número" value={this.state.notaFiscal.numero}/>
				</div>
				<TZMPanelFooter>
					<TZMButton label="Salvar" icon="fas fa-save" onClick={this.onSalvar}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

import React, {Component} from "react";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {EditarTabelaFreteIncidencia} from "./EditarTabelaFreteIncidencia";
import {newTabelaFretePercursoDestinoIncidencia} from "./MapTabelaFrete";
import {byIdOrKey} from "../../../utilities/FilterUtils";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {UfService} from "../servicos/UfService";
import {byNome} from "../../../utilities/SortUtils";

export class EditarTabelaFreteDestino extends Component {

	state = {
		visible: true,
		destino: this.props.destino,
		ufs: []
	};

	ufService = new UfService();

	componentDidMount() {
		this.ufService.listar().then(ufs => this.setState({ufs: [<option value={0}>Nenhum</option>, ...ufs.sort(byNome).map(uf => <option key={uf.id} value={JSON.stringify(uf)}>{uf.nome}</option>)]}));
	}

	onClose = () => this.setState({visible: false});

	salvarDestino = () => {
		const {destino} = this.state;
		const messages = [];
		if (!destino.municipio && !destino.uf) messages.push("O destino é obrigatório");
		if (destino.municipio?.id && destino.uf?.id) messages.push("O destino deve ser um município ou uma UF (nunca os dois ao mesmo tempo)");
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>);
		} else {
			if (this.props.onModalClose) {
				this.props.onModalClose(destino);
			}
			this.onClose();
		}
	}

	handleChange = (event) => this.setState({destino: {...this.state.destino, [event.name]: event.value}});

	inserirIncidencia = () => this.editarIncidencia(newTabelaFretePercursoDestinoIncidencia());

	editarIncidencia = (incidencia) => {
		incidencia.clientes.forEach(c => c.razaoSocial = c.vwCliente?.razaoSocial);
		ModalRealm.showDialog(<EditarTabelaFreteIncidencia tabelaFrete={this.props.tabelaFrete} descricoes={this.props.descricoes} key={Math.random()} incidencia={incidencia} onModalClose={incidencia => {
			const {destino} = this.state;
			const i = destino.incidencias.findIndex(i => (i.id && i.id === incidencia.id) || (i._key && i._key === incidencia._key));
			if (i >= 0) {
				destino.incidencias[i] = incidencia;
			} else {
				destino.incidencias.unshift(incidencia);
			}
			this.setState({destino});
		}}/>);
	}

	removerIncidencia = (incidencia) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja remover esta incidência?" onYes={() => {
			const {destino} = this.state;
			destino.incidencias = destino.incidencias.filter(i => byIdOrKey(i, incidencia));
			this.setState({destino});
		}}/>);
	}

	render() {
		return (
			<Dialog style={{width: "1200px"}} visible={this.state.visible} modal header="Tabela de Frete - Percurso - Destino" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<NeoSuggestion.Municipio appendTo={document.body} col={8} label="Município" name="municipio" value={this.state.destino.municipio} onChange={this.handleChange}/>
					<TZMCombobox appendTo={document.body} children={this.state.ufs} col={4} label="UF" name="uf" value={JSON.stringify(this.state.destino.uf)} onChange={this.handleChange}/>
					<div className="ui-g-12">
						<TZMTable header="Incidências" value={this.state.destino.incidencias} rows={10} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirIncidencia}/>
						}>
							<TZMColumn header="Clientes" body={i => i.clientes.map(c => <div>{c.vwCliente?.razaoSocial || c.razaoSocial}</div>)}/>
							<TZMColumn header="Elementos de Custo" body={i => i.elementosCusto.map(ec => <div>{ec.descricao}</div>)}/>
							<TZMColumn style={{width: "5em", textAlign: "center"}} header="Ações" body={i => (
								<div>
									<i className="ui-tzm-icon-uncoloured fa fa-edit" onClick={() => this.editarIncidencia(i)}/>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerIncidencia(i)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarDestino}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarDestino} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

import { httpGet, httpGetAsync } from "../../../utilities/TZMRequest";

export class TransportadoraService {

	completar(transportadora) {
		if (transportadora.id) {
			return httpGet(`/transportadoras/${transportadora.id}`);
		} else {
			return httpGet(`/transportadoras/${transportadora}`);
		}
	}

	completarPK(pk) {		
		return httpGet(`/transportadoras/completar-pk/${pk.fornecedorId}`);
	}

    listarSimples = async (params) => {
		return await httpGetAsync('/transportadoras/listar-simples', params);
	}

	autoComplete = async (params) => {
		return await httpGetAsync('/transportadoras/listar-simples', params, false);
	}

	atualizar = async () => {
        return await httpGetAsync("/transportadoras/atualizar");
    } 

    listar = (query) => {
		return httpGet(`/transportadoras?transportadora=${query}`);
	}

	findByCardCode = async cardCode => {
		if (cardCode?.length) {
			return await httpGet(`/transportadoras/findByCardCode/${cardCode}`);
		}
		return null;
	}

}

import {Button} from "primereact/components/button/Button";

import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {PerfilService} from "../../servicos/PerfilService";
import {UsuarioService} from "../../servicos/UsuarioService";

export default class EditarUsuario extends React.Component {

	state = {
		usuario: this.completarNulos(this.props.usuario),
		visible: true,
		alterado: false
	};

	usuarioService = new UsuarioService();	
	perfilService = new PerfilService();

	handleUpdate = (key, event) => {
		const usuario = this.state.usuario;
		usuario[key] = event.target.value;
		this.setState({alterado: true, usuario});
	}

	handleUpdatePA = (event) => {
		const usuario = this.state.usuario;
		if (!usuario[event.name]) {
			usuario[event.name] = [];
		}
		usuario[event.name].push(event.value);
		this.setState({alterado: true, usuario});
	}

	handleUpdatePR = (event) => {
		const usuario = this.state.usuario;
		if (!usuario[event.name]) {
			usuario[event.name] = [];
		}
		usuario[event.name] = usuario[event.name].filter((e) => e.id !== event.value.id);
		this.setState({alterado: true, usuario});
	}

	completarNulos(usuario) {
	
		return usuario;
	}

	validarUsuario = () => {
		let messages = [];
		if (this.state.usuario.login == null || this.state.usuario.login.length < 1) {
			messages.push("O login é obrigatório");
		}
		if (this.state.usuario.id == null && (this.state.usuario.senha == null || this.state.usuario.senha.length < 1)) {
			messages.push("A senha é obrigatória");
		} else if (this.state.usuario.senha !== this.state.usuario.csenha) {
			messages.push("Os campos de senha não conferem");	
		}
		if (this.state.usuario.perfis.length === 0 ) { 
			messages.push("Selecione ao menos um perfil.");
		}		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	salvarUsuario = () => {
		if (this.validarUsuario()) {
			this.usuarioService.salvar(this.state.usuario).then(() => this.fecharPainel("onSave"));
		}
	}

	fecharPainel = (modalResponse) => {
		this.setState({visible: false});
		if (this.props[modalResponse]) {
			this.props[modalResponse](this.state.pedido);
		}
	}

	fecharUsuario = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={() => this.fecharPainel("onClose")} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel("onClose");
		}
	}

	reativarUsuario = () => {
		this.usuarioService.salvar({...this.state.usuario, ativado: true}).then(() => this.fecharPainel("onDelete"));
	}

	desativarUsuario = () => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.usuarioService.excluir(this.state.usuario).then(() => this.fecharPainel("onDelete"));
		}} question="Tem certeza de que deseja desativar este usuário?" />);
	}

	selectTabIndex = (index) => {
		this.setState({selectedTab: index});
	}

	listarPerfis = (event) => {		
		this.perfilService.buscar(`search=descricao~${event.query};id!${this.state.usuario.perfis.map(p => p.id).join(",")}`, {size: 10, page: 0}).then((perfis) => this.setState({perfis}));
	}

	handlePerfis = (event) => {
		let perfil = this.state.perfil;
		perfil = event.value;
		this.setState({perfil});
	}

	onSelectPerfil = event => {
		const usuario = this.state.usuario;
		let perfil = this.state.perfil;
		if (event.value) {
			usuario.perfis.push(event.value);	
			perfil = null;	
		}
		this.setState({usuario,perfil});
	}

	perfilDetails = [
		<TZMColumn key={0} header="Descrição" field="descricao" />,
		<TZMColumn key={1} style={{width: "4em", textAlign: "center"}} header="Ações" body={(p) => {
			return (
				<span key={0} title="Remover Perfil" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.removerPerfil(p)} />
			);
		}} />
	];

	removerPerfil = (perfil) => {
		const usuario = this.state.usuario;
		usuario.perfis = usuario.perfis.filter(p => p.id !== perfil.id);
		this.setState({usuario});
	}

	render() {		
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Usuário" {...this.props}>
				<TZMTabView activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">
							<div className="ui-g-3"><TZMTextField autoFocus required disabled={this.state.usuario.id != null} onChange={(event) => this.handleUpdate("login", event)} label="Login" value={this.state.usuario.login} /></div>
							<div className="ui-g-9"><TZMTextField onChange={(event) => this.handleUpdate("nome", event)} label="Nome" value={this.state.usuario.nome} /></div>							
							<div className="ui-g-4"><TZMTextField onChange={(event) => this.handleUpdate("email", event)} label="E-mail" value={this.state.usuario.email} /></div>
							<div className="ui-g-3">
								<TZMTextField placeholder={this.state.usuario.id != null ? "**********" : null} warning={this.state.usuario.senha !== this.state.usuario.csenha} 
										required={this.state.usuario.id == null} onChange={(event) => this.handleUpdate("senha", event)} type="password" label="Senha" value={this.state.usuario.senha} /></div>
							<div className="ui-g-3">
								<TZMTextField placeholder={this.state.usuario.id != null ? "**********" : null} warning={this.state.usuario.senha !== this.state.usuario.csenha} 
										required={this.state.usuario.id == null} onChange={(event) => this.handleUpdate("csenha", event)} type="password" label="Confirmação de senha" value={this.state.usuario.csenha} /></div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Perfis</span>}>
						<div className="ui-g">
							
							<TZMAutoComplete 
								col={12} 
								field="descricao" 
								name="perfil" 
								label="Perfil" 
								onSelect={this.onSelectPerfil} 
								onChange={this.handlePerfis} 
								completeMethod={this.listarPerfis} 
								autoclear 
								suggestions={this.state.perfis} 
								value={this.state.perfil} 
							/> 
							<TZMTable 
								header="Perfis do Usuário" 
								emptyMessage="Nenhum perfil selecionado" 
								col={12} 
								children={this.perfilDetails} 
								value={this.state.usuario.perfis} 
							/>
						</div>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					{this.state.usuario.id != null && this.state.usuario.ativado && this.state.usuario.login !== "admin" ? <TZMButton style={{float: "left"}} danger title="Desativar Usuário" icon="fas fa-trash-alt" onClick={this.desativarUsuario} /> : null}
					{this.state.usuario.id != null && !this.state.usuario.ativado ? <TZMButton style={{float: "left"}} title="Reativar Usuário" icon="fas fa-eye" onClick={this.reativarUsuario} /> : null}
					<Button className="ui-button-success ui-tzm-button" label="Salvar" icon="fas fa-save" onClick={this.salvarUsuario} />
					<Button className="ui-button-secondary ui-tzm-button" label="Fechar" icon="fas fa-times" onClick={this.fecharUsuario} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarUsuario}
					onEscape={this.fecharUsuario}
					onAlt1={() => this.selectTabIndex(0)}
					onAlt2={() => this.selectTabIndex(1)}
				/>
			</TZMDialog>
		);
	}

}

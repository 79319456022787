import React, {Component} from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTextField from "../../../components/common/TZMTextField";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {TZMCurrency} from "../../../components/common/TZMCurrency";

export class EditarElementoCusto extends Component {

	state = {
		elementoCusto: this.props.elementoCusto,
		visible: true,
		_key: Math.random()
	}

	onClose = () => this.setState({visible: false});

	handleUpdate = event => {
		const {elementoCusto} = this.state;
		elementoCusto[event.name] = event.value;
		this.setState({elementoCusto});
	}

	onSalvar = () => {
		if (!this.state.elementoCusto.nome?.length || !this.state.elementoCusto.valor) {
			ModalRealm.showDialog(<TZMPopup messages={["O nome e valor do elemento de custo são obrigatórios"]}/>);
			return;
		}
		if (this.props.onModalClose) {
			this.props.onModalClose(this.state.elementoCusto);
		}
		this.onClose();
	}

	render() {
		return (
			<TZMDialog key={this.state._key} style={{width: "450px"}} visible={this.state.visible} modal header="Elemento de Custo" onHide={this.onClose} {...this.props}>
				<div className="ui-g">
					<TZMTextField col={8} name="nome" autoFocus onChange={this.handleUpdate} label="Nome" value={this.state.elementoCusto.nome}/>
					<TZMCurrency col={4} name="valor" onChange={this.handleUpdate} label="Valor" value={this.state.elementoCusto.valor}/>
				</div>
				<TZMPanelFooter>
					<TZMButton label="Salvar" icon="fas fa-save" onClick={this.onSalvar}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

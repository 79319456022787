import React, {Component} from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMTable from "../../../components/table/TZMTable";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import {newTipoVeiculo} from "../tabela-frete/MapTabelaFrete";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {EditarTipoVeiculo} from "./EditarTipoVeiculo";
import {TipoVeiculoService} from "../servicos/TipoVeiculoService";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMConfirm from "../../../components/dialog/TZMConfirm";

export class TipoVeiculo extends Component {

	state = {
		descricao: "",
		tiposVeiculo: []
	};

	tipoVeiculoService = new TipoVeiculoService();

	handleChange = (event) => this.setState({[event.name]: event.value});

	editarTipoVeiculo = (tipoVeiculo = newTipoVeiculo()) => {
		ModalRealm.showDialog(<EditarTipoVeiculo key={Math.random()} tipoVeiculo={tipoVeiculo} onModalClose={this.listarTiposVeiculo}/>);
	}

	removerTipoVeiculo = (tipoVeiculo) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja excluir este tipo de veículo?" onYes={() => {
			this.tipoVeiculoService.excluir(tipoVeiculo.id).then(this.listarTiposVeiculo);
		}}/>);
	}

	listarTiposVeiculo = () => {
		this.tipoVeiculoService.listar(`search=descricao=ik='${this.state.descricao}'`).then(tiposVeiculo => this.setState({tiposVeiculo}));
	}

	render() {
		return (
			<TZMMainContent>
				<PanelContent>
					<div className="ui-g-12">
						<TZMPanel header="Tipos de Veículo">
							<TZMForm>
								<PanelContent>
									<TZMTextField col={12} label="Descrição" name="descricao" value={this.state.descricao} onChange={this.handleChange}/>
								</PanelContent>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton success icon="fas fa-plus" label="Novo Tipo de Veículo" onClick={() => this.editarTipoVeiculo()}/>
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarTiposVeiculo}/>
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}}/>
						<TZMPanel>
							<TZMTable paginator rows={50} value={this.state.tiposVeiculo}>
								<TZMColumn header="Descrição" field="descricao"/>
								<TZMColumn header="Ações" style={{width: "5em", textAlign: "center"}} body={tv => (
									<div>
										<span title="Editar Tipo de Veículo" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarTipoVeiculo(tv)}/>
										<span title="Remover Tipo de Veículo" className="fa fa-trash ui-tzm-icon" onClick={() => this.removerTipoVeiculo(tv)}/>
									</div>
								)}/>
							</TZMTable>
						</TZMPanel>
					</div>
				</PanelContent>
			</TZMMainContent>
		);
	}

}

import {httpGet, httpGetAsync, httpPost} from "../../../utilities/TZMRequest";
import {RelatorioService} from "../../servicos/RelatorioService";

export class RomaneioService {

	relatorioService = new RelatorioService();

	salvar(romaneio) {
		return httpPost("/romaneios", romaneio);
	}

	buscar(romaneio) {
		return httpGet(`/romaneios/${romaneio.id}`);
	}

    listarSimples = async (params) => {
		return httpGetAsync('/romaneios/listar-simples', params);
	}

	clientePedido = async (pedido) => {
		return await httpGetAsync(`/romaneios/cliente-pedido/${pedido}`);
	}

	notaFiscal = async (notaFiscal) => {
		return await httpGetAsync(`/romaneios/nota-fiscal/${notaFiscal}`);
	}

	notaFiscalItens = async (notaFiscal) => {
		return await httpGetAsync(`/romaneios/nota-fiscal-itens/${notaFiscal}`);
	}

	imprimir = async (romaneio, nome) => {     
		console.log('danone')   
        return this.relatorioService.postReport(`/romaneios/imprimir/${romaneio.id}`, nome, '.pdf');
    }

	imprimirContrato = async (romaneio, nome) => {
		return this.relatorioService.postReport(`/romaneios/imprimir/contrato/${romaneio.id}`, nome, '.docx');
	}

	async imprimirDespesas(params) {
		return await this.relatorioService.postReport(`/romaneios/despesas?inicio=${params.inicio}&fim=${params.fim}`, "DespesasFrete", ".pdf");
	}

    containsNf = async (notaFiscal) => {
		return await httpGetAsync(`/romaneios/contains-nf/${notaFiscal}`);
	}

}

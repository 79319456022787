import React from "react";
import TZMButton from "../../../../components/common/TZMButton";
import TZMDialog from "../../../../components/dialog/TZMDialog";
import TZMPanel from "../../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../../components/panel/TZMPanelFooter";
import TZMTabView from "../../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../../components/panel/TZMTabPanel";
import TZMForm from "../../../../components/common/TZMForm";
import TZMUserData from "../../../../utilities/TZMUserData";
import {RomaneioService} from "../../servicos/RomaneioService";
import TZMTable from "../../../../components/table/TZMTable";
import {Column} from "primereact/components/column/Column";
import TZMAutoComplete from "../../../../components/common/TZMAutoComplete";
import {toCurrency, toDecimal} from "../../../../components/common/TZMFormatter";
import TZMCalendar from "../../../../components/common/TZMCalendar";
import {VwPedidoService} from "../../../servicos/VwPedidoService";
import {ClienteService} from "../../../servicos/ClienteService";
import {ModalRealm} from "../../../../components/modal/ModalRealm";
import {TransportadoraService} from "../../servicos/TransportadoraService";
import {TipoVeiculoService} from "../../servicos/TipoVeiculoService";
import TZMNumberField from "../../../../components/common/TZMNumberField";
import TZMCombobox from "../../../../components/common/TZMCombobox";
import "./Romaneio";
import TZMConfirm from "../../../../components/dialog/TZMConfirm";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import {Button} from "primereact/components/button/Button";
import {Validator} from "../../../../utilities/TZMValidator";
import TZMPopup from "../../../../components/dialog/TZMPopup";
import TZMTextArea from "../../../../components/common/TZMTextArea";
import {MotoristaService} from "../../../servicos/MotoristaService";
import {VeiculoService} from "../../../servicos/VeiculoService";
import {TZMCurrency} from "../../../../components/common/TZMCurrency";
import {sum} from "../../../../utilities/NumberUtils";
import TZMTextField from "../../../../components/common/TZMTextField";
import {EditarMotorista} from "../../motorista/EditarMotorista";
import {EditarVeiculo} from "../../veiculos/EditarVeiculo";
import {normalizeQuery} from "../../../../utilities/StringUtils";

function completarNulos(romaneio) {
	if (!romaneio.itens) {
		romaneio.itens = [];
	}
	return romaneio;
}

export class EditarRomaneio extends React.Component {

	state = {
		visible: true,
		romaneio: completarNulos(this.props.romaneio),
		romaneioItem: {
			params: ""
		},
		notasFiscaisItens: []
	};

	romaneioService = new RomaneioService();
	vwPedidoService = new VwPedidoService();
	clienteService = new ClienteService();
	transportadoraService = new TransportadoraService();
	tipoVeiculoService = new TipoVeiculoService();
	motoristaService = new MotoristaService();
	veiculoService = new VeiculoService();

	componentDidMount() {
		this.tipoVeiculoService.listar().then(tiposVeiculo => {
			this.setState({
				tipoVeiculo: tiposVeiculo[0],
				tiposVeiculo: [
					<option key={0} value={null}>Qualquer</option>,
					...tiposVeiculo.map(tv => <option key={tv.id} value={JSON.stringify(tv)}>{tv.descricao}</option>)
				]
			});
		});
	}

	onClose = () => this.setState({visible: false});

	handleUpdateItem = (event) => {
		let romaneio = this.state.romaneio;
		romaneio.itens[event.index][event.name] = event.value;
		if (event.name === "peso") {
			this.recalcularRateioFrete(romaneio);
		}
		this.setState({romaneio});
    }

	handleChange = (event, callback) => {
		const {romaneio} = this.state;
		romaneio[event.name] = event.value;
		this.setState({romaneio}, callback);
	}
	
	handleClear = (event) => {
		let romaneio = this.state.romaneio;
		romaneio[event.name] = null;
		this.setState({romaneio});
	}

	validarFinalizar = () => {
		if (this.validar()) {
			let messages = [];
			let romaneio = this.state.romaneio;
			romaneio.itens.forEach((item, index) => {
				if (!romaneio.frete) {
					messages.push("Frete não informado.");
				}
				if (!item.notaFiscalId) {
					messages.push("Nota fiscal não informada.");
				}						
			});
			if (messages.length > 0) {
				ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} />);
				return false;
			}
			return true;
		}
	}

	validar = () => {
		let messages = [];
		let {romaneio} = this.state;
		if (!Validator.isEntidade(romaneio.motorista)) messages.push("O motorista é obrigatório");
		if (romaneio.veiculo?.length && !Validator.isEntidade(romaneio.veiculo)) messages.push("Você deve selecionar o veículo preenchido para vinculá-lo corretamente");
		if (!romaneio.itens?.length) {
			messages.push("O romaneio deve ter ao menos um item.");
		} else {
			for (const item of romaneio.itens) {
				if (!item.notaFiscalId) messages.push("O número da nota fiscal é obrigatório em todos os itens");
				//if (!item.cliente?.id) messages.push("O cliente é obrigatório em todos os itens");
				if (!item.peso) messages.push("O peso do item é obrigatório em todos os itens");
				if (!item.valorNotaFiscal) messages.push("O valor da nota fiscal é obrigatório em todos os itens");
			}
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages}/>);
			return false;
		}
		return true;
	}

	editorNotaFiscal = (rowData, props) => {		
		return (			
			<div style={{ textAlign: "right"}}>
                <TZMNumberField onChange={this.handleUpdateItem} name="notaFiscalId" index={props.rowIndex}
						value={this.state.romaneio.itens[props.rowIndex].notaFiscalId} 
						onBlur={() => {
							const {romaneio} = this.state;
							if (Number(rowData.notaFiscalId) > 0) {
								this.romaneioService.containsNf(rowData.notaFiscalId).then(numero => {
									if (!numero || romaneio.numero === numero) {
										this.romaneioService.notaFiscal(rowData.notaFiscalId).then(notaFiscal => {
											this.romaneioService.notaFiscalItens(rowData.notaFiscalId).then(notaFiscalItens => {
												if (notaFiscal && notaFiscal.notaFiscalId) {
													romaneio.itens[props.rowIndex] = {
														...romaneio.itens[props.rowIndex],
														...notaFiscal
													};
													if (!romaneio.itens[props.rowIndex].pedidoId) {
														romaneio.itens[props.rowIndex]._semPedido = true;
													}
													romaneio.itens[props.rowIndex].peso = notaFiscal.pesoBruto;
													romaneio.itens[props.rowIndex].detalhes = notaFiscalItens;
													romaneio.itens[props.rowIndex].detalhes.forEach(rid => {
														rid.linha = rid.itemId;
														delete rid.itemId;
													});
													this.recalcularRateioFrete(romaneio);
													this.clienteService.findByCardCode(notaFiscal.clienteCpfCnpj).then(cliente => {
														this.transportadoraService.findByCardCode(notaFiscal.transportadoraCnpj).then(transportadora => {
															
															if (cliente && cliente.id) {																
																romaneio.itens[props.rowIndex].cliente = cliente;
															}	
															if (transportadora && transportadora.id) {																
																romaneio.itens[props.rowIndex].transportadora = transportadora;
															}															
															this.setState({romaneio});
														});
													});
												}
											});
										});
									} else {
										ModalRealm.showDialog(<TZMPopup messages={[`Esta nota fiscal já está presente no romaneio ${numero}.`]}/>);
									}
								});
							}
						}}/>
			</div>
		);
	}

	recalcularRateioFrete = romaneio => {
		if (romaneio.itens?.length) {
			let precoPorKg = romaneio.valorFrete / romaneio.itens.map(ri => ri.peso).reduce(sum, 0);
			for (const item of romaneio.itens) {
				let precoPorValor = (precoPorKg * item.peso) / item.valorNotaFiscal;
				for (const detalhe of item.detalhes) {
					detalhe.valorFreteRateio = Math.round(precoPorValor * detalhe.valorTotalItem * 100) / 100;
				}
			}
			const diff = romaneio.valorFrete - romaneio.itens.map(ri => ri.detalhes?.map(rid => rid.valorFreteRateio).reduce(sum, 0)).reduce(sum, 0);
			if (diff < .03) {
				romaneio.itens[0].detalhes[0].valorFreteRateio += diff;
			}
		}
	}

	salvar = () => {
		if (this.validar()) {
			this.romaneioService.salvar(this.state.romaneio).then((romaneio) => {
				if (this.props.onModalClose) this.props.onModalClose(romaneio);
				this.onClose();
			});
		}
	}

	faturamento = () => {
		if (this.validar()) {
			let {romaneio} = this.state;
			romaneio.status = "FATURAMENTO";
			ModalRealm.showDialog(<TZMConfirm key={Math.random()} onYes={() => {
				this.romaneioService.salvar(romaneio).then((result) => {
					if (this.props.onModalClose) this.props.onModalClose(result);
					this.onClose();
				});
			}} question="Tem certeza de que deseja encaminhar o romaneio para o faturamento?"/>);
		}
	}

	finalizar = () => {
		if (this.validarFinalizar()) {
			let romaneio = this.state.romaneio;
			romaneio.status = "FINALIZADO";
			ModalRealm.showDialog(<TZMConfirm key={Math.random()} onYes={() => {
				this.romaneioService.salvar(romaneio).then((result) => {
					if (this.props.onModalClose) this.props.onModalClose(result);
					this.onClose();
				});
			}} question="Tem certeza de que deseja finalizar o romaneio?" />);
		}
	}

	inserirItem = () => {
		let romaneio = this.state.romaneio;
		if (!romaneio.itens) {
			romaneio.itens = [];
		}
		romaneio.itens.unshift({
			_key: Math.random(),
			volumes: 0,
			cliente: null,
			peso: 0,
			quantidade: 0,
			valorNotaFiscal: 0,
			detalhes: []
		});
		romaneio.itens.forEach((a, i) => a.item = romaneio.itens.length - i);
		this.setState({romaneio, alterado: true});
	}

	excluirItem = item => {
		if (item.id) {
			ModalRealm.showDialog(<TZMConfirm key={Math.random()} onYes={() => {
				let romaneio = this.state.romaneio;
				romaneio.itens = romaneio.itens.filter(f => f !== item);
				romaneio.itens.forEach((a, i) => a.item = romaneio.itens.length - i);
				this.recalcularRateioFrete(romaneio);
				this.setState({romaneio});
			}} question="Tem certeza de que deseja remover este item?" />);	
		} else {
			let romaneio = this.state.romaneio;
			romaneio.itens =  romaneio.itens.filter(f => f._key !== item._key);
			this.recalcularRateioFrete(romaneio);
			this.setState({romaneio});
		}
	}

	columns = [
		<Column expander key="expander" style={{textAlign: "center", width: "2em"}}/>,
		<Column key="item" field="item" style={{textAlign: "center",width: "2em"}}/>,
		<Column header="Nota Fiscal" key="notaFiscal" style={{width: "8em", textAlign: "center"}} body={this.editorNotaFiscal}/>,
		<Column header="Pedido" key="pedido" field="pedidoId" style={{textAlign: "center", width: "8em"}} body={(ri, i) => (
			ri._semPedido ? <TZMTextField index={i.rowIndex} type="number" name="pedidoId" value={ri.pedidoId} onChange={this.handleUpdateItem}/> : ri.pedidoId
		)}/>,
		<Column header="Volumes" body={(ri, i) => (
			<TZMTextField index={i.rowIndex} type="number" name="volumes" value={ri.volumes} onChange={this.handleUpdateItem}/>
		)} key="volumes" field="volumes" style={{width: "8em", textAlign: "right"}}/>,
		<Column header="Peso/Kg" body={(ri, i) => (
			<TZMTextField index={i.rowIndex} type="number" name="peso" value={ri.peso} onChange={this.handleUpdateItem}/>
		)} key="peso" field="peso" style={{width: "8em", textAlign: "right"}}/>,
		<Column header="Valor NF" key="valorNf" field="valorNotaFiscal" style={{width: "8em", textAlign: "right"}} body={(rowData) => toCurrency(rowData.valorNotaFiscal)}/>,
		<Column header="Cliente" key="cliente" style={{textAlign: "left", width: "*"}} body={ri => ri.cliente?.vwCliente?.razaoSocial}/>,
		<Column header="Transportadora" key="transportadora" style={{textAlign: "left", width: "*"}} body={ri => ri.transportadora?.vwTransportadora?.razaoSocial}/>,
		<Column header="Valor Frete" key="valorFrete" style={{textAlign: "right", width: "8em"}} body={ri => toCurrency(ri.detalhes?.map(rid => rid.valorFreteRateio).reduce(sum, 0))}/>,
		<Column style={{width: "5em"}} key="acoes" header="Ações" body={(rowData) => {
			let botoes = [];
			botoes.push(<span key={3} title="Remover" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirItem(rowData)}/>);
			return <div style={{ textAlign: "center" }}>{botoes}</div>;
		}}/>
	];

	optionsStatus = [
		<option key={0} value={null}/>,
		<option key={1} value="EXPEDICAO">Expedição</option>,
		<option key={2} value="FATURAMENTO">Faturamento</option>,
		<option key={3} value="FINALIZADO">Finalizado</option>,
		<option key={4} value="CANCELADO">Cancelado</option>
	];

	listarMotoristas = event => {
		event.query = normalizeQuery(event.query);
		this.motoristaService.autoComplete(`search=status==ATIVO;nome=ik='${event.query}',cpf=ik='${event.query}',rg=ik='${event.query}',cnh=ik='${event.query}'`).then(motoristas => {
			if (!motoristas.length) {
				motoristas.push({id: -1, nome: `${this.CRIAR_CADASTRO_MOTORISTA}${event.query}`});
			}
			this.setState({motoristas});
		});
	}

	CRIAR_CADASTRO_MOTORISTA = "Criar cadastro de motorista para ";

	CRIAR_CADASTRO_VEICULO = "Criar cadastro de veículo para ";

	listarVeiculos = event => {
		this.veiculoService.autoComplete(`search=placa=ik='${event.query}'`).then(veiculos => {
			if (!veiculos.length) {
				veiculos.push({id: -1, descricao: `${this.CRIAR_CADASTRO_VEICULO}${event.query.toUpperCase()}`});
			}
			this.setState({veiculos});
		});
	}

	notaFiscalItemTemplate = romaneioItem => {
		return (
			<div>
				<TZMTable footerColumnGroup={(
					<ColumnGroup>
						<Row>
							<Column/>
							<Column/>
							<Column/>
							<Column/>
							<Column style={{textAlign: "right"}} footer={toCurrency(romaneioItem.detalhes?.map(rid => rid.valorFreteRateio).reduce(sum, 0))}/>
							<Column style={{textAlign: "right"}} footer={toCurrency(romaneioItem.detalhes?.map(rid => rid.valorTotalItem).reduce(sum, 0))}/>
						</Row>
					</ColumnGroup>
				)} value={romaneioItem.detalhes}>
					<Column style={{width: "6em"}} header="Item" field="linha"/>
					<Column header="Descrição" field="descricao"/>
					<Column style={{width: "10em", textAlign: "right"}} header="Quantidade" field="quantidade"/>
					<Column style={{width: "6em"}} header="Unidade" field="unidade"/>
					<Column style={{width: "8em", textAlign: "right"}} header="Valor Frete" field="valorFreteRateio" body={rid => toCurrency(rid.valorFreteRateio)}/>
					<Column style={{width: "10em", textAlign: "right"}} header="Valor Total" field="valorTotalItem" body={rid => toCurrency(rid.valorTotalItem)}/>
				</TZMTable>
			</div>
		);
	}

	onSelectMotorista = event => {
		if (event.value.id) {
			if (event.value.id === -1) {
				const {nome} = event.value;
				event.value = null;
				this.handleChange(event, () => {
					ModalRealm.showDialog(<EditarMotorista motorista={{
						id: null,
						nome: nome.replace(this.CRIAR_CADASTRO_MOTORISTA, "")
					}} onModalClose={motorista => {
						event.value = motorista;
						this.handleChange(event);
					}}/>);
				});
			} else {
				this.handleChange(event);
			}
		}
	}

	onSelectVeiculo = event => {
		if (event.value.id) {
			if (event.value.id === -1) {
				const {descricao} = event.value;
				event.value = null;
				this.handleChange(event, () => {
					ModalRealm.showDialog(<EditarVeiculo veiculo={{
						id: null,
						placa: descricao.replace(this.CRIAR_CADASTRO_VEICULO, "")
					}} onModalClose={veiculo => {
						event.value = veiculo;
						this.handleChange(event);
					}}/>);
				});
			} else {
				this.handleChange(event);
			}
		}
	}

	toggleNotaFiscalItem = event => this.setState({notasFiscaisItens: event.data});

    render() {
		return (
			<TZMDialog style={{width: "1510px"}} visible={this.state.visible} modal header="Romaneio" onClose={this.onClose} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
								<div className="ui-g">
									<div className="ui-g-7 ui-g-nopad">
										<div className="ui-g">
											<TZMCombobox col={2} children={this.optionsStatus} value={this.state.romaneio.status} name="status" label="Status" disabled/>
											<TZMCalendar disabled col={2} label="Data de Emissão" name="dataEmissao" onChange={this.handleUpdate} value={this.state.romaneio.dataEmissao}/>
											<TZMAutoComplete col={8} onSelect={this.onSelectMotorista} onClear={this.handleClear} onChange={this.handleChange} placeholder="Nome, CPF, RG ou CNH" suggestions={this.state.motoristas} completeMethod={this.listarMotoristas} name="motorista" label="Motorista" value={this.state.romaneio.motorista} field="nome"/>
											<TZMAutoComplete col={9} onSelect={this.onSelectVeiculo} field="descricao" onClear={this.handleClear} onChange={this.handleChange} placeholder="Placa" suggestions={this.state.veiculos} completeMethod={this.listarVeiculos} name="veiculo" label="Veículo" value={this.state.romaneio.veiculo}/>
											<TZMCurrency label="Valor da Coleta/Retirada" col={3} name="valorColeta" value={this.state.romaneio.valorColeta} onChange={this.handleChange}/>
											<TZMCurrency col={3} field="valorFrete" onBlur={() => {
												const {romaneio} = this.state;
												this.recalcularRateioFrete(romaneio);
												this.setState({romaneio});
											}} value={this.state.romaneio.valorFrete} name="valorFrete" onChange={this.handleChange} label="Valor do Frete"/>
											<TZMCurrency label="Valor do Pedágio" col={3} name="valorPedagio" value={this.state.romaneio.valorPedagio} onChange={this.handleChange}/>
											<TZMCurrency label="Valor da Descarga" col={3} name="valorDescarga" value={this.state.romaneio.valorDescarga} onChange={this.handleChange}/>
											<TZMCurrency label="Outros Valores" col={3} name="valorOutros" value={this.state.romaneio.valorOutros} onChange={this.handleChange}/>
										</div>
									</div>
									<div className="ui-g-5 ui-g-nopad">
										<div className="ui-g">
											<TZMTextArea col={12} name="observacao" field="observacao" onChange={this.handleChange} maxLength="4000" style={{height: "9.75em"}} label="Observações de Embarque" value={this.state.romaneio.observacao}/>
										</div>
									</div>
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Itens</span>}>
								<div className="ui-g">								
									<TZMTable value={this.state.romaneio.itens}
											  paginator
											  rows={10}
											  rowsPerPageOptions={[10, 20, 30]}
											  pageLinkSize={10}
											  emptyMessage="Nenhum item adicionado"
											  rowExpansionTemplate={this.notaFiscalItemTemplate}
											  onRowToggle={this.toggleNotaFiscalItem}
											  expandedRows={this.state.notasFiscaisItens}
											children={this.columns} paginatorLeft={
												<div style={{ textAlign: "left"}}>
													<Button icon="fas fa-plus" title="Adicionar Item" className="tzm-paginator-button" onClick={() =>{this.inserirItem()}} />
												</div>
											}
											footerColumnGroup={
												<ColumnGroup>
													<Row>
														<Column colSpan={4} style={{fontSize: "12px", textAlign: "left"}} footer={"Total de Registros: " + toDecimal(this.state.romaneio.itens.length)}/>
														<Column style={{textAlign: "right"}} footer={toDecimal(this.state.romaneio.itens.map(item => item.volumes).reduce(sum, 0))}/>
														<Column style={{textAlign: "right"}} footer={toDecimal(this.state.romaneio.itens.map(item => item.peso).reduce(sum, 0))}/>
														<Column style={{textAlign: "right"}} footer={toCurrency(this.state.romaneio.itens.map(item => item.valorNotaFiscal).reduce(sum, 0))}/>
														<Column/>
														<Column/>
														<Column style={{textAlign: "right"}} footer={toCurrency(this.state.romaneio.itens.map(item => item.detalhes?.map(rid => rid.valorFreteRateio).reduce(sum, 0)).reduce(sum, 0))}/>
														<Column/>
													</Row>
												</ColumnGroup>
											}/>
								</div>
							</TZMTabPanel>													
						</TZMTabView>
					</TZMForm>	
				</TZMPanel>
				<TZMPanelFooter>					
					{TZMUserData.hasRole("FRETE_ROMA_E") && this.state.romaneio.status === 'EXPEDICAO' ? 
						<TZMButton className="ui-button-info" style={{ float: "left" }} title="Encaminhar para o faturamento" icon="fas fa-file-invoice-dollar" onClick={this.faturamento} /> 
					: null }
					{TZMUserData.hasRole("FRETE_ROMA_E") && this.state.romaneio.status === 'FATURAMENTO' ? 
						<TZMButton className="ui-button-success" style={{ float: "left" }} title="Finalizar o romaneio" icon="fas fa-truck" onClick={this.finalizar} /> 
					: null}
					{TZMUserData.hasRole("FRETE_ROMA_E") && (this.state.romaneio.status !==  'FINALIZADO' && this.state.romaneio.status !==  'CANCELADO') ? 
						<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvar} /> 
					: null}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose} />
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}

}

import React, {Component} from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextArea from "../../../components/common/TZMTextArea";
import {AuditoriaCteService} from "./AuditoriaCteService";
import {CteService} from "../servicos/CteService";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMCombobox from "../../../components/common/TZMCombobox";

export class AprovacaoCte extends Component {

	state = {
		visible: true,
		_key: Math.random(),
		observacoes: "",
		ctes: [],
		valorCorreto: "TRANSPORTADORA"
	};

	cteService = new CteService();
	auditoriaCteService = new AuditoriaCteService();

	onClose = () => this.setState({visible: false});

	aprovarCte = () => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja confirmar?" onYes={async () => {
			for (let cte of this.props.ctes) {
				cte = await this.cteService.completar(cte);
				cte.status = "AUDITADO_REVISADO";
				cte.auditoria.status = "REVISADO";
				cte.auditoria.responsavel = TZMUserData.getUsuario();
				cte.auditoria.observacoes = this.state.observacoes;
				switch (this.state.valorCorreto) {
				case "AUDITORIA":
					cte.auditoria.valorCorreto = cte.auditoria?.valorTotal;
					break;
				default:
					cte.auditoria.valorCorreto = cte.valorPrestacaoServico?.valorTotal;
					break;
				}
				await this.cteService.salvar(cte);
			}
			if (this.props.onModalClose) {
				this.props.onModalClose();
			}
			this.onClose();
		}}/>);
	}

	handleChange = (event) => this.setState({[event.name]: event.value});

	valoresCorretos = [
		<option key={0} value="TRANSPORTADORA">Pela transportadora</option>,
		<option key={1} value="AUDITORIA">Pela auditoria</option>
	];

	render() {
		return (
			<TZMDialog key={this.state._key} style={{width: "700px"}} visible={this.state.visible} modal header="Aprovação de Fretes" onHide={this.onClose} {...this.props}>
				<div className="ui-g">
					<TZMCombobox label="Considerar como valor correto" children={this.valoresCorretos} col={12} name="valorCorreto" onChange={this.handleChange}/>
					<div className="ui-g-12">
						<TZMTextArea style={{height: "9em"}} label="Observações" value={this.state.observacoes} name="observacoes" onChange={this.handleChange}/>
					</div>
				</div>
				<TZMPanelFooter>
					<TZMButton style={{float: "left"}} label="Aprovar Fretes" icon="fas fa-check-circle" onClick={this.aprovarCte}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

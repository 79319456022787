import { httpGetAsync, httpPost } from "../../../utilities/TZMRequest";

export class ConfiguracaoService     {

    completar = async () => {
        return await httpGetAsync("/configuracoes/completar");
    } 

	salvar(configuracao) {
		return httpPost("/configuracoes", configuracao);
	}

    carregarXml = async (params) => {
        return await httpGetAsync("/ctes/carregar-xml-tabela",params);
    } 

}

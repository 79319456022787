import React, {Component} from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMForm from "../../../components/common/TZMForm";
import {TransportadoraUtils} from "../transportadora/TransportadoraUtils";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import moment from "moment";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import {TransportadoraService} from "../servicos/TransportadoraService";
import {MinutaService} from "../servicos/MinutaService";
import TZMTable from "../../../components/table/TZMTable";
import {Column} from "primereact/components/column/Column";
import {EditarMinuta} from "./EditarMinuta";
import {numberFormat} from "../../../utilities/TZMFormat";
import {toCurrency} from "../../../components/common/TZMFormatter";
import {TZMSimpleCheckbox} from "../../../components/common/TZMSimpleCheckbox";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMUserData from "../../../utilities/TZMUserData";
import {httpGet} from "../../../utilities/TZMRequest";

const title = "Minutas";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Minuta extends Component {

	state = {
		inicio: moment().startOf("month").format("YYYY-MM-DD"),
		fim: moment().endOf("month").format("YYYY-MM-DD"),
		transportadora: null,
		chave: "",
		numero: "",
		minutas: []
	};

	minutaService = new MinutaService();

	transportadoraService = new TransportadoraService();

	handleUpdate = event => {
		this.setState({[event.name]: event.value});
	}

	listarTransportadoras = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((transportadoras) => {
			this.setState({transportadoras});
		});
	}

	handleClear = (event) => {
		this.setState({[event.name]: null});
	}

	isMinutaCompleta = minuta => {
		return (
			minuta.status === "PENDENTE"
			&& minuta.origem
			&& minuta.destino
			&& minuta.transportadora
			&& minuta.cliente
			&& minuta.numero
			&& minuta.fatura
			&& minuta.cnpjRemetente
			&& minuta.cnpjTomador
			&& minuta.peso
			&& minuta.valorTotalFrete
			&& minuta.notasFiscais?.length
			&& minuta.elementosCusto?.length
		);
	}

	listarMinutas = () => {
		const params = [];
		const relations = [];
		if (this.state.inicio?.length) params.push(`dataEmissao>${this.state.inicio}`);
		if (this.state.fim?.length) params.push(`dataEmissao<${this.state.fim}`);
		if (this.state.fatura) params.push(`fatura:${this.state.fatura}`);
		if (this.state.numero) params.push(`numero:${this.state.numero}`);
		if (this.state.chave?.length) params.push(`chave:${this.state.chave}`);
		if (this.state.transportadora?.id) relations.push(`transportadora=id:${this.state.transportadora.id}`);
		this.minutaService.listar(`search=${params.join(";")}&${relations.join("&")}`).then(minutas => this.setState({minutas}));
	}

	incluirMinuta = () => {
		httpGet("/minutas/new").then(this.editarMinuta);
	}

	editarMinuta = (minuta) => {
		ModalRealm.showDialog(<EditarMinuta minuta={minuta} onModalClose={this.listarMinutas}/>);
	}

	toggleSelecionado = minuta => {
		const {minutas} = this.state;
		minutas.filter(m => m.id === minuta.id).forEach(m => m._selecionado = !minuta._selecionado);
		this.setState({minutas});
	}

	toggleAllSelecionado = () => {
		const {minutas} = this.state;
		const _selecionado = minutas.every(m => m._selecionado);
		minutas.forEach(m => m._selecionado = !_selecionado && this.isMinutaCompleta(m));
		this.setState({minutas});
	}

	confirmarSelecionados = () => {
		ModalRealm.showDialog(<TZMConfirm question={
			<>
				Tem certeza de que deseja confirmar as minutas selecionadas? <b>ATENÇÃO</b>: Não será possível editá-las depois da confirmação.
			</>
		} onYes={() => {
			const minutas = this.state.minutas.filter(m => m._selecionado);
			this.minutaService.confirmar(minutas.map(m => m.id)).then(this.listarMinutas);
		}}/>);
	}

	printStatus = minuta => {
		switch (minuta.status) {
		case "PREENCHIDO": return <i style={{color: "#2196F3"}} title="Confirmado" className="fas fa-check"/>;
		case "PROCESSADO": return <i style={{color: "#4CAF50"}} title="Processado" className="fas fa-check-circle"/>;
		default: return <i style={{color: "#F44336"}} title="Pendente" className="fas fa-asterisk"/>;
		}
	}

	excluirMinuta = minuta => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja excluir esta minuta?" onYes={() => {
			this.minutaService.excluir(minuta.id).then(this.listarMinutas);
		}}/>);
	}

	lerConembs = () => {
		httpGet("/public/txts/conhecimentos").then(this.listarMinutas);
	}

	converterMinutas = () => {
		httpGet("/public/conhecimentos/minutas").then(this.listarMinutas);
	}

	converterCtes = () => {
		httpGet("/public/minutas/ctes").then(this.listarMinutas);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-1">
										<TZMTextField type="number" name="fatura" autoFocus onChange={this.handleUpdate} label="Nº Fatura" placeholder="Número da Fatura" value={this.state.fatura}/>
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="Período de Emissão da Minuta" placeholder="Inicial" name="inicio" onChange={this.handleUpdate} value={this.state.inicio}/>
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" name="fim" placeholder="Final" onChange={this.handleUpdate} value={this.state.fim}/>
									</div>
									<div className="ui-g-3">
										<TZMTextField name="chave" autoFocus onChange={this.handleUpdate} label="Chave" placeholder="Chave da Minuta" value={this.state.chave}/>
									</div>
									<div className="ui-g-1">
										<TZMTextField type="number" name="numero" placeholder="Número" label="Número" onChange={this.handleUpdate} value={this.state.numero}/>
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete itemTemplate={TransportadoraUtils.transportadoraTemplate}
														 onClear={this.handleClear}
														 onSelect={this.handleUpdate}
														 onChange={this.handleUpdate}
														 placeholder="Razão social, fantasia ou CNPJ"
														 suggestions={this.state.transportadoras}
														 completeMethod={this.listarTransportadoras}
														 name="transportadora"
														 label="Transportadora"
														 value={this.state.transportadora}
														 field="razaoSocial"/>
									</div>
								</div>
								<TZMPanelFooter>
									{
										TZMUserData.getUsuario().login === "admin" ? (
											<div style={{float: "left"}}>
												<TZMButton label="Ler ConEmb's" onClick={this.lerConembs} tooltip="Realiza o processo de leitura dos arquivos ConEmb"/>
												<TZMButton label="Converter Minutas" onClick={this.converterMinutas} tooltip="Realiza o processo de conversão dos ConEmb para Minutas"/>
												<TZMButton label="Converter CT-es" onClick={this.converterCtes} tooltip="Realiza o processo de conversão das minutas para CT-es"/>
											</div>
										) : null
									}
									<TZMButton icon="fas fa-add" label="Incluir" onClick={this.incluirMinuta}/>
									<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarMinutas}/>
								</TZMPanelFooter>
							</TZMForm>
						</TZMPanel>
					</div>
					<div className="ui-g-12">
						<TZMPanel header={<span>Resultado da Pesquisa</span>}>
							<TZMTable value={this.state.minutas} paginator rows={20} paginatorLeft={
								<TZMButton disabled={!this.state.minutas.some(m => m._selecionado)} onClick={this.confirmarSelecionados} label="Confirmar Selecionados"/>
							} paginatorRight={
								<span style={{marginRight: "5.25em", lineHeight: "26px"}}><b>Valor Total: </b>{toCurrency(this.state.minutas.map(m => m.valorTotalFrete).reduce((a, b) => a + b, 0))}</span>
							}>
								<Column style={{width: "3.5em", textAlign: "center"}} header={
									<TZMSimpleCheckbox checked={this.state.minutas.every(m => m._selecionado)} onChange={this.toggleAllSelecionado}/>
								} body={m => <TZMSimpleCheckbox disabled={!this.isMinutaCompleta(m)} checked={m._selecionado} onChange={() => this.toggleSelecionado(m)}/>}/>
								<Column style={{width: "5em", textAlign: "center"}} header="Status" body={this.printStatus}/>
								<Column style={{width: "10em", textAlign: "right"}} header="Fatura" field="fatura" sortable/>
								<Column style={{width: "10em", textAlign: "right"}} header="Número" field="numero" sortable/>
								<Column style={{width: "10em", textAlign: "right"}} header="Data de Emissão" field="dataEmissao" sortable body={m => moment(m.dataEmissao).format("DD/MM/YYYY")}/>
								<Column header="Cliente" body={m => m.cliente?.vwCliente.razaoSocial}/>
								<Column header="Transporte" body={m => (
									<div>
										<div style={{padding: "0 .5em"}}><i className="fa fa-truck lead"/> {m.transportadora?.razaoSocial}</div>
										<div style={{display: "flex", borderTop: "1px solid rgba(50, 50, 50, .1)"}}>
											<div style={{padding: "0 .5em", flex: 1}}><i style={{transform: "rotate(-45deg)"}} className="fa fa-arrow-right green"/> {m.origem ? `${m.origem.nome}/${m.origem.siglaUf}` : ""}</div>
											<div style={{padding: "0 .5em", flex: 1, borderLeft: "1px solid rgba(50, 50, 50, .1)"}}><i style={{transform: "rotate(45deg)"}} className="fa fa-arrow-right red"/> {m.destino ? `${m.destino.nome}/${m.destino.siglaUf}` : ""}</div>
										</div>
									</div>
								)}/>
								<Column style={{width: "12em", textAlign: "right"}} header="Peso" body={m => `${numberFormat.format(m.peso)}Kg`}/>
								<Column style={{width: "12em", textAlign: "right"}} header="Valor" body={m => toCurrency(m.valorTotalFrete)}/>
								<Column style={{width: "5em", textAlign: "center"}} header="Ações" body={m => (
									<div>
										<span title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarMinuta(m)}/>
										<span title="Remover" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirMinuta(m)}/>
									</div>
								)}/>
							</TZMTable>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm/>
			</TZMMainContent>
		);
	}

}

import React, {Component} from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTable from "../../../components/table/TZMTable";
import {Column} from "primereact/components/column/Column";
import TZMTextArea from "../../../components/common/TZMTextArea";
import {AuditoriaCteService} from "./AuditoriaCteService";
import TZMTextField from "../../../components/common/TZMTextField";
import {byDescricao, byNome} from "../../../utilities/SortUtils";
import TZMUserData from "../../../utilities/TZMUserData";
import {CteService} from "../servicos/CteService";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import {toCurrency, toCurrencyRightAligned} from "../../../components/common/TZMFormatter";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import {StatusCte} from "./StatusCte";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {TabelaFreteService} from "../servicos/TabelaFreteService";
import {EditarTabelaFrete} from "../tabela-frete/EditarTabelaFrete";
import moment from "moment";

export class RevisaoCte extends Component {

	state = {
		visible: true,
		_key: Math.random(),
		cte: this.props.cte,
		observacoes: "",
		valorCorreto: this.props.cte.auditoria?.valorTotal || this.props.cte.valorPrestacaoServico?.valorTotal,
		calcValues: []
	};

	cteService = new CteService();
	auditoriaCteService = new AuditoriaCteService();
	tabelaFreteService = new TabelaFreteService();

	componentDidMount() {
		this.alinharCte();
	}

	alinharCte = () => {
		const cte = this.state.cte;
		let descricoes = [];
		cte.auditoria.elementosCusto.forEach(ec => descricoes.push(ec.descricao.toUpperCase()));
		cte.valorPrestacaoServico.itens.forEach(vpsi => descricoes.push(vpsi.nome.toUpperCase()));
		descricoes = descricoes.filter((a, i) => descricoes.indexOf(a) === i);
		descricoes.filter(d => !cte.auditoria.elementosCusto.some(ec => ec.descricao.toUpperCase() === d)).forEach(d => {
			cte.auditoria.elementosCusto.push({nome: d, descricao: d, valor: 0, id: "_ghost"});
		});
		descricoes.filter(d => !cte.valorPrestacaoServico.itens.some(vpsi => vpsi.nome.toUpperCase() === d)).forEach(d => {
			cte.valorPrestacaoServico.itens.push({nome: d, descricao: d, valor: 0, id: "_ghost"});
		});
		cte.auditoria.elementosCusto = cte.auditoria.elementosCusto.sort(byDescricao);
		cte.valorPrestacaoServico.itens = cte.valorPrestacaoServico.itens.sort(byNome);
		const calcValues = [];
		cte.auditoria.elementosCusto.forEach((ec, i) => {
			calcValues.push({valor: ec.valor - cte.valorPrestacaoServico.itens[i].valor});
		});
		this.setState({cte, calcValues});
	}

	onClose = () => this.setState({visible: false});

	aprovarCte = () => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja confirmar?" onYes={() => {
			const {cte} = this.state;
			cte.status = "AUDITADO_REVISADO";
			cte.auditoria.status = "REVISADO";
			cte.auditoria.responsavel = TZMUserData.getUsuario();
			cte.auditoria.observacoes = this.state.observacoes;
			cte.auditoria.valorCorreto = this.state.valorCorreto;
			cte.auditoria.elementosCusto = cte.auditoria.elementosCusto.filter(ec => ec.id !== "_ghost");
			cte.valorPrestacaoServico.itens = cte.valorPrestacaoServico.itens.filter(vpsi => vpsi.id !== "_ghost");
			this.cteService.salvar(cte).then(cte => {
				if (this.props.onModalClose) {
					this.props.onModalClose();
				}
				this.setState({cte}, this.alinharCte);
			});
		}}/>);
	}

	reprocessarAuditoria = () => {
		const {cte} = this.state;
		cte.auditoria.elementosCusto = cte.auditoria.elementosCusto.filter(ec => ec.id !== "_ghost");
		cte.valorPrestacaoServico.itens = cte.valorPrestacaoServico.itens.filter(vpsi => vpsi.id !== "_ghost");
		this.auditoriaCteService.auditar(cte).then(cte => this.setState({cte}, this.alinharCte));
	}

	columnsValores1 = [
		<Column header="Nome" key="nome" style={{width: "*"}} body={ec => <span style={ec.id === "_ghost" ? {color: "#CCCCCC"} : {}}>{ec.nome.toUpperCase()}</span>}/>,
		<Column header="Valor" key="valor" field="valor" style={{textAlign: "right", width: "8em"}} body={(rowData) => <span style={rowData.id === "_ghost" ? {color: "#CCCCCC"} : {}}>{rowData.valor.toCurrency()}</span>}/>
	];

	columnsValores2 = [
		<Column header="Nome" key="descricao" style={{width: "*"}} body={ec => <span style={ec.id === "_ghost" ? {color: "#CCCCCC"} : {}}>{ec.descricao.toUpperCase()}</span>}/>,
		<Column header="Valor" key="valor" field="valor" style={{textAlign: "right", width: "8em"}} body={(rowData) => (
			<span style={rowData.id === "_ghost" ? {color: "#CCCCCC"} : {}}>{rowData.valor.toCurrency()}</span>
		)}/>
	];

	columnsCalcValues = [
		<Column header="Diferença" key={0} body={cv => cv.valor ? toCurrencyRightAligned(cv.valor) : <span>&nbsp;</span>}/>
	];

	columnsMensagens = [
		<Column header="Mensagens da Auditoria" key={0} body={m => m}/>
	];

	handleChange = (event) => this.setState({[event.name]: event.value});

	handleChangeCte = (event) => {
		const {cte} = this.state;
		cte[event.name] = event.value;
		this.setState({cte});
	}

	statusPendentes = [
		"AUDITADO_FALHA",
		"AUDITADO_ERRO"
	];

	corrigirPorTransportadora = () => {
		this.setState({valorCorreto: this.state.cte.valorPrestacaoServico?.valorTotal}, this.focarValorCorreto);
	}

	corrigirPorAuditoria = () => {
		this.setState({valorCorreto: this.state.cte.auditoria?.valorTotal}, this.focarValorCorreto);
	}

	focarValorCorreto = () => {
		const iValorCorreto = document.getElementById(`valorCorreto${this.state._key}`);
		iValorCorreto.focus();
		iValorCorreto.setSelectionRange(0, iValorCorreto.value.length);
	}

	editarTabelaFrete = () => {
		this.tabelaFreteService.listarCte(`transportadora=${this.state.cte.transportadora.transportadoraId}&dataDespacho=${moment(this.state.cte.identificacaoCte.dataEmissao).format("YYYY-MM-DD")}`).then(tabelasFrete => {
			if (tabelasFrete && tabelasFrete.length) {
				ModalRealm.showDialog(<EditarTabelaFrete key={Math.random()} tabelaFrete={tabelasFrete[0]} onModalClose={this.reprocessarAuditoria}/>);
			}
		});
	}

	render() {
		return (
			<TZMDialog key={this.state._key} style={{width: "1000px"}} visible={this.state.visible} modal header="Revisão de Frete" onHide={this.onClose} {...this.props}>
				<div className="ui-g">
					<StatusCte col={3} cte={this.state.cte}/>
					<TZMTextField disabled col={4} label="Número" value={this.state.cte.identificacaoCte?.numeroCte}/>
					<NeoSuggestion.TipoVeiculo col={5} name="tipoVeiculo" label="Tipo de Veículo" value={this.state.cte.tipoVeiculo} onChange={this.handleChangeCte}/>
					<TZMTextField disabled col={6} label="Origem" value={`${this.state.cte.identificacaoCte?.nomeMunicipioOrigem}/${this.state.cte.identificacaoCte?.ufOrigem}`}/>
					<TZMTextField disabled col={6} label="Destino" value={`${this.state.cte.identificacaoCte?.nomeMunicipioDestino}/${this.state.cte.identificacaoCte?.ufDestino}`}/>
					<div className="ui-g-5">
						<TZMTable header="Componentes do Valor da Prestação do Serviço" footer={
							<div style={{textAlign: "right"}}>Total: {toCurrency(this.state.cte.valorPrestacaoServico?.itens.map(i => i.valor).reduce((a, b) => a + b, 0))}</div>
						} value={this.state.cte.valorPrestacaoServico?.itens} children={this.columnsValores1}/>
					</div>
					<div className="ui-g-2">
						<TZMTable header="&nbsp;" value={this.state.calcValues} footer={
							<div style={{textAlign: "right"}}>Total: {toCurrency(this.state.calcValues.map(cv => cv.valor).reduce((a, b) => a + b, 0))}</div>
						} children={this.columnsCalcValues}/>
					</div>
					<div className="ui-g-5">
						<TZMTable header="Componentes do Cálculo da Auditoria do Frete" footer={
							<div style={{textAlign: "right"}}>Total: {toCurrency(this.state.cte.auditoria.elementosCusto.map(i => i.valor).reduce((a, b) => a + b, 0))}</div>
						} value={this.state.cte.auditoria.elementosCusto} children={this.columnsValores2}/>
					</div>
					<div className="ui-g-12">
						<TZMTable value={this.state.cte.auditoria.mensagens} children={this.columnsMensagens}/>
					</div>
					<TZMCurrency id={`valorCorreto${this.state._key}`} disabled={this.state.cte.status === "AUDITADO_REVISADO"} col={3} name="valorCorreto" label="Valor Correto" value={this.state.valorCorreto} onChange={this.handleChange}/>
					<div className="ui-g-9">
						<span className="tzm-link-cte" onClick={this.corrigirPorTransportadora}>O valor correto é o da transportadora?</span>
						{this.state.cte.auditoria?.valorTotal ? <span className="tzm-link-cte" onClick={this.corrigirPorAuditoria}>O valor correto é o da auditoria?</span> : null}
					</div>
					<div className="ui-g-12">
						<TZMTextArea disabled={this.state.cte.status === "AUDITADO_REVISADO"} style={{height: "9em"}} label="Observações" value={this.state.observacoes} name="observacoes" onChange={this.handleChange}/>
					</div>
				</div>
				<TZMPanelFooter>
					<TZMButton disabled={!this.statusPendentes.includes(this.state.cte.status)} style={{float: "left"}} label="Refazer Auditoria" icon="fas fa-sync" onClick={this.reprocessarAuditoria}/>
					<TZMButton disabled={!this.statusPendentes.includes(this.state.cte.status)} style={{float: "left"}} label="Aprovar Frete" icon="fas fa-check-circle" onClick={this.aprovarCte}/>
					<TZMButton disabled={!this.statusPendentes.includes(this.state.cte.status)} style={{float: "left"}} label="Alterar Tabela de Frete" icon="fas fa-edit" onClick={this.editarTabelaFrete}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

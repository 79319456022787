import {httpDelete, httpGet, httpPost} from "../../../utilities/TZMRequest";

export class TipoVeiculoService {

	buscar(id) {
		return httpGet(`/tipos-veiculo/${id}`);
	}

	listar(query) {
		return httpGet(`/tipos-veiculo?${query}`);
	}

	salvar(tipoVeiculo) {
		return httpPost("/tipos-veiculo", tipoVeiculo);
	}

	excluir(id) {
		return httpDelete("/tipos-veiculo", id);
	}

}

import classNames from "classnames";
import React from "react";
import "./Common.css";
import {MultiSelect} from "primereact/components/multiselect/MultiSelect";

export default class TZMMultiSelect extends React.Component {

	componentDidMount() {
		if (this.props.autofocus && this.inputField) {
			this.inputField.focus();
		}
	}

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({...event, name: this.props.name, value: event.value, index: this.props.index});
		}
	}

	render() {
		let classes = classNames({
			"ui-combobox": true,
			"ui-input-required": this.props.required && (!this.props.value || this.props.value.trim().length === 0)
		});
		if (this.props.className != null) {
			classes += " " + this.props.className;
		}
		return (
			<div className={`ui-textfield-group${this.props.col ? ` ui-g-${this.props.col}` : ""}`}>
				<label className="ui-input-label">{this.props.label}</label><br />
				<MultiSelect defaultLabel="Nenhuma opção selecionada" className={classes} {...this.props} onChange={this.onChange}/>
			</div>
		);
	}

}

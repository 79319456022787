
export class MunicipioUtils { 

	static municipioTemplate(municipio){
		return `${municipio.nome} - ${municipio.siglaUf}`;
	}

}



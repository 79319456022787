export function sum(a, b) {
	return a + b;
}

export function isCpfValido(cpf) {
	cpf = cpf.replace(/[^\d]+/g, "");
	if (!cpf.length) return false;
	switch (cpf) {
	case "00000000000":
	case "11111111111":
	case "22222222222":
	case "33333333333":
	case "44444444444":
	case "55555555555":
	case "66666666666":
	case "77777777777":
	case "88888888888":
	case "99999999999":
	case "12345678909":
		return false;
	default:
		break;
	}
	let add = 0;
	for (let i = 0; i < 9; ++i)
		add += Number(cpf.charAt(i)) * (10 - i);
	let rev = 11 - (add % 11);
	if (rev === 10 || rev === 11)
		rev = 0;
	if (rev !== Number(cpf.charAt(9)))
		return false;
	add = 0;
	for (let i = 0; i < 10; ++i)
		add += Number(cpf.charAt(i)) * (11 - i);
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11)
		rev = 0;
	return rev === Number(cpf.charAt(10));

}

export const cFIB = {
	id: {hidden: true},
	dataEmissao: {header: "Data Emissão", format: "date"},
	dataEmissaoCte: {header: "Data Emissão CT-e", format: "date"},
	numeroFatura: {header: "Fatura"},
	notaFiscalId: {header: "Nota Fiscal"},
	tipoNota: {header: "Tipo de Nota"},
	naturezaOperacaoFiscal: {header: "Natureza Operação Fiscal"},
	transportadora: {header: "Transportadora"},
	transportadoraId: {hidden: true},
	cte: {header: "Frete"},
	empresaDestinataria: {header: "Empresa Destinatária/Remetente"},
	enderecoColeta: {header: "Endereço de Coleta/Entrega"},
	cidade: {header: "Cidade"},
	cidadeDestinoTrecho: {header: "Cidade Destino Trecho"},
	ufDestinoTrecho: {header: "UF Destino Trecho"},
	pesoBruto: {header: "Qt, Peso Bruto (kg)"},
	codigoMaterial: {header: "Código Material"},
	material: {header: "Material"},
	familia: {headre: "Familia"},
	unidadeMateria: {header: "Unidade Material"},
	qtItem: {header: "Qt. Item"},
	evento: {header: "Evento"},
	tipoCarga: {header: "Tipo de Carga"},
	tipoImposto: {header: "Tipo Imposto"},
	valorNotaFiscal: {header: "Valor Nota Fiscal", format: "currency"},
	percProduto: {hidden: true},
	difFrete: {header: "Vr Dif Frete Cobr x Prev", format: "currency"},
	valorImposto: {header: "Vr Impostos", format: "currency"},
	valorFreteC_Imposto: {header: "Vr Frete c/ Imposto", format: "currency"},
	valorFretePagarPrevistoKg: {header: "Vr Frete a pagar previsto/Kg", format: "currency"},
	valorFretePrevisto: {header: "Vr Frete Previsto", format: "currency"},
	valorFreteContabil: {header: "Vr Frete Contábil", format: "currency"},
	valorFretePagar: {header: "Vr Frete a pagar", format: "currency"},
	percentualFrete: {header: "% Frete"},
	percentualImposto: {header: "% Imposto"},
	pisCofins: {header: "Cálculo Pis e Cofins"},
	valorLiquido: {header: "Valor Líquido"},
	tipoCte: {header: "Tipo CT-e"},
};

import React from "react";
import moment from "moment";
import TZMButton from "../../../../components/common/TZMButton";
import TZMForm from "../../../../components/common/TZMForm";
import TZMTextField from "../../../../components/common/TZMTextField";
import {ModalRealm} from "../../../../components/modal/ModalRealm";
import TZMPanel from "../../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../../utilities/TZMUserData";
import {EditarRomaneio} from "./EditarRomaneio";
import {Column} from "primereact/components/column/Column";
import TZMMainContent from "../../../../components/main/TZMMainContent";
import TZMTable from "../../../../components/table/TZMTable";
import {RomaneioService} from "../../servicos/RomaneioService";
import TZMCalendar from "../../../../components/common/TZMCalendar";
import {TransportadoraService} from "../../servicos/TransportadoraService";
import {ClienteService} from "../../../servicos/ClienteService";
import TZMCombobox from "../../../../components/common/TZMCombobox";
import TZMAutoComplete from "../../../../components/common/TZMAutoComplete";
import {TransportadoraUtils} from "../../transportadora/TransportadoraUtils";
import {ClienteUtils} from "../../../comum/cliente/ClienteUtils";
import TZMConfirm from "../../../../components/dialog/TZMConfirm";
import {MotoristaService} from "../../../servicos/MotoristaService";
import {VeiculoService} from "../../../servicos/VeiculoService";
import {Validator} from "../../../../utilities/TZMValidator";
import {toCurrency} from "../../../../components/common/TZMFormatter";
import {httpGet} from "../../../../utilities/TZMRequest";

const title = "Romaneio";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Romaneio extends React.Component {

	state = {
		romaneios: [],
		params: {
			numeroPedido: null,
			numeroNf: null,			
			status: null,			
			cliente: null,
			transportadora:null,
			inicio: null,
			fim: null
		}
	};

	romaneioService = new RomaneioService();	
	transportadoraService = new TransportadoraService();	
	clienteService = new ClienteService();
	motoristaService = new MotoristaService();
	veiculoService = new VeiculoService();

	autoCompleteTransportadora = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((response) => {
			this.setState({ transportadoras: response });
		});
	}

	autoCompleteCliente = async (event) => {
        await this.clienteService.autoComplete({cliente: event.query, desativados: false, sort: 'razao'}).then((clientes) => this.setState({clientes}));
    }

	listar = async () => {
		let params = Object.assign({}, this.state.params);
		for (const key of Object.keys(params)) {
			if (Validator.isEntidade(params[key])) {
				params[key] = params[key].id;
			}
		}
		await this.romaneioService.listarSimples(params).then(romaneios => this.setState({romaneios}));
	}
	
	editar = (event) => {
		this.setState({selectedRomaneio: event.data});
		if (event.data) {
			if (event.data.id) {
				this.romaneioService.buscar(event.data).then((romaneio) => {
					ModalRealm.showDialog(<EditarRomaneio key={Math.random()} romaneio={romaneio} onModalClose={this.listar}/>);
				});
			} else {
				ModalRealm.showDialog(<EditarRomaneio key={Math.random()} romaneio={event.data} onModalClose={this.listar}/>);
			}
		}
	}

	handleUpdate = (event) => {		
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});
	}
	
	handleChange = (event) => {		
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}
	
	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	columns = [			
		<Column sortable header="Número" key="numero" field="numero" style={{width: "8em", textAlign: "center"}}/>,
		<Column sortable header="Data Emissão" key="dataEmissao" field="dataEmissao" style={{textAlign: "center", width: "12em"}} body={(rowData) => moment(rowData.dataEmissao).format("DD/MM/YYYY HH:mm:ss")}/>,
		<Column sortable header="Motorista" key="motorista" field="motorista"/>,
		<Column sortable header="Veículo" key="veiculo" field="veiculo"/>,
		<Column style={{textAlign: "right", width: "10em"}} sortable header="Valor do Frete" key="valorFrete" field="valorFrete" body={r => toCurrency(r.valorFrete)}/>,
		<Column header="Notas Fiscais" key="notasFiscais" field="notasFiscais" body={r => r.notasFiscais?.split(";").map(nf => <span key={Math.random()} className="romaneio-notafiscal">{nf}</span>)}/>,
		<Column sortable header="Status" key="status" field="status" style={{textAlign: "center", width: "9em"}}/>,
		<Column header="Ações" key="acoes" style={{width: "7em", textAlign: "center"}} body={(romaneio) => {
			let botoes = [];
			if (TZMUserData.hasRole("FRETE_ROMA_E")) {
				botoes.push(<span key={0} title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.editar({data: romaneio})}/>);
			}			
			botoes.push(<span key={1} title="Imprimir Romaneio" className={romaneio._isLoading1 ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf ui-tzm-icon`} onClick={() => this.imprimir(romaneio)}/>);
			botoes.push(<span key={2} title="Imprimir Contrato" className={romaneio._isLoading2 ? `fa fa-spin fa-circle-notch` : `fa fa-file-signature ui-tzm-icon`} onClick={() => this.imprimirContrato(romaneio)}/>);
			if (romaneio.id != null && romaneio.status !== 'CANCELADO') {
				botoes.push(<span key={3} title="Cancelar" className="fa fa-ban ui-tzm-icon" onClick={(event) => this.cancelar(romaneio)}/>);
			}
			return <div  style={{ textAlign: "center" }}>{botoes}</div>;	
		}}/>
	];

	criar = () => {
		this.setState({selectedRomaneio: null});
		const data = {
			id: null,
			status: "EXPEDICAO",
			dataEmissao: moment().format("YYYY-MM-DDTHH:mm:ss"),
			valorPedagio: 0,
			valorFrete: 0,
			valorColeta: 0,
			valorOutros: 0,
			valorDescarga: 0,
			itens: []
		};
		this.editar({data});
	}

	imprimir = async (romaneio) => {
		const romaneios = this.state.romaneios;
		romaneios.filter(r => r.id === romaneio.id).forEach(p => {
			p._isLoading1 = true;
		});
		this.setState({ romaneios });
		await this.romaneioService.imprimir(romaneio, "Romaneio_" + romaneio.numero).then(response => {
			romaneios.filter(r => r.id === romaneio.id).forEach(r => r._isLoading1 = false);
			this.setState({ romaneios });
		});
	}

	imprimirContrato = async (romaneio) => {
		const romaneios = this.state.romaneios;
		romaneios.filter(r => r.id === romaneio.id).forEach(p => {
			p._isLoading2 = true;
		});
		this.setState({romaneios});
		await this.romaneioService.imprimirContrato(romaneio, "ContratoRomaneio_" + romaneio.numero).then(response => {
			romaneios.filter(r => r.id === romaneio.id).forEach(r => r._isLoading2 = false);
			this.setState({romaneios});
		});
	}

	optionsStatus = [
		<option key={0} value={null}/>,
		<option key={1} value="EXPEDICAO">Expedição</option>,
		<option key={2} value="FATURAMENTO">Faturamento</option>,
		<option key={3} value="FINALIZADO">Finalizado</option>,
		<option key={4} value="CANCELADO">Cancelado</option>
	];

	cancelar = (romaneio) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja cancelar este romaneio? Após cancela-lo não será mais possível desfazer o cancelamento." onYes={() => {
				this.romaneioService.buscar(romaneio).then(romaneio => {
					romaneio.status = "CANCELADO";
					this.romaneioService.salvar(romaneio).then(this.listar);
				});
			}} 
		/>);
	}

	listarMotoristas = event => {
		this.motoristaService.listar(`search=nome=ik=${event.query},cpf=ik=${event.query},rg=ik=${event.query},cnh=ik=${event.query}`).then(motoristas => this.setState({motoristas}));
	}

	listarVeiculos = event => {
		this.veiculoService.listar(`search=placa=ik=${event.query}`).then(veiculos => this.setState({veiculos}));
	}

	imprimirDespesas = async () => {
		this.setState({_lock: true}, () => {
			const {inicio, fim} = this.state.params;
			this.romaneioService.imprimirDespesas({inicio, fim}).then(() => {
				this.setState({_lock: false});
			});
		});
	}

	reprocesarTodos = async () => {
		await httpGet("/romaneios/reprocessar-todos");
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}> 
							<TZMForm>
								<div className="ui-g">
									<TZMCalendar col={2} label="Período de Emissão" placeholder="Inicial" name="inicio" onChange={this.handleUpdate} value={this.state.params.inicio}/>
									<TZMCalendar col={2} label="&nbsp;" placeholder="Final" name="fim" onChange={this.handleUpdate} value={this.state.params.fim}/>
									<TZMTextField col={3} type="number" name="numeroPedido"  placeholder="Número Pedido" label="Pedido" onChange={this.handleUpdate} value={this.state.params.numeroPedido}/>
									<TZMTextField col={3} type="number" name="numeroNf" placeholder="Número NF" label="Nota Fiscal" onChange={this.handleUpdate} value={this.state.params.numeroNf}/>
									<TZMCombobox col={2} value={this.state.params.status} name="status" label="Status" onChange={this.handleChange} children={this.optionsStatus}/>
									<TZMAutoComplete col={3} itemTemplate={TransportadoraUtils.transportadoraTemplate} onClear={this.handleClear}  onSelect={this.handleChange} onChange={this.handleChange} placeholder="Razão social, fantasia ou CNPJ" suggestions={this.state.transportadoras} completeMethod={this.autoCompleteTransportadora} name="transportadora" label="Transportadora" value={this.state.params.transportadora} field="razaoSocial"/>
									<TZMAutoComplete col={3} onClear={this.handleClear} itemTemplate={ClienteUtils.templateTipo} onSelect={this.handleChange} onChange={this.handleChange} suggestions={this.state.clientes} completeMethod={this.autoCompleteCliente} name="cliente" field="descricaoAmigavel" label="Cliente"  placeholder="Razão social, fantasia ou CNPJ"  value={this.state.params.cliente}/>
									<TZMAutoComplete col={3} onClear={this.handleClear} onChange={this.handleChange} placeholder="Nome, CPF, RG ou CNH" suggestions={this.state.motoristas} completeMethod={this.listarMotoristas} name="motorista" label="Motorista" value={this.state.params.motorista} field="nome"/>
									<TZMAutoComplete col={3} field="descricao" onClear={this.handleClear} onChange={this.handleChange} placeholder="Placa" suggestions={this.state.veiculos} completeMethod={this.listarVeiculos} name="veiculo" label="Veículo" value={this.state.params.veiculo}/>
								</div>
							</TZMForm>		
							<TZMPanelFooter>
								<TZMButton label="Relatório de Despesas" onClick={this.imprimirDespesas} disabled={this.state._lock || !this.state.params.inicio?.length || !this.state.params.fim?.length}/>
								{/*<TZMButton label="Reprocessar Todos" onClick={this.reprocesarTodos}/>*/}
								{TZMUserData.hasRole("FRETE_ROMA_C") ? <TZMButton success icon="fas fa-plus" label="Novo Romaneio" onClick={this.criar} /> : null}				
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listar} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.romaneios} paginator rows={50} children={this.columns}/>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
	
}

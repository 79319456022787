import React, {Component} from "react";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {EditarElementoCusto} from "./EditarElementoCusto";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {newTabelaFreteElementoCusto} from "./MapTabelaFrete";
import {byIdOrKey} from "../../../utilities/FilterUtils";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {commonElementoCustoColumns, headerElementoCusto} from "./TabelaFrete";


export class EditarTabelaFreteIncidencia extends Component {

	state = {
		visible: true,
		incidencia: this.props.incidencia
	};

	onClose = () => this.setState({visible: false});

	salvarIncidencia = () => {
		const {incidencia} = this.state;
		const messages = [];
		incidencia.clientes = incidencia.clientes?.filter(c => c && c.id);
		if (!incidencia.clientes?.length) messages.push("A incidencia deve ter ao menos um cliente");
		if (!incidencia.elementosCusto?.length) messages.push("A incidencia deve ter ao menos um elemento de custo");
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>);
		} else {
			if (this.props.onModalClose) {
				this.props.onModalClose(incidencia);
			}
			this.onClose();
		}
	}

	handleChangeItem = (event) => {
		const {incidencia} = this.state;
		incidencia[event.name][event.index] = event.value;
		this.setState({incidencia});
	}

	inserirCliente = () => {
		const {incidencia} = this.state;
		incidencia.clientes.push(null);
		this.setState({incidencia});
	}

	removerCliente = (cliente) => {
		const {incidencia} = this.state;
		incidencia.clientes = incidencia.clientes.filter(c => (byIdOrKey(c, cliente)));
		this.setState({incidencia});
	}

	inserirElementoCusto = () => this.editarElementoCusto(newTabelaFreteElementoCusto());

	editarElementoCusto = (elementoCusto) => {
		ModalRealm.showDialog(<EditarElementoCusto tabelaFrete={this.props.tabelaFrete} descricoes={this.props.descricoes} key={Math.random()} elementoCusto={elementoCusto} onModalClose={elementoCusto => {
			const {incidencia} = this.state;
			const i = incidencia.elementosCusto.findIndex(ec => (ec.id && ec.id === elementoCusto.id) || (ec._key && ec._key === elementoCusto._key));
			if (i >= 0) {
				incidencia.elementosCusto[i] = elementoCusto;
			} else {
				incidencia.elementosCusto.unshift(elementoCusto);
			}
			this.setState({incidencia});
		}}/>);
	}

	removerElementoCusto = (elementoCusto) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja remover este elemento de custo?" onYes={() => {
			const {incidencia} = this.state;
			incidencia.elementosCusto = incidencia.elementosCusto.filter(ec => byIdOrKey(ec, elementoCusto));
			this.setState({incidencia});
		}}/>);
	}

	render() {
		return (
			<Dialog style={{width: "1200px"}} visible={this.state.visible} modal header="Tabela de Frete - Percurso - Destino - Incidência" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<div className="ui-g-12">
						<TZMTable header="Clientes" value={this.state.incidencia.clientes} rows={10} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirCliente}/>
						}>
							<TZMColumn header="Nome" body={(c, i) => (
								<NeoSuggestion.Cliente value={c} name="clientes" index={i.rowIndex} onChange={this.handleChangeItem}/>
							)}/>
							<TZMColumn style={{width: "5em", textAlign: "center"}} header="Ações" body={c => (
								<div>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerCliente(c)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
					<div className="ui-g-12">
						<TZMTable headerColumnGroup={headerElementoCusto} header="Elementos de Custo (para os clientes acima)" value={this.state.incidencia.elementosCusto} rows={10} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirElementoCusto}/>
						}>
							{commonElementoCustoColumns}
							<TZMColumn style={{width: "5em", textAlign: "center"}} body={ec => (
								<div>
									<i className="ui-tzm-icon-uncoloured fa fa-edit" onClick={() => this.editarElementoCusto(ec)}/>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerElementoCusto(ec)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarIncidencia}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarIncidencia} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

import classNames from "classnames";
import React from "react";
import { InputMask } from 'primereact/components/inputmask/InputMask';
import "./Common.css";

export default class TZMInputMask extends React.Component {

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({...event, name: this.props.name});
		}
	}

	render() {        
		let classes = classNames({
			"ui-textfield": true,
			"ui-input-disabled-autocomplete": this.props.forac,
			"ui-input-warning": this.props.warning,
			"ui-input-required": this.props.required && (this.props.value == null || JSON.stringify(this.props.value).trim().length === 0)
		});
		let icon = null;
		if (this.props.exclamation) icon = "ui-input-exclamation";
		if (this.props.pending) icon = "ui-input-pending";
		if (this.props.confirmed) icon = "ui-input-confirmed";
		if (this.props.integrated) icon = "ui-input-integrated";
		return (
			<div className={`ui-textfield-group ui-g-${this.props.col}`}>
				<label className="ui-input-label">{this.props.label}</label><br />
				<div className={icon} title={this.props.exclamation}>
					<InputMask type={this.props.type} className={classes} {...this.props} onChange={this.onChange} value={this.props.value ? this.props.value : ""} />
				</div>
			</div>
		);
	}

}

import preval from 'preval.macro';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/omega/theme.css";
import React from "react";
import { Link, Route, withRouter, Switch } from "react-router-dom";
import "./App.css";
import TZMButton from "./components/common/TZMButton";
import TZMIcon from "./components/common/TZMIcon";
import TZMLogo from "./components/common/TZMLogo";
import TZMAppMenu from "./components/menubar/TZMAppMenu";
import TZMMenubar from "./components/menubar/TZMMenubar";
import { ModalRealm } from "./components/modal/ModalRealm";
import TZMSidebar from "./components/sidebar/TZMSidebar";
import TZMSidebarCard from "./components/sidebar/TZMSidebarCard";
import TZMSidebarCardFooter from "./components/sidebar/TZMSidebarCardFooter";
import { PainelVersao } from "./components/versao/PainelVersao";
import "./modules/font-awesome/css/fontawesome.css";
import "./modules/font-awesome/css/solid.css";
import CheatSheet from "./pages/CheatSheet";
import { Perfil } from './pages/comum/perfil/Perfil';
import Configuracoes from "./pages/comum/usuario/Configuracoes";
import "./utilities/TZMFormat.js";
import TZMShortcut from "./utilities/TZMShortcut";
import TZMUserData from "./utilities/TZMUserData";
import { Transportadora } from './pages/frete/transportadora/Transportadora';
import {TabelaFrete} from "./pages/frete/tabela-frete/TabelaFrete";
import { Cte } from './pages/frete/cte/Cte';
import {TipoVeiculo} from "./pages/frete/tipo-veiculo/TipoVeiculo";
import { Romaneio as RreRomaneio } from "./pages/frete/romaneio/rre/Romaneio";
import { Romaneio as AapRomaneio } from "./pages/frete/romaneio/aap/Romaneio";
import Usuario from "./pages/comum/usuario/Usuario";
import {Fatura} from "./pages/frete/fatura/Fatura";
import {Minuta} from "./pages/frete/minuta/Minuta";
import {Veiculo} from "./pages/frete/veiculos/Veiculo";
import {Motorista} from "./pages/frete/motorista/Motorista";
import  Configuracao  from './pages/frete/configuracao/Configuracao';

const ambiente = process.env.REACT_APP_AMBIENTE;

const BUILD_VERSION = preval`const moment = require('moment');module.exports = moment().format('YYYYMMDD-HHmm');`;

const empresa = process.env.REACT_APP_EMPRESA;

export const App = withRouter(class extends React.Component {

	state = {
		sidebarVisible: false,
		menus: [],
		allowed: {}
	};

	toggleSidebar = () => {
		this.setState({sidebarVisible: !this.state.sidebarVisible});
	}

	openSettings = () => {
		this.setState({sidebarVisible: false});
		ModalRealm.showDialog(<Configuracoes key={TZMUserData.getIdGenerator()} />);
	}

	runLogout = () => {
		TZMUserData.resetUsuario();
		window.location.replace("/");
	}

	showCheatSheet() {
		ModalRealm.showDialog(<CheatSheet key={TZMUserData.getIdGenerator()} />);
	}

	moveTo = (path) => {
		this.props.history.push(path);
		this.setState({sidebarVisible: false});
	}

	componentDidMount() {
		ModalRealm.showDialog(<PainelVersao key={TZMUserData.getIdGenerator()} />);
		let menus = [];
		let allowed = {			
			"CADASTROS": [],
			"FRETE": []
		};
		if (ambiente === "INTERNO") {
			if (TZMUserData.hasRole("____USER_M")) {
				allowed["CADASTROS"].push("____USER_M");				
				menus.push(<Route key={0} path="/usuarios" component={Usuario} />);				
			}

			if (TZMUserData.hasRole("____CLIE_M")) {				
				allowed["CADASTROS"].push("____CLIE_M");				
				menus.push(<Route key={10} path="/configuracoes" component={Configuracao} />);
			}
			if (TZMUserData.hasRole("____PERF_M")) {
				allowed["CADASTROS"].push("____PERF_M");
				menus.push(<Route key={7} path="/perfis" component={Perfil} />);
			}
			if (TZMUserData.hasRole("FRETE_TRANS_M")) {
				allowed["FRETE"].push("FRETE_TRANS_M");
				menus.push(<Route key={19} path="/transportadora" component={Transportadora} />);
			}
			if (TZMUserData.hasRole("FRETE_TABFR_M")) {
				allowed["FRETE"].push("FRETE_TABFR_M");								
				menus.push(<Route key={20} path="/tabelas-frete" component={TabelaFrete} />);
			}
			if (TZMUserData.hasRole("FRETE_CTE_M")) {
				allowed["FRETE"].push("FRETE_CTE_M");
				menus.push(<Route key={21} path="/ctes" component={Cte} />);
			}
			if (TZMUserData.hasRole("FRETE_MINU_M")) {
				allowed["FRETE"].push("FRETE_MINU_M");
				menus.push(<Route key={22} path="/minutas" component={Minuta} />);
			}
			if (TZMUserData.hasRole("FRETE_ROMA_M")) {
				allowed["FRETE"].push("FRETE_ROMA_M");								
				menus.push(<Route key={23} path="/romaneios" component={empresa === "RRE" ? RreRomaneio : AapRomaneio} />);
			}
			if (TZMUserData.hasRole("FRETE_TVEIC_M")) {
				allowed["FRETE"].push("FRETE_TVEIC_M");								
				menus.push(<Route key={24} path="/tipos-veiculo" component={TipoVeiculo} />);
			}
			if (TZMUserData.hasRole("FRETE_VEIC_M")) {
				allowed["FRETE"].push("FRETE_VEIC_M");
				menus.push(<Route key={25} path="/veiculos" component={Veiculo} />);
			}
			if (TZMUserData.hasRole("FRETE_MOTO_M")) {
				allowed["FRETE"].push("FRETE_MOTO_M");
				menus.push(<Route key={26} path="/motoristas" component={Motorista} />);
			}
			if (TZMUserData.hasRole("FRETE_FAT_M")) {
				allowed["FRETE"].push("FRETE_FAT_M");
				menus.push(<Route key={27} path="/faturas" component={Fatura} />);
			}
		}
		this.setState({menus, allowed});
	}

	links = {
		"____PERF_M": {priority: 407, label: "Perfis", to: "/perfis", icon: "th"},
		"____USER_M": {priority: 409, label: "Usuários", to: "/usuarios", icon: "user-circle"},
		"____CLIE_M": {priority: 410, label: "Configuração", to: "/configuracoes", icon: "cogs"},
		"FRETE_TRANS_M": {priority: 900, label: "Transportadoras", to: "/transportadora", icon: "truck"},
		"FRETE_TABFR_M": {priority: 901, label: "Tabelas de Frete", to: "/tabelas-frete", icon: "boxes"},
		"FRETE_CTE_M": {priority: 902, label: "Fretes", to: "/ctes", icon: "truck-loading"},
		"FRETE_MINU_M": {priority: 902, label: "Minutas", to: "/minutas", icon: "truck-loading"},
		"FRETE_TVEIC_M": {priority: 903, label: "Tipos de Veículo", to: "/tipos-veiculo", icon: "motorcycle"},
		"FRETE_VEIC_M": {priority: 904, label: "Veículos", to: "/veiculos", icon: "truck"},
		"FRETE_ROMA_M": {priority: 905, label: "Romaneio", to: "/romaneios", icon: "clipboard-list"},
		"FRETE_FAT_M": {priority: 906, label: "Faturas", to: "/faturas", icon: "file-invoice"},
		"FRETE_MOTO_M": {priority: 907, label: "Motoristas", to: "/motoristas", icon: "users"}
	};

	linkTemplate = (index, linkData) => {
		return {
			index: linkData.priority,
			value: (
				<div key={TZMUserData.getIdGenerator()}> 
					<Link key={linkData.to} onClick={this.toggleSidebar} to={linkData.to}>
						<div key={index} className="ui-sidebar-link">
							<TZMIcon className="sidebar-link" name={linkData.icon} />
							<span>{linkData.label}</span>
						</div>
					</Link>
				</div>
			)
		};
	}
	
	renderGroupLinks = (k) => {
		let i = 0;
		if (this.state.allowed[k].length) {
			return (
				<div key={TZMUserData.getIdGenerator()}>
					<div className="sidebar-link-header">{k}</div>
					{this.state.allowed[k].map(b => this.linkTemplate(i++, this.links[b])).sort((a, b) => a.priority - b.priority).map((i) => i.value)}
				</div>
			);
		} else {
			return null;
		}
	}

	render() {		
		return (
			<div>
				<div className="ui-trademark"><span style={{marginRight: "4px"}}>SFBA Frete {`1.0 ` + BUILD_VERSION}</span><TZMIcon name="copyright" /><span style={{marginLeft: "4px"}}>2021 TZM</span></div>
				<TZMMenubar>
					<TZMAppMenu active={this.state.sidebarVisible ? this.state.sidebarVisible  : undefined} onClick={this.toggleSidebar} />
					<div className="ui-tzm-logo" />
					<TZMLogo />
				</TZMMenubar>
				<TZMSidebar visible={this.state.sidebarVisible} onHide={() => this.setState({ sidebarVisible: false })}>
					<TZMSidebarCard>		
						<TZMSidebarCardFooter>
							<div className="ui-sidebar-footer-strip">
							<span>{TZMUserData.getPrincipal().nome}</span>
								<TZMButton className="sidebar-button ui-button-danger"  icon="fas fa-sign-out-alt" title="Sair do Sistema" onClick={this.runLogout} />
								<TZMButton className="sidebar-button ui-button-primary" icon="fas fa-question-circle" title="Tabela de Atalhos" onClick={this.showCheatSheet} />
								<TZMButton className="sidebar-button ui-button-success" icon="fas fa-cogs" title="Configurações" onClick={this.openSettings} />
							</div>
						</TZMSidebarCardFooter>
					</TZMSidebarCard>
					{Object.keys(this.state.allowed).map(this.renderGroupLinks)}
				</TZMSidebar>
				<main style={{margin: "10px"}}><Switch>{this.state.menus}</Switch></main>
				<TZMShortcut active master onCtrlO={this.openSettings} onF1={this.toggleSidebar} onCtrlF1={this.showCheatSheet} onCtrlQ={this.runLogout} />
				<ModalRealm />				
			</div>
		);
	}

});

import React, {Component} from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMTable from "../../../components/table/TZMTable";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {Column} from "primereact/components/column/Column";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import {FaturaService} from "../../servicos/FaturaService";
import {VisualizarFatura} from "./VisualizarFatura";
import moment from "moment";
import {toCurrencyRightAligned} from "../../../components/common/TZMFormatter";
import TZMCalendar from "../../../components/common/TZMCalendar";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMTextField from "../../../components/common/TZMTextField";

const title = "Fatura";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Fatura extends Component {

	state = {
		faturas: [],
		params: {
			status: null,
			inicio:  moment(new Date()).startOf('month').format("YYYY-MM-DD"),
			fim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
			transportadora: null,
			documento: "",
			numeroDocumento: ""
		}
	};

	faturaService = new FaturaService();

	visualizarFatura = (fatura) => {
		this.faturaService.buscar(fatura.id).then(f => {
			ModalRealm.showDialog(<VisualizarFatura fatura={f} onModalClose={this.listarFaturas}/>);
		});
	}

	cancelarFatura = (fatura) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza que deseja cancelar essa fatura?" onYes={async () => {
			this.faturaService.cancelar(fatura).then(() => {
				this.listarFaturas();
			})
		}}/>);
	}

	listarFaturas = () => {
		const params = this.state.params;
		if (this.state.transportadora?.cpfCnpj) 
			params.transportadora = this.state.transportadora.cpfCnpj;
		this.faturaService.listar(params).then(faturas => this.setState({faturas}));
	}

	columns = [
		<Column header="Nome do Arquivo" key="nomeArquivo" name="nomeArquivo" field="nomeArquivo" style={{width: "20em"}}/>,
		<Column header="Documento" key="documento" name="documento" field="documento" style={{width: "10em"}}/>,
		<Column header="Número Documento" key="numeroDocumento" name="numeroDocumento" field="numeroDocumento" style={{width: "9em"}}/>,
		<Column header="Transportadora" key="transportadora" name="transportadora" field="transportadora"style={{width: "*"}} />,
		<Column header="Data Emissão" style={{width: "10em", textAlign: "center"}} body={f => moment(f.dataEmissao).format("DD/MM/YYYY")} />,
		<Column header="Data Vencimento" style={{width: "10em" , textAlign: "center" }} body={f => moment(f.dataVencimento).format("DD/MM/YYYY")}/>,
		<Column header="Quantidade de CT-e's" key="quantidade" name="quantidade" field="quantidade" style={{width: "7em",  textAlign: "center" }} />,		
		<Column header="Valor Total" style={{width: "12em"}} body={f => toCurrencyRightAligned(f.valorTotal)}/>,
		<Column header="Ações" style={{textAlign: "center", width: "7em"}} body={f => (
			<div>
				<span title="Visualizar Fatura" className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarFatura(f)}/>
				{/* <span title="Cancelar Fatura" className="fa fa-trash ui-tzm-icon" onClick={() => this.cancelarFatura(f)}/> */}
			</div>
		)}/>
	];

	handleUpdate = (event) => {
		const {params} = this.state;
		params[event.name] = event.value;
		this.setState({params});
	}

	optionsStatus = [		
		<option key={0} value=''>Todos</option>,
		<option key={0} value={'PROCESSADO'}>Processado</option>,
		<option key={1} value={'CANCELADO'}>Cancelado</option>
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<TZMCalendar col={2} label="Período Emissão" name="inicio" value={this.state.params.inicio} onChange={this.handleUpdate}/>
									<TZMCalendar col={2} label="&nbsp;" name="fim" value={this.state.params.fim} onChange={this.handleUpdate}/>
									<NeoSuggestion.Transportadora col={3} label="Transportadora" name="transportadora" value={this.state.params.transportadora} onChange={this.handleUpdate}/>
									<TZMTextField col={2} label="Documento" name="documento" value={this.state.params.documento} onChange={this.handleUpdate}/>
									<TZMTextField col={2} label="Número do Documento" name="numeroDocumento" value={this.state.params.numeroDocumento} onChange={this.handleUpdate}/>
									<TZMCombobox col={1} value={this.state.params.status} name="status" label="Status" onChange={this.handleUpdate}>
										{this.optionsStatus}
									</TZMCombobox>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarFaturas}/>
							</TZMPanelFooter>
						</TZMPanel>
					</div>
					<div className="ui-g-12">
						<TZMPanel header={<span>Resultado da Pesquisa</span>}>
							<TZMTable value={this.state.faturas}  paginator rows={50} rowsPerPageOptions={[50, 100, 200]}>
								{this.columns}
							</TZMTable>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm/>
			</TZMMainContent>
		);
	}

}

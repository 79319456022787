import React, {Component} from "react";
import {TabelaFreteService} from "../servicos/TabelaFreteService";
import {Dialog} from "primereact/components/dialog/Dialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {PanelContent} from "../../../components/common/PanelContent";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {EditarTabelaFretePercurso} from "./EditarTabelaFretePercurso";
import {EditarElementoCusto} from "./EditarElementoCusto";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {
	clonarPercurso,
	newTabelaFreteElementoCusto,
	newTabelaFretePercurso,
	printDestino,
	printOrigem, TabelaFreteFlagOptions
} from "./MapTabelaFrete";
import {byUfOrMunicipio} from "../../../utilities/SortUtils";
import {byIdOrKey} from "../../../utilities/FilterUtils";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {commonElementoCustoColumns, headerElementoCusto} from "./TabelaFrete";
import TZMTextField from "../../../components/common/TZMTextField";
import {isLike} from "../../../utilities/QueryUtils";
import {hasSameMunicipioOrUf} from "../../../utilities/EqualUtils";
import {ReajustarElementoCusto} from "./ReajustarElementoCusto";
import TZMMultiSelect from "../../../components/common/TZMMutiSelect";

export class EditarTabelaFrete extends Component {

	state = {
		visible: true,
		tabelaFrete: this.props.tabelaFrete,
		_origens: "",
		_destinos: ""
	};

	tabelaFreteService = new TabelaFreteService();

	onClose = () => this.setState({visible: false});

	salvarTabelaFrete = () => {
		const {tabelaFrete} = this.state;
		const messages = [];
		if (!tabelaFrete.transportadora) {
			messages.push("A transportadora é obrigatória");
		} else {
			delete tabelaFrete.transportadora.municipio;
		}
		if (!tabelaFrete.percursos?.length) messages.push("A tabela de frete deve ter ao menos um percurso");
		for (const percurso of tabelaFrete.percursos) {
			for (const other of tabelaFrete.percursos.slice(tabelaFrete.percursos.findIndex(p => p.id === percurso.id)).filter(p => byIdOrKey(p, percurso))) {
				const origens = percurso.origens.filter(o => other.origens.some(oo => hasSameMunicipioOrUf(o, oo)));
				const destinos = percurso.destinos.filter(o => other.destinos.some(oo => hasSameMunicipioOrUf(o, oo)));
				if (origens.length && destinos.length) {
					messages.push(`O percurso ${percurso.descricao} conflita com ${other.descricao}: Origens = ${origens.map(printOrigem)}; Destinos = ${destinos.map(printDestino).join(", ")}`);
				}
			}
		}
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>)
		} else {
			this.tabelaFreteService.salvar(tabelaFrete).then(tabelaFrete => {
				if (this.props.onModalClose) {
					this.props.onModalClose(tabelaFrete);
				}
				this.onClose();
			});
		}
	}

	handleChange = (event) => {
		const {tabelaFrete} = this.state;
		tabelaFrete[event.name] = event.value;
		this.setState({tabelaFrete});
	}

	inserirPercurso = () => this.editarPercurso(newTabelaFretePercurso());

	editarPercurso = (percurso) => {
		ModalRealm.showDialog(<EditarTabelaFretePercurso descricoes={this.state.tabelaFrete.elementosCusto.map(ec => ec.descricao)}
														 descPercursos={this.state.tabelaFrete.percursos.filter(p => byIdOrKey(p, percurso)).map(p => p.descricao)}
														 key={Math.random()}
														 percurso={percurso}
														 tabelaFrete={this.state.tabelaFrete}
														 onModalClose={percurso => {
			const {tabelaFrete} = this.state;
			const i = tabelaFrete.percursos.findIndex(p => (p.id && p.id === percurso.id) || (p._key && p._key === percurso._key));
			if (i >= 0) {
				tabelaFrete.percursos[i] = percurso;
			} else {
				tabelaFrete.percursos.unshift(percurso);
			}
			this.setState({tabelaFrete});
		}}/>);
	}

	removerPercurso = (percurso) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja remover este percurso?" onYes={() => {
			const {tabelaFrete} = this.state;
			tabelaFrete.percursos = tabelaFrete.percursos.filter(p => byIdOrKey(p, percurso));
			this.setState({tabelaFrete});
		}}/>);
	}

	inserirElementoCusto = () => this.editarElementoCusto(newTabelaFreteElementoCusto());

	editarElementoCusto = (elementoCusto) => {
		ModalRealm.showDialog(<EditarElementoCusto tabelaFrete={this.state.tabelaFrete} key={Math.random()} elementoCusto={elementoCusto} onModalClose={elementoCusto => {
			const {tabelaFrete} = this.state;
			const i = tabelaFrete.elementosCusto.findIndex(ec => (ec.id && ec.id === elementoCusto.id) || (ec._key && ec._key === elementoCusto._key));
			if (i >= 0) {
				tabelaFrete.elementosCusto[i] = elementoCusto;
			} else {
				tabelaFrete.elementosCusto.unshift(elementoCusto);
			}
			this.setState({tabelaFrete});
		}}/>);
	}

	removerElementoCusto = (elementoCusto) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja remover este elemento de custo?" onYes={() => {
			const {tabelaFrete} = this.state;
			tabelaFrete.elementosCusto = tabelaFrete.elementosCusto.filter(ec => byIdOrKey(ec, elementoCusto));
			this.setState({tabelaFrete});
		}}/>);
	}

	handleTableQuery = (event) => {
		if (this.origensTimeout) {
			clearTimeout(this.origensTimeout);
			this.origensTimeout = null;
		}
		this.setState({[`_${event.name}`]: event.value}, () => {
			this.origensTimeout = setTimeout(() => {
				const {tabelaFrete} = this.state;
				tabelaFrete.percursos.forEach(p => {
					const hidden1 = p.origens.some(o => (o.municipio && isLike(o.municipio.nome, this.state._origens)) || (o.uf && isLike(o.uf.nome, this.state._origens)));
					const hidden2 = p.destinos.some(o => (o.municipio && isLike(o.municipio.nome, this.state._destinos)) || (o.uf && isLike(o.uf.nome, this.state._destinos)));
					p._hidden = !hidden1 || !hidden2;
				});
				this.setState({tabelaFrete});
			}, 300);
		});
	}

	clonarPercurso = o => this.editarPercurso(clonarPercurso(o));

	reajustarValor = () => {
		const {tabelaFrete} = this.state;
		let elementosCusto = [];
		tabelaFrete.elementosCusto.forEach(ec => elementosCusto.push(ec.descricao));
		tabelaFrete.percursos.forEach(p => {
			p.elementosCusto.forEach(ec => elementosCusto.push(ec.descricao));
			p.destinos.forEach(d => {
				d.incidencias.forEach(i => {
					i.elementosCusto.forEach(ec => elementosCusto.push(ec.descricao));
				});
			});
		});
		elementosCusto = elementosCusto.filter((ec, i) => elementosCusto.indexOf(ec) === i);
		ModalRealm.showDialog(<ReajustarElementoCusto elementosCusto={elementosCusto} onModalClose={reajuste => {
			const reajustador = valor => {
				switch (reajuste.forma) {
				case "PERCENTUAL": return (Number(valor) * (100 + Number(reajuste.valor))) / 100;
				default: return Number(valor) + Number(reajuste.valor);
				}
			};
			const reajustadorElementoCusto = ec => {
				if (ec.valorMinimo) {
					ec.valorMinimo = reajustador(ec.valorMinimo);
				}
				if (ec.valorLimite) {
					ec.valorLimite = reajustador(ec.valorLimite);
				}
				ec.faixas.forEach(ecf => {
					ecf.valor = reajustador(ecf.valor);
				});
			};
			const ecFilter = ec => ec.descricao === reajuste.elementoCusto;
			const {tabelaFrete} = this.state;
			tabelaFrete.elementosCusto.filter(ecFilter).forEach(reajustadorElementoCusto);
			tabelaFrete.percursos.forEach(p => {
				p.elementosCusto.filter(ecFilter).forEach(reajustadorElementoCusto);
				p.destinos.forEach(d => {
					d.incidencias.forEach(i => {
						i.elementosCusto.filter(ecFilter).forEach(reajustadorElementoCusto);
					});
				});
			});
			this.setState({tabelaFrete});
			ModalRealm.showDialog(<TZMPopup messages={[`Reajuste de ${reajuste.forma === "PERCENTUAL" ? "" : "R$"}${reajuste.valor}${reajuste.forma === "PERCENTUAL" ? "%" : ""} em ${reajuste.elementoCusto} realizado com sucesso!`]}/>);
		}}/>);
	}

	render() {
		return (
			<Dialog style={{width: "1200px"}} visible={this.state.visible} modal header="Tabela de Frete" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<NeoSuggestion.Transportadora col={8} name="transportadora" value={this.state.tabelaFrete.transportadora} onChange={this.handleChange}/>
					<TZMCalendar onChange={this.handleChange} value={this.state.tabelaFrete.inicio} name="inicio" col={2} label="Vigência"/>
					<TZMCalendar onChange={this.handleChange} value={this.state.tabelaFrete.fim} name="fim" col={2} label="&nbsp;"/>
					<TZMMultiSelect value={this.state.tabelaFrete.flags} options={TabelaFreteFlagOptions} name="flags" label="Opções" col={6} onChange={this.handleChange}/>
					<TZMTextField col={3} label="Descrição do ICMS" value={this.state.tabelaFrete.descricaoIcms} name="descricaoIcms" onChange={this.handleChange}/>
					<TZMTextField col={3} label="Descrição do Peso Taxado" value={this.state.tabelaFrete.descricaoPesoTaxado} name="descricaoPesoTaxado" onChange={this.handleChange}/>
					<div className="ui-g-12">
						<TZMTable value={this.state.tabelaFrete.percursos.filter(p => !p._hidden)} header="Percursos" rows={10} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirPercurso}/>
						}>
							<TZMColumn style={{width: "*"}} header="Descrição" field="descricao" sortable/>
							<TZMColumn style={{width: "*"}} header={(
								<TZMTextField label="Origens" name="origens" value={this.state._origens} onChange={this.handleTableQuery}/>
							)} body={p => p.origens.sort(byUfOrMunicipio).map(printOrigem).join(", ")}/>
							<TZMColumn style={{width: "*"}} header={(
								<TZMTextField label="Destinos" name="destinos" value={this.state._destinos} onChange={this.handleTableQuery}/>
							)} body={p => <div className="tabela-frete-percurso-list">{p.destinos.sort(byUfOrMunicipio).map(printDestino).join(", ")}</div>}/>
							<TZMColumn style={{width: "6em", textAlign: "center"}} header="Ações" body={p => (
								<div>
									<i title="Editar Percurso" className="ui-tzm-icon-uncoloured fa fa-edit" onClick={() => this.editarPercurso(p)}/>
									<i title="Clonar Percurso" className="ui-tzm-icon-uncoloured fa fa-copy" onClick={() => this.clonarPercurso(p)}/>
									<i title="Remover Percurso" className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerPercurso(p)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
					<div className="ui-g-12">
						<TZMTable headerColumnGroup={headerElementoCusto} value={this.state.tabelaFrete.elementosCusto} header="Elementos de Custo (Global)" rows={10} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirElementoCusto}/>
						}>
							{commonElementoCustoColumns}
							<TZMColumn style={{width: "5em", textAlign: "center"}} body={ec => (
								<div>
									<i className="ui-tzm-icon-uncoloured fa fa-edit" onClick={() => this.editarElementoCusto(ec)}/>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerElementoCusto(ec)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton style={{float: "left"}} secondary label="Reajustar Valor" icon="fas fa-percent" onClick={this.reajustarValor}/>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarTabelaFrete}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarTabelaFrete} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

import React, {Component} from "react";
import {VeiculoService} from "../../servicos/VeiculoService";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {EditarVeiculo} from "./EditarVeiculo";

const title = "Veículos";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Veiculo extends Component {

	state = {
		veiculos: [],
		placa: "",
		modelo: "",
		fabricante: ""
	};

	veiculoService = new VeiculoService();

	handleChange = event => {
		this.setState({[event.name]: event.value});
	}

	listarVeiculos = () => {
		let query = [];
		if (this.state.placa?.length) {
			query.push(`placa==${this.state.placa}`);
		}
		if (this.state.modelo?.length) {
			query.push(`modelo=ik=${this.state.modelo}`);
		}
		if (this.state.fabricante?.length) {
			query.push(`fabricante=ik=${this.state.fabricante}`);
		}
		this.veiculoService.listar(`search=${query.join(";")}`).then(veiculos => this.setState({veiculos}));
	}

	criarVeiculo = () => {
		this.editarVeiculo({
			placa: "",
			fabricante: "",
			modelo: "",
			tipoVeiculo: null,
			capacidade: 0,
			carga: 0
		});
	}

	editarVeiculo = veiculo => {
		ModalRealm.showDialog(<EditarVeiculo veiculo={veiculo} onModalClose={this.listarVeiculos}/>);
	}

	excluirVeiculo = veiculo => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja excluir este veículo?" onYes={() => {
			this.veiculoService.excluir(veiculo.id).then(this.listarVeiculos);
		}}/>);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<TZMTextField type="plate" maxLength={7} label="Placa" col={4} name="placa" value={this.state.placa} onChange={this.handleChange}/>
									<TZMTextField label="Modelo" col={4} name="modelo" value={this.state.modelo} onChange={this.handleChange}/>
									<TZMTextField label="Fabricante" col={4} name="fabricante" value={this.state.fabricante} onChange={this.handleChange}/>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								{TZMUserData.hasRole("FRETE_VEIC_C") ? <TZMButton success icon="fas fa-plus" label="Novo Veículo" onClick={this.criarVeiculo}/> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarVeiculos}/>
							</TZMPanelFooter>
						</TZMPanel>
					</div>
				</div>
				<div style={{height: "8px"}} />
				<TZMPanel header="Resultado da Pesquisa">
					<TZMTable value={this.state.veiculos} paginator rows={50}>
						<TZMColumn header="Placa" field="placa"/>
						<TZMColumn header="Modelo" field="modelo"/>
						<TZMColumn header="Fabricante" field="fabricante"/>
						<TZMColumn header="Tipo de Veículo" body={v => v.tipoVeiculo?.descricao}/>
						<TZMColumn header="Capacidade (m³)" field="capacidade"/>
						<TZMColumn header="Carga (Kg)" field="carga"/>
						<TZMColumn header="Ações" style={{width: "6em", textAlign: "center"}} body={v => (
							<div>
								{TZMUserData.hasRole("FRETE_VEIC_E") ? <span title="Editar Veículo" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarVeiculo(v)} /> : null}
								{TZMUserData.hasRole("FRETE_VEIC_D") ? <span title="Excluir Veículo" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirVeiculo(v)} /> : null}
							</div>
						)}/>
					</TZMTable>
				</TZMPanel>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}

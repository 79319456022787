
export class Util {

	static trim(object) {
		if (typeof object === "string") return object.trim();
		if (typeof object === "object") Object.keys(object).forEach(k => typeof object[k] === "string" ? object[k] = object[k].trim() : null);
		return object;
	}

	static hintAutoComplete(result, field) {
		if (result == null) result = [];
		if (result.length === 0) result.push({[field || "descricao"]: "Nenhum registro encontrado", id: null, pk: {}});
		return result;
	}

	static isEmptyObject(object) {
		return object != null || (Object.keys(object).length === 0 && object.constructor === Object);
	}

	static emptyPromise(val = null) {
		return new Promise((resolve) => { resolve(val); });
	}


}

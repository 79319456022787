import React, {Component} from "react";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import {Card} from "primereact/components/card/Card";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import {Column} from "primereact/components/column/Column";
import moment from "moment";
import {toCurrency, toCurrencyRightAligned, toNumber} from "../../../components/common/TZMFormatter";
import TZMTable from "../../../components/table/TZMTable";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import TZMTextField from "../../../components/common/TZMTextField";
import {CteService} from "../servicos/CteService";
import {VisualizarCte} from "../cte/VisualizarCte";
import TZMUserData from "../../../utilities/TZMUserData";

export class VisualizarFatura extends Component {

	state = {
		visible: true,
		fatura: this.props.fatura,
		ctes: [],
	};

	cteService = new CteService();

	onClose = () => this.setState({visible: false});

	columnGroupDocumentos = (
		<ColumnGroup>
			<Row>
				<Column rowSpan={2} header="Filial"/>
				<Column rowSpan={2} header="Tipo"/>
				<Column rowSpan={2} header="Série"/>
				<Column colSpan={3} header="Data"/>
				<Column rowSpan={2} header="Tipo de Cobrança"/>
				<Column colSpan={4} header="Valores"/>
				<Column colSpan={3} header="Dados Bancários"/>
				<Column rowSpan={2} header="Ação Documento"/>
			</Row>
			<Row>
				<Column header="Emissão"/>
				<Column header="Vencimento"/>
				<Column header="Lim Pag Desconto"/>
				<Column header="Valor Fatura"/>
				<Column header="ICMS"/>
				<Column header="Juros Mora"/>
				<Column header="Desconto Acréscimo"/>
				<Column header="Agente Cobrança"/>
				<Column header="Nº Agência"/>
				<Column header="Conta Corrente"/>
			</Row>
		</ColumnGroup>
	);

	columnsDocumentos = [
		<Column key="filial" field="filial"/>,
		<Column key="tipoDocumento" field="tipoDocumento"/>,
		<Column key="serieDocumento" field="serieDocumento"/>,
		<Column key="dataEmissao" body={d => moment(d.dataEmissao).format("DD/MM/YYYY")}/>,
		<Column key="dataVencimento" body={d => moment(d.dataVencimento).format("DD/MM/YYYY")}/>,
		<Column key="dataLimitePagamentoDesconto" body={d => moment(d.dataLimitePagamentoDesconto).format("DD/MM/YYYY")}/>,
		<Column key="tipoCobranca" field="tipoCobranca"/>,
		<Column key="valorDocumento" body={d => toCurrencyRightAligned(d.valorDocumento)}/>,
		<Column key="valorTotalIcms" body={d => toCurrencyRightAligned(d.valorTotalIcms)}/>,
		<Column key="valorJurosDiaAtraso" body={d => toCurrencyRightAligned(d.valorJurosDiaAtraso)}/>,
		<Column key="valorDescontoAcrescimo" body={d => toCurrencyRightAligned(d.valorDescontoAcrescimo)}/>,
		<Column key="identificacaoAgenteCobranca" field="identificacaoAgenteCobranca"/>,
		<Column key="agencia" body={d => `${d.numeroAgenciaBancaria}-${d.digitoVerificadorAgenciaBancaria}`}/>,
		<Column key="contaCorrente" body={d => `${d.contaCorrente}-${d.digitoVerificadorContaCorrente}`}/>,
		<Column key="acaoDocumento" field="acaoDocumento"/>
	];

	visualizar = (event) => {
		this.setState({selectedCte: event.data});
		if (event.data != null) {
			console.log(event.data)
			this.cteService.completarCteFromFatura(event.data).then((cte) => {
				console.log(cte)
				ModalRealm.showDialog(<VisualizarCte 
										key={TZMUserData.getIdGenerator()} 
										cte={cte}  
										// onModalClose={this.listar}  
				/>);
			});
		}
	}

	render() {
		return (
			<TZMDialog  style={{width: "1250px"}}  visible={this.state.visible} modal header="Fatura" onHide={this.onClose} {...this.props}>
				<TZMTabView >
					<TZMTabPanel header="Dados Principais">
						<div className="ui-g">
							<div className="ui-g-4">
								<Card subtitle="Cabeçalho do Documento">
									<div className="ui-g">
										<TZMTextField disabled col={12} label="Registro" value={this.state.fatura.faturaCabecalhoDocumento.registro}/>
										<TZMTextField disabled col={12} label="Documento" value={this.state.fatura.faturaCabecalhoDocumento.documento}/>
									</div>
								</Card>
							</div>
							<div className="ui-g-8">
								<Card subtitle="Cabeçalho de Intercâmbio">
									<div className="ui-g">
										<TZMTextField disabled col={3} label="Registro" value={this.state.fatura.faturaCabecalhoIntercambio.registro}/>
										<TZMTextField disabled col={6} label="Intercâmbio" value={this.state.fatura.faturaCabecalhoIntercambio.intercambio}/>
										<TZMTextField disabled col={3} label="Data" value={moment(this.state.fatura.faturaCabecalhoIntercambio.data).format("DD/MM/YYYY")}/>
										<TZMTextField disabled col={6} label="Remetente" value={this.state.fatura.faturaCabecalhoIntercambio.remetente}/>
										<TZMTextField disabled col={6} label="Destinatário" value={this.state.fatura.faturaCabecalhoIntercambio.destinatario}/>
									</div>
								</Card>
							</div>
							<div className="ui-g-6">
								<Card subtitle="Transportadora">
									<div className="ui-g">
										<TZMTextField disabled col={3} label="Registro" value={this.state.fatura.faturaTransportadora.registro}/>
										<TZMTextField disabled col={9} label="CNPJ" value={this.state.fatura.faturaTransportadora.cnpj}/>
										<TZMTextField disabled col={12} label="Razão Social" value={this.state.fatura.faturaTransportadora.razaoSocial}/>
									</div>
								</Card>
							</div>
							<div className="ui-g-6">
								<Card subtitle="Total da Fatura">
									<div className="ui-g">
										<TZMTextField disabled col={6} label="Registro" value={this.state.fatura.faturaTotalFatura?.registro}/>
										<TZMTextField disabled col={6} label="Quantidade" value={this.state.fatura.faturaTotalFatura?.quantidade}/>
										<TZMTextField disabled col={12} label="Valor Total" value={toCurrency(this.state.fatura.faturaTotalFatura?.valorTotal)}/>
									</div>
								</Card>
							</div>
							<div className="ui-g-12">
								<Card subtitle="Documento">
									<div className="ui-g">
										<TZMTextField disabled col={3} label="Filial" value={this.state.fatura.documentos[0]?.filial || ""}/>
										<TZMTextField disabled col={3} label="Tipo" value={`${this.state.fatura.documentos[0]?.tipoDocumento || ""}`}/>
										<TZMTextField disabled col={2} label="Série" value={this.state.fatura.documentos[0]?.serieDocumento || ""}/>
										<TZMTextField disabled col={2} label="Tipo de Cobrança" value={this.state.fatura.documentos[0]?.tipoCobranca || ""}/>
										<TZMTextField disabled col={2} label="Ação Documento" value={this.state.fatura.documentos[0]?.acaoDocumento || ""}/>
										<TZMTextField disabled col={4} label="Data de Emissão" value={this.state.fatura.documentos[0]?.dataEmissao ? moment(this.state.fatura.documentos[0]?.dataEmissao).format("DD/MM/YYYY") : ""}/>
										<TZMTextField disabled col={4} label="Data de Vencimento" value={this.state.fatura.documentos[0]?.dataVencimento ? moment(this.state.fatura.documentos[0]?.dataVencimento).format("DD/MM/YYYY") : ""}/>
										<TZMTextField disabled col={4} label="Data Limite para Pagamento com Desconto" value={this.state.fatura.documentos[0]?.dataLimitePagamentoDesconto ? moment(this.state.fatura.documentos[0]?.dataLimitePagamentoDesconto).format("DD/MM/YYYY") : ""}/>
										<TZMTextField disabled col={3} label="Valor da Fatura" value={toCurrency(this.state.fatura.documentos[0]?.valorDocumento || 0)}/>
										<TZMTextField disabled col={3} label="ICMS" value={toCurrency(this.state.fatura.documentos[0]?.valorTotalIcms || 0)}/>
										<TZMTextField disabled col={3} label="Juros Mora" value={toCurrency(this.state.fatura.documentos[0]?.valorJurosDiaAtraso || 0)}/>
										<TZMTextField disabled col={3} label="Desconto Acréscimo" value={toCurrency(this.state.fatura.documentos[0]?.valorDescontoAcrescimo || 0)}/>
										<TZMTextField disabled col={5} label="Agente Cobrança" value={this.state.fatura.documentos[0]?.identificacaoAgenteCobranca || ""}/>
										<TZMTextField disabled col={2} label="Nº da Agência" value={`${this.state.fatura.documentos[0]?.numeroAgenciaBancaria || ""}-${this.state.fatura.documentos[0]?.digitoVerificadorAgenciaBancaria || ""}`}/>
										<TZMTextField disabled col={5} label="Nº Conta Corrente" value={`${this.state.fatura.documentos[0]?.contaCorrente || ""}-${this.state.fatura.documentos[0]?.digitoVerificadorContaCorrente || ""}`}/>
									</div>
								</Card>
							</div>
						</div>
					</TZMTabPanel>					
					<TZMTabPanel header="Fretes">
						<TZMTable value={this.state.fatura.documentos[0]?.ctes} paginator rows={12} responsive
							footer={(
								<>
									<div style={{textAlign: "right"}}><b>Total de Registros:</b> {toNumber(this.state.fatura.documentos[0]?.ctes?.length || 0)}</div>
									<div style={{textAlign: "right"}}><b>Valor Total:</b> {toCurrency(this.state.fatura.documentos[0]?.ctes?.map(c => c.valorFrete).reduce((a, b) => a + b, 0) || 0)}</div>
								</>
							)}>
							<Column header="Número" key="numero" field="numero" style={{textAlign: "center", width: "5em"}}/>
							<Column header="Data de Emissão" key="dataEmissao" body={c => moment(c.dataEmissao).format("DD/MM/YYYY")} style={{textAlign: "center", width: "15em"}}/>
							<Column header="Remetente" key="nomeRemetente" field="nomeRemetente" style={{width: "*"}}/>
							<Column header="Destinatário" key="nomeDestinatario" field="nomeDestinatario" style={{width: "*"}}/>
							<Column header="Valor Total" key="valorFrete" body={c => toCurrencyRightAligned(c.valorFrete)} style={{textAlign: "center", width: "15em"}}/>
							<Column header="Ações" key="acoes" style={{textAlign: "center", width: "5em"}} body={(c) => {
								return (
									<div style={{textAlign: "center"}}>
										<span title="Visualizar Frete" className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizar({data: c})}/>
									</div>
								);
							}}/>
						</TZMTable>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

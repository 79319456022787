import React, {Component} from "react";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {EditarElementoCusto} from "./EditarElementoCusto";
import {EditarTabelaFreteDestino} from "./EditarTabelaFreteDestino";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {
	newTabelaFreteElementoCusto,
	newTabelaFretePercursoDestino,
	newTabelaFretePercursoOrigem
} from "./MapTabelaFrete";
import {byIdOrKey, isNotHidden} from "../../../utilities/FilterUtils";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {commonElementoCustoColumns, headerElementoCusto} from "./TabelaFrete";
import {EditarTabelaFreteOrigem} from "./EditarTabelaFreteOrigem";
import TZMTextField from "../../../components/common/TZMTextField";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import {isLike} from "../../../utilities/QueryUtils";

export class EditarTabelaFretePercurso extends Component {

	state = {
		visible: true,
		percurso: this.props.percurso
	};

	onClose = () => this.setState({visible: false});

	handleChange = (event) => {
		const {percurso} = this.state;
		percurso[event.name] = event.value;
		this.setState({percurso});
	}

	salvarPercurso = () => {
		const {percurso} = this.state;
		const messages = [];
		if (!percurso.origens?.length) messages.push("O percurso deve ter ao menos uma origem");
		if (!percurso.destinos?.length) messages.push("O percurso deve ter ao menos um destino");
		if (!percurso.descricao?.length) {
			messages.push("O percurso deve ter uma descrição");
		} else if (this.props.descPercursos?.includes(percurso.descricao)) {
			messages.push("Esta descrição já está sendo usada por outro percurso");
		}
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>);
		} else {
			if (this.props.onModalClose) {
				this.props.onModalClose(this.state.percurso);
			}
			this.onClose();
		}
	}

	inserirOrigem = () => this.editarOrigem(newTabelaFretePercursoOrigem());

	inserirDestino = () => this.editarDestino(newTabelaFretePercursoDestino());

	editarOrigem = (origem) => {
		ModalRealm.showDialog(<EditarTabelaFreteOrigem key={Math.random()} origem={origem} onModalClose={origem => {
			const {percurso} = this.state;
			const i = percurso.origens.findIndex(o => (o.id && o.id === origem.id) || (o._key && o._key === origem._key));
			if (i >= 0) {
				percurso.origens[i] = origem;
			} else {
				percurso.origens.unshift(origem);
			}
			this.setState({percurso});
		}}/>);
	}

	editarDestino = (destino) => {
		ModalRealm.showDialog(<EditarTabelaFreteDestino tabelaFrete={this.props.tabelaFrete} descricoes={[...this.props.descricoes, ...this.state.percurso.elementosCusto.map(ec => ec.descricao)]} key={Math.random()} destino={destino} onModalClose={destino => {
			const {percurso} = this.state;
			const i = percurso.destinos.findIndex(d => (d.id && d.id === destino.id) || (d._key && d._key === destino._key));
			if (i >= 0) {
				percurso.destinos[i] = destino;
			} else {
				percurso.destinos.unshift(destino);
			}
			this.setState({percurso});
		}}/>);
	}

	inserirElementoCusto = () => this.editarElementoCusto(newTabelaFreteElementoCusto());

	editarElementoCusto = (elementoCusto) => {
		ModalRealm.showDialog(<EditarElementoCusto tabelaFrete={this.props.tabelaFrete} descricoes={this.props.descricoes} key={Math.random()} elementoCusto={elementoCusto} onModalClose={elementoCusto => {
			const {percurso} = this.state;
			const i = percurso.elementosCusto.findIndex(ec => (ec.id && ec.id === elementoCusto.id) || (ec._key && ec._key === elementoCusto._key));
			if (i >= 0) {
				percurso.elementosCusto[i] = elementoCusto;
			} else {
				percurso.elementosCusto.unshift(elementoCusto);
			}
			this.setState({percurso});
		}}/>);
	}

	removerElementoCusto = (elementoCusto) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja remover este elemento de custo?" onYes={() => {
			const {percurso} = this.state;
			percurso.elementosCusto = percurso.elementosCusto.filter(ec => byIdOrKey(ec, elementoCusto));
			this.setState({percurso});
		}}/>);
	}

	removerDestino = (destino) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja remover este destino?" onYes={() => {
			const {percurso} = this.state;
			percurso.destinos = percurso.destinos.filter(d => byIdOrKey(d, destino));
			this.setState({percurso});
		}}/>);
	}

	adicionarOrigem = (event) => {
		const {percurso} = this.state;
		percurso.origens.push(event.value);
		this.setState({percurso, origem: null});
	}

	removerOrigem = (municipio) => {
		const {percurso} = this.state;
		percurso.origens = percurso.origens.filter(o => o.id !== municipio.id);
		this.setState({percurso});
	}

	handleTableQuery = (event) => {
		if (this.origensTimeout) {
			clearTimeout(this.origensTimeout);
			this.origensTimeout = null;
		}
		this.setState({[`_${event.name}`]: event.value}, () => {
			this.origensTimeout = setTimeout(() => {
				const {percurso} = this.state;
				percurso.destinos.forEach(d => {
					d._hidden = !((d.municipio && isLike(d.municipio.nome, this.state._destinos)) || (d.uf && isLike(d.uf.nome, this.state._destinos)));
				});
				this.setState({percurso});
			}, 300);
		});
	}

	render() {
		return (
			<Dialog style={{width: "1200px"}} visible={this.state.visible} modal header="Tabela de Frete - Percurso" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<TZMTextField label="Descrição" col={8} name="descricao" value={this.state.percurso.descricao} onChange={this.handleChange}/>
					<TZMCurrency label="Valor Mínimo" col={2} name="valorMinimo" value={this.state.percurso.valorMinimo} onChange={this.handleChange}/>
					<TZMCurrency label="Valor Máximo" col={2} name="valorMaximo" value={this.state.percurso.valorMaximo} onChange={this.handleChange}/>
					{this.props.descPercursos?.includes(this.state.percurso.descricao) ? <div className="tf-ec-warning">Já existe outro percurso com essa mesma descrição</div> : null}
					<div className="ui-g-12">
						<TZMTable header="Origens" value={this.state.percurso.origens} rows={5} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirOrigem}/>
						}>
							<TZMColumn header="Origem" body={d => d.municipio ? `${d.municipio.nome}/${d.municipio.siglaUf}` : d.uf.nome}/>
							<TZMColumn header="Ações" style={{width: "5em"}} body={o => (
								<div style={{textAlign: "center"}}>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerOrigem(o)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
					<div className="ui-g-12">
						<TZMTable header="Destinos" value={this.state.percurso.destinos.filter(isNotHidden)} rows={5} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirDestino}/>
						}>
							<TZMColumn header={(
								<TZMTextField label="Destinos" name="destinos" value={this.state._destinos} onChange={this.handleTableQuery}/>
							)} body={d => d.municipio ? `${d.municipio.nome}/${d.municipio.siglaUf}` : d.uf.nome}/>
							<TZMColumn header="Incidências" body={d => d.incidencias?.map(i => i.clientes?.map(ic => <div key={ic.id}>{ic.nome || ic.vwCliente?.razaoSocial}</div>))}/>
							<TZMColumn style={{width: "5em", textAlign: "center"}} header="Ações" body={d => (
								<div>
									<i className="ui-tzm-icon-uncoloured fa fa-edit" onClick={() => this.editarDestino(d)}/>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerDestino(d)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
					<div className="ui-g-12">
						<TZMTable header="Elementos de Custo (para este percurso)" headerColumnGroup={headerElementoCusto} value={this.state.percurso.elementosCusto} rows={5} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.inserirElementoCusto}/>
						}>
							{commonElementoCustoColumns}
							<TZMColumn style={{width: "5em", textAlign: "center"}} body={ec => (
								<div>
									<i className="ui-tzm-icon-uncoloured fa fa-edit" onClick={() => this.editarElementoCusto(ec)}/>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerElementoCusto(ec)}/>
								</div>
							)}/>
						</TZMTable>
					</div>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarPercurso}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarPercurso} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}

export function toQuery(value) {
	return `*${value.replace(/[^0-9a-zA-Z]/g, "*")}*`;
}

export function isLike(value, query) {
	return asPlain(value).toUpperCase().includes(asPlain(query).toUpperCase());
}

export function asPlain(value) {
	return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

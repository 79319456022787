export default class Roles {

    static ROLES_COMUM = [
        {label: "Perfis",                       value: ["____PERF_M", "____PERF_C", "____PERF_E", "____PERF_D"]},
        {label: "Configuração",                 value: ["____CLIE_M", "____CLIE_C", "____CLIE_E", "____CLIE_D"]},
		{label: "Usuários",                     value: ["____USER_M", "____USER_C", "____USER_E", "____USER_D"]},
		{label: "Clientes",                     value: ["____CLIE_M", "____CLIE_C", "____CLIE_E", "____CLIE_D"]},
        {label: "Contas de E-mail",             value: ["____MAIL_M", "____MAIL_C", "____MAIL_E", "____MAIL_D"]},
        {label: "Transportadora",               value: ["FRETE_TRANS_M","FRETE_TRANS_C","FRETE_TRANS_E","FRETE_TRANS_D"]},
        {label: "Romaneio",                     value: ["FRETE_ROMA_M","FRETE_ROMA_C","FRETE_ROMA_E","FRETE_ROMA_D"]},
        {label: "Tabelas de Frete",             value: ["FRETE_TABFR_M","FRETE_TABFR_C","FRETE_TABFR_E","FRETE_TABFR_D"]},        
        {label: "Fretes",                       value: ["FRETE_CTE_M","FRETE_CTE_C","FRETE_CTE_E","FRETE_CTE_D"]},
        {label: "Tipos de Veículo",             value: ["FRETE_TVEIC_M","FRETE_TVEIC_C","FRETE_TVEIC_E","FRETE_TVEIC_D"]},
        {label: "Veículos",                     value: ["FRETE_VEIC_M","FRETE_VEIC_C","FRETE_VEIC_E","FRETE_VEIC_D"]},
        {label: "Motoristas",                   value: ["FRETE_MOTO_M","FRETE_MOTO_C","FRETE_MOTO_E","FRETE_MOTO_D"]},
        {label: "Minutas",                      value: ["FRETE_MINU_M","FRETE_MINU_C","FRETE_MINU_E","FRETE_MINU_D"]},
    ];

}

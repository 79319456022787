import React from "react";
import moment from "moment";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMMainContent from "../../../components/main/TZMMainContent";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { VwClienteService } from "../../servicos/VwClienteService";
import {ConfiguracaoService} from "../servicos/ConfiguracaoService";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMButton from "../../../components/common/TZMButton";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import { TZMSimpleCheckbox } from "../../../components/common/TZMSimpleCheckbox";


const title = "Configuração";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Configuracao extends React.Component {

    constructor() {
		super();           
		this.state = {
			params: {
				dias: 30
			},
			configuracao:{
				entrada: {
					descricaoAmigavel: '',
					pk:{}
				},
				tomador: {
					descricaoAmigavel: '',
					pk:{}
				},
				entradas:[],
				tomadores:[],
				
			}
		}
	}
    
    configuracaoService = new ConfiguracaoService();
	clienteService = new VwClienteService();

	completar = () => {        
		 this.configuracaoService.completar().then( configuracao => this.setState({configuracao}));
	}

	componentDidMount(){
		this.completar();
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	entradas = async (event) => {
		let params = Object.assign({});	
		let notIn = this.state.configuracao.entradas;
		params.cliente = event.query;				
		params.sort = 'razao';
		params.posicao = 'ATIVO';
		params.clienteId_notIn = notIn.map(c => c.pk.clienteId).join(",")		        
        await this.clienteService.autoComplete(params).then((entradas) => this.setState({entradas}));
    }

	tomadores = async (event) => {
		let params = Object.assign({});	
		let notIn = this.state.configuracao.tomadores;
		params.cliente = event.query;				
		params.sort = 'razao';
		params.posicao = 'ATIVO';
		params.clienteId_notIn = notIn.map(c => c.pk.clienteId).join(",")		        
        await this.clienteService.autoComplete(params).then((tomadores) => this.setState({tomadores}));
    }

	excluirEntrada = (cliente) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let configuracao = this.state.configuracao;
		configuracao.entradas =  configuracao.entradas.filter(c => c !== cliente);
		this.setState({configuracao});
		}} question="Tem certeza de que deseja remover esta entrada?" />);
	}

	columnsEntradas = [		
		<TZMColumn header="Código" field="pk.clienteId"  style={{width: "8em", textAlign: "center"}}/>,
		<TZMColumn header="Razão Social" field="razaoSocial"  />,
		<TZMColumn header="Nome Fantasia" field="nomeFantasia" />,		
		<TZMColumn header="CNPJ" field="cpfCnpjFormatado" />,
		<TZMColumn header="Cidade" field="cidade" />,
		<TZMColumn header="Estado" field="estado" style={{width: "4em", textAlign: "center"}}/>,
		<TZMColumn key="acao" header="Ações" style={{width: "4em", textAlign: "center"}} body={(cliente) => {
			return (
				<div>
					<span title="Remover Entrada" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirEntrada(cliente)} />
				</div>
			);
		}} />		
	];

	columnsTomadores = [		
		<TZMColumn header="Código" field="pk.clienteId"  style={{width: "8em", textAlign: "center"}}/>,
		<TZMColumn header="Razão Social" field="razaoSocial"  />,
		<TZMColumn header="Nome Fantasia" field="nomeFantasia" />,		
		<TZMColumn header="CNPJ" field="cpfCnpjFormatado" />,
		<TZMColumn header="Cidade" field="cidade" />,
		<TZMColumn header="Estado" field="estado" style={{width: "4em", textAlign: "center"}}/>,
		<TZMColumn key="acao" header="Ações" style={{width: "4em", textAlign: "center"}} body={(cliente) => {
			return (
				<div>
					<span title="Remover Tomador" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirTomador(cliente)} />
				</div>
			);
		}} />		
	];

	excluirTomador = (cliente) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let configuracao = this.state.configuracao;
		configuracao.tomadores =  configuracao.tomadores.filter(c => c !== cliente);
		this.setState({configuracao});
		}} question="Tem certeza de que deseja remover este tomador?" />);
	}
	
	handleClear = (event) => {
		let configuracao = this.state.configuracao;
		configuracao[event.name] = null;
		this.setState({configuracao});
	}

	handleSelect = (event) => {
		let configuracao = this.state.configuracao;
		configuracao[event.name] = event.value;
		this.setState({configuracao});
    }

	addEntrada = (event) => {
		let configuracao = this.state.configuracao;
		configuracao.entradas.unshift(event.value);
		configuracao.entrada = null;
		this.setState({configuracao});
	}

	addTomador = (event) => {
		let configuracao = this.state.configuracao;
		configuracao.tomadores.unshift(event.value);
		configuracao.tomador = null;
		this.setState({configuracao});
	}

	salvar = () => {
		this.configuracaoService.salvar(this.state.configuracao).then(() => {
			this.completar()});
	}
	
	carregarXml = () => {
		this.configuracaoService.carregarXml(this.state.params)		
	}	
	
	handleChange = (event) => {
		let configuracao = this.state.configuracao;
		configuracao[event.name] = event.value;
		this.setState({configuracao});		
	}

	handleChangeParams = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});		
	}

	render() {
		return (
			<TZMMainContent>
				<TZMTabView>
					<TZMTabPanel header="Configurações">
						<TZMPanel header="Configuração">
							<div className="ui-g">
								<TZMTextField label="Pasta de Entrada" disabled col={2} name="enderecoEntrada" onChange={this.handleChange} value={this.state.configuracao.enderecoEntrada}/>
								<TZMTextField label="Pasta de Saída" disabled col={2} name="enderecoSaida" onChange={this.handleChange} value={this.state.configuracao.enderecoSaida}/>
								<TZMTextField label="Pasta de Erro" disabled col={2} name="enderecoErro" onChange={this.handleChange} value={this.state.configuracao.enderecoErro}/>
								<TZMTextField label="Data de Emissão do Último CT-e lido pelo Omega" col={3} disabled  value={moment(this.state.configuracao.dataEmissaoCte).format("DD/MM/YYYY HH:mm:ss")} />
								<TZMSimpleCheckbox label={this.state.configuracao.integrarFaturamento ? `Desativar integração de faturamento com o Omega` :`Ativar integração de faturamento com Omega`} name="integrarFaturamento" 
													col={3} checked={this.state.configuracao.integrarFaturamento} onChange={this.handleChange}/>
								<div className="ui-g-6">
									<TZMPanel header="Entradas">
										<TZMAutoComplete emptyMessage="Nenhum registro encontrado" placeholder="Razão social, fantasia ou CNPJ" itemTemplate={this.clienteTemplate} 
														onClear={this.handleClear}  onSelect={this.addEntrada} onChange={this.handleSelect} 
																suggestions={this.state.entradas} completeMethod={this.entradas} label="Entrada" name="entrada" field="descricaoAmigavel" 
																value={this.state.configuracao.entrada} />
										<TZMTable value={this.state.configuracao.entradas}  emptyMessage="Nenhum cliente adicionado" children={this.columnsEntradas}/>	
									</TZMPanel>
								</div>
								<div className="ui-g-6">
									<TZMPanel header="Tomadores">
										<TZMAutoComplete emptyMessage="Nenhum registro encontrado" placeholder="Razão social, fantasia ou CNPJ" itemTemplate={this.clienteTemplate} 
														onClear={this.handleClear}  onSelect={this.addTomador} onChange={this.handleSelect} 
																suggestions={this.state.tomadores} completeMethod={this.tomadores} label="Tomador" name="tomador" field="descricaoAmigavel" 
																value={this.state.configuracao.tomador} />
										<TZMTable value={this.state.configuracao.tomadores}  emptyMessage="Nenhum cliente adicionado" children={this.columnsTomadores}/>	
									</TZMPanel>
								</div>
							</div>
							<TZMPanelFooter>
								<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvar} />
							</TZMPanelFooter>
						</TZMPanel>
					</TZMTabPanel>
					<TZMTabPanel header="Comandos">
					<div className="ui-g">	
						<div className="ui-g-3">									
							<label >Esse processo realiza a leitura dos XML's armazendos no banco de dados.</label>
						</div>
						<div className="ui-g-9"/>
						<div className="ui-g-1">
							<TZMTextField label="Quantidade de dias" type="number" min={0} max={180} name="dias" onChange={this.handleChangeParams} value={this.state.params.dias}/>
						</div>	
						
						<div className="ui-g-2">
							<TZMButton success label="Carregar XML" icon="fas fa-save" onClick={this.carregarXml} />
						</div>
				
					</div>	
					</TZMTabPanel>	
				</TZMTabView>	
                <span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}

import React from "react";

export class RomaneioUtils { 

	static columnStatus(rowData) {
		let value, color, title;
		switch (rowData.status) {
		case "EXPEDIDO":
			value = "truck";
			color = "#8d8888";
			title = "Expedido";
			break;
		case "CANCELADO":
			value = "ban";
			color = "#F44336";
			title = "Cancelado";
			break;
		case "FATURADO":
			value = "check";
			color = "#4CAF50";
			title = "Faturado";
			break;
		default:
			break;
		}
		return <span key="columnStatus" className={`fas fa-${value}`} title={title} style={{color}} />;
	}
}
import {EntidadeService} from "../../servicos/EntidadeService";
import {httpDelete, httpGet, httpPost} from "../../../utilities/TZMRequest";

export class TabelaFreteService extends EntidadeService {

	buscar(id) {
		return httpGet(`/tabelas-frete/${id}`);
	}

	salvar(tabelaFrete) {
		return httpPost("/tabelas-frete", tabelaFrete);
	}

	excluir(id) {
		return httpDelete("/tabelas-frete", id);
	}

	listar(query) {
		return httpGet(`/tabelas-frete?${query}`);
	}

	listarCte(query) {
		return httpGet(`/tabelas-frete/cte?${query}`);
	}

	dtos(transportadora) {
		return httpGet(`/tabelas-frete/dtos?transportadora=${transportadora}`);
	}
	
}

import {httpDelete, httpGet, httpGetAsync, httpPost} from "../../utilities/TZMRequest";

export class Service {

	constructor(basePath) {
		this.basePath = basePath;
	}

	buscar(id) {
		return httpGet(this.basePath + "/" + id);
	}

	salvar(entidade) {
		return httpPost(this.basePath, entidade);
	}

	excluir(id) {
		return httpDelete(this.basePath, id);
	}

	listar(query) {
		return httpGet(this.basePath + "?" + query, false);
	}

	autoComplete = async (query) => {		
        return await httpGetAsync( this.basePath + "?" + query, null, false);
	}

}
